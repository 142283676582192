import React from 'react';
import styles from './index.module.scss';
import InboxPageConversationsList from '../../components/ConversationsList';
import ConversationView from '../../components/ConversationView';
import InboxPageSidebar from '../../components/InboxPageSidebar';

export default function InboxPage() {
  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <InboxPageSidebar />
      </div>
      <div className={styles.conversations}>
        <InboxPageConversationsList />
      </div>
      <div className={styles.conversation}>
        <ConversationView />
      </div>
    </div>
  );
}
