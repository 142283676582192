import { ReactNode } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export interface ChatWidgetPageHeaderProps {
  title: ReactNode;
  size: 'normal' | 'small' | 'large';
  onCloseClick: () => void;
  showBackArrow?: boolean;
  onBackArrowClick?: () => void;
  showTitleLogo?: boolean;
}

function BackIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" />
      <polyline
        points="160 208 80 128 160 48"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="24"
      />
    </svg>
  );
}

export default function ChatWidgetPageHeader(props: ChatWidgetPageHeaderProps) {
  const {
    title,
    size,
    showBackArrow,
    onBackArrowClick,
    showTitleLogo,
    onCloseClick,
  } = props;

  return (
    <div className={`chat-widget-header chat-widget-header-${size}`}>
      {
        showBackArrow && (
          <div className="chat-widget-header-back-container">
            <Button
              type="link"
              className="chat-widget-header-back"
              size="small"
              onClick={onBackArrowClick}
            >
              <BackIcon />
            </Button>
          </div>
        )
      }
      <div className="d-flex align-items-center gap-2">
        {
          showTitleLogo && (
            <img
              src="/zupport-blue-logo.png"
              alt="Zupport Logo"
              className="chat-widget-header-logo"
            />
          )
        }
        <h1 className="chat-widget-header-title">
          {title}
        </h1>
      </div>
      <Button
        type="link"
        className="chat-widget-header-close"
        size="small"
        onClick={onCloseClick}
      >
        <CloseOutlined color="white" />
      </Button>
    </div>
  );
}
