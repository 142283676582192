import { Spin, SpinProps } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import cn from '../../utils/cn';

interface SpinnerProps extends Partial<SpinProps> {
  center?: boolean;
}

export default function Spinner(props: SpinnerProps) {
  const { center = true, ...rest } = props;

  return (
    <div className={cn(center && 'd-flex justify-content-center')}>
      <Spin indicator={<LoadingOutlined spin />} {...rest} />
    </div>
  );
}
