interface AppPageTitleProps {
  title: string;
}

export default function AppPageTitle(props: AppPageTitleProps) {
  const { title } = props;

  return (
    <h2 className="fw-600 text-gray-800 m-0">{title}</h2>
  );
}
