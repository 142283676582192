export function getDayDisplay(date: Date) {
  const year = date.getFullYear();
  const isSameYear = year === new Date().getFullYear();
  const monthName = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();

  if (isSameYear) {
    return `${monthName} ${day}`;
  }

  return `${monthName} ${day}, ${year}`;
}

export function getDayTimeDisplay(date: Date) {
  return `${getDayDisplay(date)}, ${date.toLocaleTimeString([], {
    hour: 'numeric',
    minute: 'numeric',
  })}`;
}
