import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { Socket, io } from 'socket.io-client';
import User from './models/User';
import Auth from './services/Auth';
import { REACT_APP_API_URL } from '../config/env';
import Users from './services/Users';
import Workspace from './models/Workspace';
import Paginated from './types/Paginated';
import Workspaces from './services/Workspaces';
import Websites from './services/Websites';
import Website, { ProactiveEngagementSequence } from './models/Website';
import Assistant, { AssistantType, AssistantData } from './models/Assistant';
import Assistants from './services/Assistants';
import ConversationMessage, {
  ConversationMessageAction,
  ConversationMessageData,
  ConversationMessageActionType,
} from './models/ConversationMessage';
import ConversationParticipant, { ConversationParticipantType } from './models/ConversationParticipant';
import Conversation, { ConversationWithMetadata } from './models/Conversation';
import FAQ from './models/FAQ';
import Conversations from './services/Conversations';
import ConversationMessages from './services/ConversationMessages';
import ConversationParticipants from './services/ConversationParticipants';
import Agent from './models/Agent';
import Agents from './services/Agents';
import ConversationMetadata from './models/ConversationMetadata';
import ConversationParticipantMetadata from './models/ConversationParticipantMetadata';
import WorkspaceUser, { WorkspaceUserRole, WorkspaceUserData } from './models/WorkspaceUser';
import WebsiteUser from './models/WebsiteUser';
import ConversationIntent from './models/ConversationIntent';
import WebsiteModule from './models/WebsiteModule';
import ConversationIntents from './services/ConversationIntents';
import Billing from './services/Billing';
import HelpCenter, { HelpCenterVerificationStatus } from './models/HelpCenter';
import HelpCenters, { HelpCenterPatchData } from './services/HelpCenters';
import ContentWebsites from './services/ContentWebsites';
import ContentWebsitePage, { ContentWebsitePageCrawlingStatus } from './models/ContentWebsitePage';
import HelpCenterArticle, { HelpCenterArticleData, HelpCenterArticleStatus, HelpCenterArticleContent } from './models/HelpCenterArticle';
import LocalizedValue from './models/LocalizedValue';
import Upload, { UploadType } from './models/Upload';
import Uploads from './services/Uploads';

export interface ServerToClientEvents {
  'conversation:created': (conversation: Conversation) => void;
  'conversation:updated': (conversation: Conversation) => void;
  'conversation:message:created': (message: ConversationMessage) => void;
  'conversation:metadata:updated': (conversation: Conversation, metadata: ConversationMetadata) => void;
  'conversation:participant:metadata:updated': (metadata: ConversationParticipantMetadata) => void;
}

export interface ClientToServerEvents {}

class Api {
  axios: AxiosInstance;

  auth: Auth;

  users: Users;

  workspaces: Workspaces;

  websites: Websites;

  contentWebsites: ContentWebsites;

  assistants: Assistants;

  conversations: Conversations;

  conversationParticipants: ConversationParticipants;

  conversationIntents: ConversationIntents;

  messages: ConversationMessages;

  agents: Agents;

  billing: Billing;

  helpCenters: HelpCenters;

  uploads: Uploads;

  token?: string;

  io: Socket<ServerToClientEvents, ClientToServerEvents> | undefined;

  constructor(readonly baseURL: string) {
    this.axios = axios.create({
      baseURL,
    });

    this.axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      // eslint-disable-next-line prefer-destructuring
      const token = this.token;

      if (token) {
        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${token}`,
          },
        } as InternalAxiosRequestConfig;
      }

      return config as InternalAxiosRequestConfig;
    });

    this.auth = new Auth(this.axios);
    this.users = new Users(this.axios);
    this.workspaces = new Workspaces(this.axios);
    this.websites = new Websites(this.axios);
    this.assistants = new Assistants(this.axios);
    this.conversations = new Conversations(this.axios);
    this.conversationParticipants = new ConversationParticipants(this.axios);
    this.conversationIntents = new ConversationIntents(this.axios);
    this.messages = new ConversationMessages(this.axios);
    this.agents = new Agents(this.axios);
    this.contentWebsites = new ContentWebsites(this.axios);
    this.billing = new Billing(this.axios);
    this.helpCenters = new HelpCenters(this.axios);
    this.uploads = new Uploads(this.axios);
  }

  setToken(token: string) {
    this.token = token;
  }

  socket(): Socket {
    if (!this.io) {
      this.io = io(this.baseURL, {
        auth: {
          authorization: this.token || '',
        },
      });
    }

    return this.io;
  }
}

const api: Api = new Api(REACT_APP_API_URL);
export default api;

export {
  AssistantType, ConversationMessageActionType, WorkspaceUserRole,
  ConversationParticipantType, ContentWebsitePageCrawlingStatus, HelpCenterArticleStatus,
  UploadType, HelpCenterVerificationStatus,
};

export * from './models/ConversationIntent';

export type {
  User, Workspace, Paginated, Website, Assistant, AssistantData, ProactiveEngagementSequence,
  ConversationParticipant, Conversation, ConversationMessage, FAQ, ConversationMessageData, Agent,
  ConversationMessageAction, WorkspaceUser, WorkspaceUserData, ConversationMetadata,
  ConversationWithMetadata, ConversationParticipantMetadata, WebsiteUser, ConversationIntent,
  WebsiteModule, ContentWebsitePage, HelpCenter, HelpCenterPatchData, HelpCenterArticle,
  HelpCenterArticleData, HelpCenterArticleContent, LocalizedValue,
  Upload,
};
