import { RouteObject, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import SignUpPage from '../../auth/pages/SignUpPage';
import SignInPage from '../../auth/pages/SignInPage';

function NotFoundPage() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
  }, []);

  return null;
}

// eslint-disable-next-line import/prefer-default-export
export const guestRoutes: RouteObject[] = [
  {
    path: '/',
    element: <SignInPage />,
  },
  {
    path: '/sign-up',
    element: <SignUpPage />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
];
