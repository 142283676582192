import { ColorPicker, ColorPickerProps } from 'antd';

interface FormColorPickerProps extends Partial<Omit<ColorPickerProps, 'onChange'>> {
  onChange?: (color: string) => void;
}

// Make the color picker friendly to ant Form
// by default, the onChange function returns a Color object
// instead of a string
export default function FormColorPicker(props: FormColorPickerProps) {
  const { onChange, ...rest } = props;

  return (
    <ColorPicker
      {...rest}
      onChange={(color) => {
        if (onChange) {
          onChange(color.toHexString());
        }
      }}
    />
  );
}
