import { Form, Input, notification } from 'antd';
import useActiveWebsite from '../hooks/useActiveWebsite';
import useRequest from '../../common/hooks/useRequest';
import api, { Website } from '../../api';
import { setWebsite } from '../state';
import { AppSubPageLayoutContent } from '../../layout/components/AppSubPageLayout';
import Button from '../../common/components/Button';

export default function WebsiteDetailsSettingsPage() {
  const website = useActiveWebsite();
  const [form] = Form.useForm();

  const request = useRequest<Website>(async (values: Pick<Website, 'summary'>) => {
    if (!website) {
      throw new Error('Website not found');
    }

    return api.websites.patch(website._id, values);
  }, {
    onSuccess: (response) => {
      setWebsite(response);
      notification.success({
        message: 'Website updated',
      });
    },
  });

  return (
    <Form
      className="flex-1"
      layout="vertical"
      colon={false}
      requiredMark={false}
      initialValues={{
        summary: website?.summary || '',
      }}
      form={form}
      onFinish={request.submit}
    >
      <AppSubPageLayoutContent
        title="Details"
        content={(
          <>
            <div>
              Provide a brief summary of your website.
              It&apos;s going to be used in proactive engagement messages,
              as well as in the chat widget, when customers have questions about your services.
            </div>
            <div>
              <Form.Item
                name="summary"
                label="Summary"
              >
                <Input.TextArea rows={10} />
              </Form.Item>
            </div>
          </>
        )}
        footer={(
          <>
            <span />
            <Form.Item noStyle>
              <Button
                type="primary"
                htmlType="submit"
                loading={request.loading}
              >
                Save
              </Button>
            </Form.Item>
          </>
        )}
      />
    </Form>
  );
}
