import BaseService from './BaseService';
import ConversationIntent, { ConversationIntentData } from '../models/ConversationIntent';

export default class ConversationIntents extends BaseService {
  async create(data: ConversationIntentData): Promise<ConversationIntent> {
    return this._post<ConversationIntentData, ConversationIntent>('/conversation-intents', data);
  }

  async patch(id: ConversationIntent['_id'], data: Partial<ConversationIntentData>): Promise<ConversationIntent> {
    return this._patch<Partial<ConversationIntentData>, ConversationIntent>(`/conversation-intents/${id}`, data);
  }

  async remove(id: ConversationIntent['_id']): Promise<ConversationIntent> {
    return this._delete(`/conversation-intents/${id}`);
  }
}
