import BaseService from './BaseService';
import Paginated from '../types/Paginated';
import Conversation, { ConversationWithMetadata } from '../models/Conversation';
import Query from '../types/Query';
import WebsiteUser from '../models/WebsiteUser';

type ConversationsListParams = Query<
Pick<Conversation, 'websiteId'>
& Partial<Pick<Conversation, 'assigneeId' | 'handlerType' | 'engagementType' | 'status'>>
& Partial<{ unassigned: true }>
>;

export default class Conversations extends BaseService {
  async get(id: string): Promise<Conversation> {
    return this._get<Conversation>(`/conversations/${id}`);
  }

  async list(params: ConversationsListParams): Promise<Paginated<ConversationWithMetadata>> {
    return this._get<Paginated<Conversation>>('/conversations', { params });
  }

  async close(id: string): Promise<Conversation> {
    return this._post(`/conversations/${id}/close`);
  }

  async getUsers(id: string): Promise<WebsiteUser[]> {
    return this._get<WebsiteUser[]>(`/conversations/${id}/users`);
  }

  async markAsRead(id: string): Promise<Conversation> {
    return this._post(`/conversations/${id}/read`);
  }
}
