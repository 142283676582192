import BaseService from './BaseService';
import { ContentWebsite } from '../models/ContentWebsite';
import Paginated from '../types/Paginated';
import Query from '../types/Query';
import ContentWebsitePage from '../models/ContentWebsitePage';

type ContentWebsitePageListParams = Query<
Pick<ContentWebsitePage, 'contentWebsiteId' | 'workspaceId'>
& Partial<Pick<ContentWebsitePage, 'status'>>
>;

type CreateContentWebsiteData = Pick<
ContentWebsite,
'domain' | 'workspaceId' | 'includedUrls' | 'excludedUrls' | 'websiteId' | 'enablePageDiscovery'
>;

export default class ContentWebsites extends BaseService {
  async create(data: CreateContentWebsiteData): Promise<ContentWebsite> {
    return this._post('/content-websites', data);
  }

  async list(params: Pick<ContentWebsite, 'workspaceId'>): Promise<ContentWebsite[]> {
    return this._get('/content-websites', { params });
  }

  async delete(id: string): Promise<ContentWebsite> {
    return this._delete(`/content-websites/${id}`);
  }

  pages = {
    list: (params: ContentWebsitePageListParams): Promise<Paginated<ContentWebsitePage>> => this._get<Paginated<ContentWebsitePage>>('/content-websites/pages', { params }),
    refresh: (id: string): Promise<void> => this._post(`/content-websites/pages/${id}/refresh`),
  };
}
