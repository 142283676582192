import BaseModel from './BaseModel';
import ColorSchemeValue from './ColorSchemeValue';

export enum HelpCenterVerificationStatus {
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified',
}

export default interface HelpCenter extends BaseModel {
  name: string;
  slug: string;
  domain?: string;
  domainVerificationStatus?: HelpCenterVerificationStatus;
  workspaceId: BaseModel['_id'];
  websiteId: BaseModel['_id'];
  favicon?: string;
  config: {
    hero: {
      background: {
        type: 'image' | 'gradient' | 'color';
        image?: ColorSchemeValue<string>;
        gradient?: ColorSchemeValue<[string, string]>;
        color?: ColorSchemeValue<string>;
      },
      text: {
        color: ColorSchemeValue<string>;
      }
    };
    brand: {
      color: ColorSchemeValue<string>;
      logo?: ColorSchemeValue<string>;
    };
    languages: {
      enable: boolean;
      default: string;
      available: string[];
    };
  };
}
