import Select, { SelectOption, SelectProps } from '../../../common/components/Select';
import { ConversationIntentType } from '../../../api';

const options: SelectOption[] = [
  {
    label: 'Technical Issue',
    value: ConversationIntentType.TECHNICAL_ISSUE,
  },
  {
    label: 'Question',
    value: ConversationIntentType.QUESTION,
  },
];

interface ConversationIntentTypeSelectProps extends Omit<SelectProps, 'options'> {}

export default function ConversationIntentTypeSelect(props: ConversationIntentTypeSelectProps) {
  return (
    <Select options={options} placeholder="Select type" {...props} />
  );
}
