const colors = {
  primary: '#2D55FB',
  gray: {
    // secondary: '#F8FAFB',
    // secondary: '#F2F4F6',
    secondary: '#efefef',
  },
};

const theme = { colors } as const;

export default theme;
