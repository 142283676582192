import { ReactNode } from 'react';

interface AppPageVerticalSectionProps {
  header: ReactNode;
  children: ReactNode;
}

export default function AppPageVerticalSection(props: AppPageVerticalSectionProps) {
  const { header, children } = props;

  return (
    <div className="app-page-vertical-section">
      <div className="app-page-vertical-section-header">
        {header}
      </div>
      <div className="app-page-vertical-section-content">
        {children}
      </div>
    </div>
  );
}
