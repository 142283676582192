import { Link } from 'react-router-dom';
import AppPageContent from '../../layout/components/AppPageContent';
import Button from '../../common/components/Button';
import useQuery from '../../common/hooks/useQuery';
import useActiveWorkspaceId from '../../workspace/hooks/useActiveWorkspaceId';
import api from '../../api';
import Spinner from '../../common/components/Spinner';
import HelpCenterPreviewCard from '../components/HelpCenterPreviewCard';

export default function HelpCentersPage() {
  const workspaceId = useActiveWorkspaceId();

  const query = useQuery(
    async () => {
      if (!workspaceId) {
        throw new Error('Workspace ID is required');
      }

      return api.helpCenters.list({ workspaceId });
    },
  );

  return (
    <AppPageContent padding="normal" layout="centered">
      <div className="d-flex align-items-center justify-content-end">
        <Link to="create">
          <Button size="small">
            Create Help Center
          </Button>
        </Link>
      </div>
      {
        query.loading && (
          <Spinner />
        )
      }
      {
        query.data && query.data.length > 0 && (
          <div>
            {
              query.data && query.data.map((helpCenter) => (
                <div
                  key={helpCenter._id}
                  className="mt-2"
                >
                  <HelpCenterPreviewCard
                    name={helpCenter.name}
                    slug={helpCenter.slug}
                  />
                </div>
              ))
            }
          </div>
        )
      }
      {
        !query.loading && query.data && query.data.length === 0 && (
          <p className="text-center">
            No help centers found
          </p>
        )
      }
    </AppPageContent>
  );
}
