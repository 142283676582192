const MARKETING_PARAMS_KEY = 'marketing_params';

const marketingParamList = [
  'ref',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
];

function filterMarketingParams(params: Record<string, string>) {
  const filteredParams: Record<string, string> = {};

  marketingParamList.forEach((param) => {
    if (params[param]) {
      filteredParams[param] = params[param];
    }
  });

  return filteredParams;
}

export const getMarketingParams = () => {
  try {
    const value = localStorage.getItem(MARKETING_PARAMS_KEY);

    if (!value) {
      return {};
    }

    return filterMarketingParams(JSON.parse(value));
  } catch (e) {
    return {};
  }
};

export const updateMarketingParams = (params: Record<string, string>) => {
  const marketingParams = getMarketingParams();

  if (!marketingParams.ref) {
    const ref = document.referrer;

    if (ref !== '') {
      try {
        const url = new URL(ref);
        marketingParams.ref = url.hostname;
      } catch (e) {
        // do nothing
        marketingParams.ref = ref;
      }
    }
  }

  localStorage.setItem(MARKETING_PARAMS_KEY, JSON.stringify({
    ...marketingParams,
    ...params,
  }));
};

export function clearMarketingParams() {
  const params = new URLSearchParams(window.location.search);
  marketingParamList.forEach((param) => {
    params.delete(param);
  });

  const search = params.toString();
  const url = window.location.href.replace(window.location.search, '');
  window.history.replaceState({}, '', `${url}${search}`);
}
