import { useEffect } from 'react';
import queryString from 'query-string';
import { clearMarketingParams, updateMarketingParams } from '../../utils/tracking';

export default function MarketingParamsTracker() {
  // Fixes this https://github.com/vercel/next.js/issues/58615#issuecomment-1820948414
  const search = new URLSearchParams(
    typeof window !== 'undefined' ? window.location.search : '',
  ).toString();

  useEffect(() => {
    const params = (search ? queryString.parse(search) : {}) as Record<string, string>;
    updateMarketingParams(params);
    clearMarketingParams();
  }, [search]);

  return null;
}
