import BaseService from './BaseService';
import HelpCenter from '../models/HelpCenter';
import HelpCenterArticle, { HelpCenterArticleData } from '../models/HelpCenterArticle';
import Query from '../types/Query';
import Paginated from '../types/Paginated';

export type HelpCenterPatchData = Partial<Pick<HelpCenter, 'name' | 'slug' | 'domain' | 'config'>>;

export type HelpCenterMultimediaPatchData = {
  favicon?: File;
  headerImageLight?: File;
  headerImageDark?: File;
  logoLight?: File;
  logoDark?: File;
};

export default class HelpCenters extends BaseService {
  async create(data: Pick<HelpCenter, 'name' | 'slug' | 'workspaceId' | 'websiteId'>) {
    return this._post<Pick<HelpCenter, 'name' | 'slug' | 'workspaceId' | 'websiteId'>, HelpCenter>('/help-centers', data);
  }

  async list(params: Pick<HelpCenter, 'workspaceId'> & Partial<HelpCenter>): Promise<HelpCenter[]> {
    return this._get<HelpCenter[]>('/help-centers', { params });
  }

  async patch(id: HelpCenter['_id'], data: HelpCenterPatchData) {
    return this._patch<HelpCenterPatchData, HelpCenter>(`/help-centers/${id}`, data);
  }

  async patchMultimedia(id: HelpCenter['_id'], data: HelpCenterMultimediaPatchData) {
    // Send as multipart in order to upload the favicon
    return this._patch<HelpCenterMultimediaPatchData, HelpCenter>(`/help-centers/${id}/multimedia`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  articles = {
    create: async (data: HelpCenterArticleData): Promise<HelpCenterArticle> => this._post(
      '/help-centers/articles',
      data,
    ),
    get: async (id: HelpCenterArticle['_id']): Promise<HelpCenterArticle> => this._get(
      `/help-centers/articles/${id}`,
    ),
    list: async (params: Query<Pick<HelpCenterArticle, 'helpCenterId'> & Partial<HelpCenterArticle>>): Promise<Paginated<HelpCenterArticle>> => this._get(
      '/help-centers/articles',
      { params },
    ),
    patch: async (id: HelpCenterArticle['_id'], data: Partial<HelpCenterArticleData>): Promise<HelpCenterArticle> => this._patch(
      `/help-centers/articles/${id}`,
      data,
    ),
    delete: async (id: HelpCenterArticle['_id']): Promise<void> => this._delete(`/help-centers/articles/${id}`),
  };
}
