import { User, WorkspaceUser } from '../../api';

const TOKEN_KEY = 'token';
const USER_KEY = 'user';
const WORKSPACE_USER_KEY = 'workspace-user';

export function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}

export function setToken(token: string) {
  localStorage.setItem(TOKEN_KEY, token);
}

export function clearToken() {
  localStorage.removeItem(TOKEN_KEY);
}

export function setUser(user: User) {
  localStorage.setItem(USER_KEY, JSON.stringify(user));
}

export function getUser(): User | undefined {
  const user = localStorage.getItem(USER_KEY);
  return user ? JSON.parse(user) : undefined;
}

export function clearUser() {
  localStorage.removeItem(USER_KEY);
}

export function getWorkspaceUser(): WorkspaceUser | undefined {
  const workspaceUser = localStorage.getItem(WORKSPACE_USER_KEY);
  return workspaceUser ? JSON.parse(workspaceUser) : undefined;
}

export function setWorkspaceUser(workspaceUser: WorkspaceUser) {
  localStorage.setItem(WORKSPACE_USER_KEY, JSON.stringify(workspaceUser));
}
