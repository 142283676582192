import React, { useState } from 'react';
import { Skeleton } from 'antd';
import styles from './index.module.scss';

function useSkeletonRangeSize(min: number, max: number) {
  const [size] = useState(() => Math.floor(Math.random() * (max - min + 1)) + min);
  return size;
}

export default function ConversationPreviewCardSkeleton() {
  const messageWidth = useSkeletonRangeSize(50, 200);
  const userWidth = useSkeletonRangeSize(40, 60);

  return (
    <div
      className={styles.card}
    >
      <Skeleton.Avatar
        active
        size={32}
      />
      <div className="flex-1 gap-2">
        <div className="d-flex justify-content-between align-items-center gap-2">
          <Skeleton.Input active style={{ height: 17, maxWidth: userWidth, minWidth: userWidth }} />
          <Skeleton.Input style={{ height: 15, maxWidth: 40, minWidth: 40 }} />
        </div>
        <div style={{ marginTop: 8 }}>
          <Skeleton.Input
            active
            style={{ height: 15, maxWidth: messageWidth, minWidth: messageWidth }}
          />
        </div>
      </div>
    </div>
  );
}
