import { Store } from 'pullstate';
import api, { HelpCenter, HelpCenterArticle, HelpCenterArticleStatus } from '../../../api';
import useQuery from '../../../common/hooks/useQuery';
import Select, { SelectOption, SelectProps } from '../../../common/components/Select';

interface HelpCenterCategorySelectProps extends Partial<SelectProps> {
  isCategory?: boolean;
  helpCenterId: HelpCenterArticle['helpCenterId'];
  defaultLanguage: string;
  value?: string;
  onChange?: (value: string) => void;
}

type HelpCenterState = {
  categories: HelpCenterArticle[];
  articles: HelpCenterArticle[];
  categoryOptions: SelectOption[];
  articleOptions: SelectOption[];
  loading: boolean;
  initialized: boolean;
};

type StateType = Record<HelpCenter['_id'], HelpCenterState>;

const State = new Store<StateType>({});

async function initializeHelpCenterState(helpCenterId: HelpCenter['_id'], language: string) {
  const result = await api.helpCenters.articles.list(
    {
      helpCenterId,
      $limit: 100,
      status: HelpCenterArticleStatus.PUBLISHED,
      $project: {
        _id: 1,
        // content: 1,
        [`content.${language}.title`]: 1,
        isCategory: 1,
      },
    },
  );

  State.update((s) => {
    const categories: HelpCenterArticle[] = [];
    const articles: HelpCenterArticle[] = [];
    const categoryOptions: SelectOption[] = [];
    const articleOptions: SelectOption[] = [];

    result.items.forEach((item) => {
      const option = {
        label: item.content[language]?.title
          || item.content[Object.keys(item.content)[0]]?.title
          || 'Untitled',
        value: item._id,
      };

      if (item.isCategory) {
        categoryOptions.push(option);
        categories.push(item);
      } else {
        articleOptions.push(option);
        articles.push(item);
      }
    });

    return {
      ...s,
      [helpCenterId]: {
        ...(s[helpCenterId] || {}),
        categories,
        articles,
        loading: false,
        initialized: true,
        categoryOptions,
        articleOptions,
      },
    };
  });
}

export function useHelpCenterCategories(helpCenterId?: HelpCenter['_id'], language = 'en'): HelpCenterArticle[] {
  return State.useState((s) => {
    if (!helpCenterId) {
      return [];
    }

    const stored = s[helpCenterId];

    if (!stored || !stored.initialized) {
      initializeHelpCenterState(helpCenterId, language).then(() => {
        // ignore
      });
    }

    return stored?.categories || [];
  }, [helpCenterId, language]);
}

export default function HelpCenterArticleSelect(props: HelpCenterCategorySelectProps) {
  const {
    helpCenterId, defaultLanguage, value, onChange, isCategory, ...rest
  } = props;

  const stored = State.useState((s) => s[helpCenterId] || {
    categories: [],
    articles: [],
    loading: false,
    initialized: false,
    categoryOptions: [],
    articleOptions: [],
  }, [helpCenterId]);
  const {
    categoryOptions, articleOptions, initialized,
  } = stored;

  useQuery(async () => {
    if (!initialized) {
      initializeHelpCenterState(helpCenterId, defaultLanguage).then(() => {
        // ignore
      });
    }
  }, [helpCenterId, defaultLanguage]);

  return (
    <Select
      options={isCategory ? categoryOptions : articleOptions}
      value={value}
      onChange={onChange}
      placeholder="Select category"
      allowClear
      showSearch
      {...rest}
    />
  );
}
