import { Card } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import PricingPlan from '../../../billing/types/PricingPlan';
import cn from '../../../common/utils/cn';
import Button from '../../../common/components/Button';
import { pluralize } from '../../../common/utils/strings';

interface PricingPlanCardProps {
  plan: PricingPlan;
  onSubscribe: (pricingId: string) => void;
  subscribeLoading?: boolean;
  subscribeDisabled?: boolean;
  quantity: number;
}

function formatPrice(price: number) {
  return `$${price}`;
}

interface FeatureItemProps {
  label: string;
  included: boolean;
}

function FeatureItem(props: FeatureItemProps) {
  const { label, included } = props;
  return (
    <div className={cn('d-flex justify-content-between', !included && 'text-muted')}>
      <span>{label}</span>
      <span className={cn('color-primary', !included && 'text-muted')}>
        {
          included ? <CheckOutlined /> : <CloseOutlined />
        }
      </span>
    </div>
  );
}

export default function PricingPlanCard(props: PricingPlanCardProps) {
  const {
    plan, onSubscribe, subscribeLoading, subscribeDisabled, quantity,
  } = props;

  return (
    <Card>
      <h2 className="text-center">{plan.name}</h2>
      <h1 className="text-center mb-0">{formatPrice(plan.price)}</h1>
      <h3 className="text-center mt-0 text-secondary">
        per seat/month
      </h3>
      <h4 className="mt-5">Includes</h4>
      <FeatureItem
        label={`${plan.aiConversationsCount} AI Conversations/month`}
        included
      />
      <FeatureItem
        label="Company Inbox"
        included
      />
      <FeatureItem
        label="AI Issue Resolution"
        included
      />
      <FeatureItem
        label="FAQs"
        included
      />
      <FeatureItem
        label="Knowledge Base"
        included={plan.knowledgeBaseEnabled}
      />
      <FeatureItem
        label="Proactive Engagement"
        included={plan.proactiveEngagementEnabled}
      />
      <div className="d-flex align-items-center justify-content-center mt-5 gap-2">
        <Button
          onClick={() => {
            if (!subscribeDisabled) {
              onSubscribe(plan.id);
            }
          }}
          loading={subscribeLoading}
          type="primary"
          color="primary"
          block
        >
          {`Upgrade (${pluralize(quantity, 'Seat')})`}
        </Button>
      </div>
      <div className="text-secondary mt-4">
        <p>
          *A seat is a user who can access the platform.
        </p>
      </div>
    </Card>
  );
}
