import BaseService from './BaseService';
import Query from '../types/Query';
import Paginated from '../types/Paginated';
import ConversationMessage, { ConversationMessageData } from '../models/ConversationMessage';

type ListParams = Query<{
  conversationId: string;
}>;

export default class ConversationMessages extends BaseService {
  async list(params: ListParams): Promise<Paginated<ConversationMessage>> {
    return this._get('/messages', { params });
  }

  async create(data: ConversationMessageData): Promise<ConversationMessage> {
    return this._post('/messages', data);
  }
}
