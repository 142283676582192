import {
  ReactNode, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { CloseOutlined } from '@ant-design/icons';
import websiteUserApi from '../../../website-user-api';
import cn from '../../../common/utils/cn';
import {
  Agent,
  Conversation,
  ConversationMessage,
  ConversationMetadata,
  ConversationParticipant,
  Website,
  WebsiteUser,
  ConversationParticipantType,
} from '../../../api';
import ChatWidgetConversations, { loadChatWidgetConversations } from '../../components/ChatWidgetConversations';
import '../../../../assets/styles/chat-widget.scss';

import ChatWidgetPageHeader, { ChatWidgetPageHeaderProps } from '../../components/ChatWidgetPageHeader';
import ChatWidgetConversation, { getDisplayTime } from '../../components/ChatWidgetConversation';
import {
  addConversationMessage,
  ChatWidgetConversationsStore,
  ChatWidgetStore,
  markConversationAsRead,
} from '../../state';
import useSize, { Size } from '../../../common/hooks/useSize';
import ChatWidgetHome from '../../components/ChatWidgetHome';
import UserAvatar from '../../../common/components/UserAvatar';
import Button from '../../../common/components/Button';

enum Tab {
  HOME = 'home',
  CHAT = 'chat',
}

function HomeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" />
      <path
        d="M40,216H216V120a8,8,0,0,0-2.34-5.66l-80-80a8,8,0,0,0-11.32,0l-80,80A8,8,0,0,0,40,120Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

function ChatIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
      <rect width="256" height="256" fill="none" />
      <path
        d="M96,176H32a8,8,0,0,1-8-8V104A72,72,0,0,1,96,32h0a72,72,0,0,1,72,72h0A72,72,0,0,1,96,176Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.1,176A72,72,0,0,0,160,224h64a8,8,0,0,0,8-8V152a72,72,0,0,0-68.06-71.89"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

enum Mode {
  MESSAGES_PREVIEW = 'messages-preview',
  REGULAR = 'regular',
}

function getReferrer() {
  let referrer = (window.location !== window.parent.location)
    ? document.referrer
    : document.location.href;

  // Remove trailing slash
  referrer = referrer.replace(/\/$/, '');
  // Remove https and http
  referrer = referrer.replace(/https?:\/\//, '');
  // Remove www
  referrer = referrer.replace(/www\./, '');

  return referrer;
}

export default function ChatWidgetPage() {
  const [tab, setTab] = useState<Tab>(Tab.HOME);
  const [website, setWebsite] = useState<Website | null>(null);
  const [user, setUser] = useState<WebsiteUser>();
  const [participant, setParticipant] = useState<ConversationParticipant | undefined>(undefined);
  const [agents, setAgents] = useState<Agent[]>();
  const [size, ref] = useSize<HTMLDivElement>();
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(Mode.REGULAR);
  const [previewMessages, setPreviewMessages] = useState<ConversationMessage[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const openRef = useRef(false);
  const modeRef = useRef<Mode>(Mode.REGULAR);
  const activeConversation = ChatWidgetConversationsStore.useState((s) => s.activeConversation);
  const conversations = ChatWidgetConversationsStore.useState((s) => s.conversations, []);

  const syncMessagesPreviewHeight = useCallback((height: string | number) => {
    window.parent.postMessage({
      message: 'zupport.chat.messages.previews.height.change',
      height,
    }, '*');
  }, []);

  const onPreviewsContainerSizeChange = useCallback((previewsContainerSize: Size) => {
    syncMessagesPreviewHeight(previewsContainerSize.height || 800);
  }, [syncMessagesPreviewHeight]);

  const [, previewsContainer] = useSize<HTMLDivElement>(onPreviewsContainerSizeChange);

  const changeMode = useCallback((newMode: Mode) => {
    window.parent.postMessage({
      message: 'zupport.chat.mode.change',
      mode: newMode,
    }, '*');
    setMode(newMode);
    modeRef.current = newMode;
  }, []);

  const closeChat = useCallback(() => {
    setOpen(false);
    openRef.current = false;
    window.parent.postMessage({ message: 'zupport.chat.close' }, '*');
  }, []);

  const openChat = useCallback(() => {
    window.parent.postMessage({ message: 'zupport.chat.open' }, '*');
    setOpen(true);
    openRef.current = true;
  }, []);

  useEffect(() => {
    // Listen for own messages because the signals to open and close the chat
    // could be sent from other places in the app
    window.addEventListener('message', (event) => {
      if (event.data) {
        if (event.data.message === 'zupport.chat.init:success') {
          const {
            userUid,
            userSecret,
            website: newWebsite,
            baseAPI,
            nextSequence,
            user: responseUser,
            conversationParticipant,
            agents: responseAgents,
          } = event.data;

          setUser(responseUser);
          setParticipant(conversationParticipant);
          setAgents(responseAgents);
          setIsMobile(typeof event.data.isMobile === 'boolean'
            ? event.data.isMobile
            : Boolean(event.data.isMobile));
          websiteUserApi.init(baseAPI, newWebsite.uid, document.referrer, {
            uid: userUid,
            secret: userSecret,
          });

          const socket = websiteUserApi.socket();

          socket.on('conversation:created', (conversation: Conversation) => {
            ChatWidgetConversationsStore.update((s) => ({
              ...s,
              conversations: [conversation, ...s.conversations],
            }));
          });

          socket.on('conversation:message:created', (message: ConversationMessage) => {
            if (message.participant._id !== conversationParticipant?._id) {
              const notificationSound = new Audio('/pop.mp3');
              notificationSound.play().then(() => {
                // Ignore
              }).catch(() => {
                // Ignore
              });
            }
            addConversationMessage(message.conversationId, message);

            // If the chat is not open, then we enable the preview mode and open the chat
            if (!openRef.current) {
              changeMode(Mode.MESSAGES_PREVIEW);

              // This will trigger the chat to open
              window.parent.postMessage({
                message: 'zupport.chat.open',
                mode: Mode.MESSAGES_PREVIEW,
              }, '*');

              setOpen(true);
              openRef.current = true;
            }

            // If mode is preview then we add the message to the preview messages
            if (modeRef.current === Mode.MESSAGES_PREVIEW) {
              // Temporarily set the height to 100vh - 80px in order to allow the previews to grow
              // and the height will automatically be set to the correct value once the preview
              // container size changes
              syncMessagesPreviewHeight(
                'calc(100dvh) - 80px',
              );
              setPreviewMessages((prev) => [...prev, message].slice(-4));

              // Load the conversations in order to be able to switch instantly to the
              // conversation when clicking a preview message
              loadChatWidgetConversations().then(() => {
                addConversationMessage(message.conversationId, message);
              }).catch(() => {
                // ignore
              });
            }

            ChatWidgetConversationsStore.update((s) => {
              if (s.activeConversation?._id === message.conversationId) {
                websiteUserApi.markConversationAsRead(newWebsite.uid, message.conversationId);
                markConversationAsRead(message.conversationId);
                return s;
              }

              return s;
            });
          });

          socket.on('conversation:metadata:updated', (conversation: Conversation, metadata: ConversationMetadata) => {
            ChatWidgetConversationsStore.update((s) => ({
              ...s,
              conversations: s.conversations.map((c) => {
                if (c._id === conversation._id) {
                  return {
                    ...c,
                    metadata: {
                      ...c.metadata,
                      ...metadata,
                      // If the conversation is active, then we reset the unread messages count
                      unreadMessagesCount: conversation._id === s.activeConversation?._id
                        ? 0
                        : metadata.unreadMessagesCount,
                    },
                  };
                }

                return c;
              }),
            }));
          });

          socket.on('conversation:updated', (conversation: Conversation) => {
            ChatWidgetConversationsStore.update((s) => ({
              ...s,
              conversations: s.conversations.map((c) => {
                if (c._id === conversation._id) {
                  return {
                    ...c,
                    ...conversation,
                  };
                }

                return c;
              }),
            }));
          });

          setWebsite(newWebsite as Website);

          ChatWidgetStore.update((s) => ({
            ...s,
            websiteUid: newWebsite.uid,
          }));

          if (nextSequence) {
            const {
              triggerDelay,
              triggerDelayVariation,
            } = nextSequence;
            const timeout = triggerDelay + Math.floor(Math.random() * triggerDelayVariation);

            setTimeout(() => {
              websiteUserApi.triggerSequence(nextSequence.id);
            }, timeout * 1000);
          }
        } else if (event.data.message === 'zupport.chat.button.clicked') {
          if (openRef.current) {
            if (modeRef.current === Mode.MESSAGES_PREVIEW) {
              changeMode(Mode.REGULAR);
              setPreviewMessages([]);
            } else {
              closeChat();
            }
          } else {
            openChat();
          }
        }
      }
    });

    window.parent.postMessage({ message: 'zupport.chat.widget.page.ready' }, '*');
  }, []);

  const onConversationStarted = useCallback((conversation: Conversation) => {
    ChatWidgetConversationsStore.update((s) => ({
      ...s,
      activeConversation: conversation,
    }));
    setTab(Tab.CHAT);
  }, []);

  const onMessagePreviewClick = useCallback((message: ConversationMessage) => {
    const conversation = conversations.find(
      (c) => c._id === message.conversationId,
    );
    setTab(Tab.CHAT);
    ChatWidgetConversationsStore.update((s) => ({
      ...s,
      activeConversation: conversation,
    }));
    changeMode(Mode.REGULAR);
    setPreviewMessages([]);
  }, [conversations]);

  const participantsKey = activeConversation?.participantIds.join(',');

  const participants = useMemo(() => {
    let result: ConversationParticipant[] = [];

    if (activeConversation) {
      Object.entries(activeConversation.participants).forEach(([, value]) => {
        if (value._id !== participant?._id) {
          result.push(value);
        }
      });
    }

    result = result.sort((a, b) => {
      if (a.type === b.type) {
        return 0;
      }

      if (a.type === ConversationParticipantType.AI_ASSISTANT) {
        return -1;
      }

      if (b.type === ConversationParticipantType.AI_ASSISTANT) {
        return 1;
      }

      return 0;
    });

    return result;
  }, [participantsKey, participant?._id]);

  const onEmailChange = async (email: string) => {
    if (user) {
      const result = await websiteUserApi.setUserEmail(user.uid, email, user.secret);
      setUser(result);
    }
  };

  let headerTitle: ReactNode = website ? `Welcome to ${website.name}!` : 'Welcome to Zupport';
  let navCollapsed = false;

  // let headerSize: ChatWidgetPageHeaderProps['size'] = 'normal';
  let showBackArrow = false;
  let onBackArrowClick: ChatWidgetPageHeaderProps['onBackArrowClick'] = () => {};

  if (activeConversation) {
    headerTitle = (
      <div className="d-flex align-items-center gap-2">
        <div className="my-2 d-flex">
          {
            participants.map((agent, index) => (
              <UserAvatar
                key={agent._id}
                avatar={agent.avatar}
                style={{
                  marginLeft: index > 0 ? -20 : 0,
                }}
                size={40}
              >
                {agent.name}
              </UserAvatar>
            ))
          }
        </div>
        <div>
          <div className="d-flex align-items-center gap-1 chat-widget-header-title-username">
            {
              participants.at(-1)?.name
            }
          </div>
          {
            activeConversation?.lastMessageAt && (
              <div className="chat-widget-header-title-time">
                {
                  getDisplayTime(new Date(activeConversation.lastMessageAt))
                }
              </div>
            )
          }
        </div>
      </div>
    );
    // headerSize = 'small';
    showBackArrow = true;
    onBackArrowClick = () => {
      ChatWidgetConversationsStore.update((s) => ({
        ...s,
        activeConversation: null,
      }));
    };
    navCollapsed = true;
  }

  if (!open) {
    return null;
  }

  return (
    <>
      {
        mode === Mode.MESSAGES_PREVIEW && (
          <div className={cn('chat-messages-preview-container', isMobile && 'chat-messages-preview-container-mobile')}>
            <div
              className="chat-messages-preview-container-bottom"
              ref={previewsContainer}
            >
              <div className="chat-messages-preview-action-buttons">
                <Button
                  type="primary"
                  shape="circle"
                  size="small"
                  style={{
                    width: 25,
                    height: 25,
                    minWidth: 25,
                  }}
                  onClick={() => {
                    closeChat();
                    changeMode(Mode.REGULAR);
                    setPreviewMessages([]);
                  }}
                  icon={(
                    <CloseOutlined
                      style={{ fontSize: 14, fill: 'white' }}
                    />
                  )}
                />
              </div>
              {
                previewMessages.map((message, index) => (
                  <div
                    key={message._id}
                    className="chat-message-preview-container"
                  >
                    <div className="chat-message-preview-avatar-container">
                      {
                        index === previewMessages.length - 1 && (
                          <UserAvatar
                            avatar={message.participant.avatar}
                            size={40}
                          />
                        )
                      }
                    </div>
                    <div
                      className="chat-message-preview-bubble"
                      onClick={() => {
                        onMessagePreviewClick(message);
                      }}
                      role="button"
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          onMessagePreviewClick(message);
                        }
                      }}
                    >
                      <div className="chat-message-preview-bubble-message">
                        {message.message}
                      </div>
                      <div className="chat-message-preview-bubble-info">
                        <span>{message.participant.name}</span>
                        <span>{getDisplayTime(new Date(message.createdAt))}</span>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        )
      }
      {
        mode === Mode.REGULAR && (
          <div className={cn('chat-widget', isMobile && 'chat-widget-mobile')} ref={ref}>
            <div>
              <ChatWidgetPageHeader
                title={headerTitle}
                size="small"
                showBackArrow={showBackArrow}
                onBackArrowClick={onBackArrowClick}
                showTitleLogo={!activeConversation}
                onCloseClick={closeChat}
              />
            </div>
            <div className="chat-widget-content">
              <div className="chat-widget-content-top">
                {
                  tab === Tab.CHAT && !activeConversation && (
                    <ChatWidgetConversations
                      websiteId={website?._id}
                      onConversationClick={(conversation) => {
                        ChatWidgetConversationsStore.update((s) => ({
                          ...s,
                          activeConversation: conversation,
                        }));
                      }}
                      widgetHeight={size.height}
                    />
                  )
                }
                {
                  tab === Tab.CHAT && activeConversation && (
                    <ChatWidgetConversation
                      conversation={activeConversation}
                      participant={participant}
                      user={user}
                      widgetHeight={size.height}
                      onEmailChange={onEmailChange}
                    />
                  )
                }
                {
                  tab === Tab.HOME && (
                    <ChatWidgetHome
                      onConversationStarted={onConversationStarted}
                      faqs={website?.faqs}
                      agents={agents}
                      widgetHeight={size.height}
                    />
                  )
                }
              </div>
              <div
                className={cn('chat-widget-nav', navCollapsed && 'chat-widget-nav-collapsed')}
              >
                <div
                  className={cn('chat-widget-nav-item', tab === Tab.HOME && 'chat-widget-nav-item-active')}
                  onClick={() => setTab(Tab.HOME)}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setTab(Tab.HOME);
                    }
                  }}
                >
                  <div className="chat-widget-nav-item-content">
                    <div className="chat-widget-nav-item-icon">
                      <HomeIcon />
                    </div>
                    <div className="chat-widget-nav-item-title">
                      Home
                    </div>
                  </div>
                </div>
                <div
                  className={cn('chat-widget-nav-item', tab === Tab.CHAT && 'chat-widget-nav-item-active')}
                  onClick={() => setTab(Tab.CHAT)}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setTab(Tab.CHAT);
                    }
                  }}
                >
                  <div className="chat-widget-nav-item-content">
                    <div className="chat-widget-nav-item-icon">
                      <ChatIcon />
                    </div>
                    <div className="chat-widget-nav-item-title">
                      Messages
                    </div>
                  </div>
                </div>
              </div>
              <div className="chat-widget-powered-by">
                <a href={`https://zupport.ai?ref=${getReferrer()}`} target="_blank" rel="noreferrer">
                  Powered by Zupport
                </a>
                <span>
                  <img
                    src="/zupport-blue-logo.png"
                    alt="Zupport Logo"
                    height={20}
                  />
                </span>
              </div>
            </div>
          </div>
        )
      }
      {/*
      @ts-ignore */}
      <style jsx>
        {`
            body,
            html,
            .app-container,
            #root {
                height: 100%;
                width: 100%;
                background-color: transparent;
            }
        `}
      </style>
    </>
  );
}
