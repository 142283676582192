import BaseService from './BaseService';

type CreateCheckoutSessionData = {
  planId: string;
  quantity: number;
  workspaceId: string;
};

export default class Billing extends BaseService {
  subscriptions = {
    checkout: {
      sessions: {
        create: (
          data:CreateCheckoutSessionData,
        ): Promise<{ url: string }> => this._post<CreateCheckoutSessionData, { url: string }>(
          '/billing/subscriptions/checkout/sessions',
          data,
        ),
      },
    },
  };

  portal = {
    sessions: {
      create: (
        data: { workspaceId: string },
      ): Promise<{ url: string }> => this._post<{ workspaceId: string }, { url: string }>(
        '/billing/portal/sessions',
        data,
      ),
    },
  };
}
