import {
  Card,
  Col, Form, Input, notification, Row,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import useActiveWebsite from '../hooks/useActiveWebsite';
import useRequest from '../../common/hooks/useRequest';
import api, { Website } from '../../api';
import { setWebsite } from '../state';
import { AppSubPageLayoutContent } from '../../layout/components/AppSubPageLayout';
import Button from '../../common/components/Button';

export default function WebsiteLinksSettingsPage() {
  const website = useActiveWebsite();
  const [form] = Form.useForm();

  const request = useRequest<Website>(async (values: Pick<Website, 'links'>) => {
    if (!website) {
      throw new Error('Website not found');
    }

    return api.websites.patch(website._id, values);
  }, {
    onSuccess: (response) => {
      setWebsite(response);
      notification.success({
        message: 'Website updated',
      });
    },
  });

  return (
    <Form
      className="flex-1"
      layout="vertical"
      colon={false}
      requiredMark={false}
      initialValues={{
        links: website?.links || '',
      }}
      form={form}
      onFinish={request.submit}
    >
      <AppSubPageLayoutContent
        title="Links"
        content={(
          <>
            <div>
              Build a list of links that you want to share with your customers.
              They will be used by the AI agents for proactive support or
              when customers ask for more information about your services.
            </div>
            <div>
              <Form.Item shouldUpdate>
                {({ getFieldValue }) => {
                  const links = getFieldValue('links') || [];

                  return (
                    <Row gutter={[16, 16]} className="mt-4">
                      {
                        links.map((link: any, index: number) => (
                          <Col span={24} key={link.id}>
                            <Card
                              size="small"
                              extra={[(
                                <Button
                                  type="link"
                                  size="small"
                                  danger
                                  onClick={() => {
                                    form.setFieldsValue({
                                      links: links.filter((_: any, i: number) => i !== index),
                                    });
                                  }}
                                  icon={(
                                    <DeleteOutlined />
                                  )}
                                />
                              )]}
                            >
                              <Form.Item
                                name={['links', index, 'id']}
                                hidden
                              >
                                <Input />
                              </Form.Item>
                              <Form.Item
                                name={['links', index, 'url']}
                                label="URL"
                                rules={[{ required: true, message: 'URL is required' }]}
                              >
                                <Input size="small" />
                              </Form.Item>
                              <Form.Item
                                name={['links', index, 'description']}
                                label="Description"
                                className="m-0"
                              >
                                <Input size="small" />
                              </Form.Item>
                            </Card>
                          </Col>
                        ))
                      }
                    </Row>
                  );
                }}
              </Form.Item>
            </div>
          </>
        )}
        footer={(
          <>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const links = getFieldValue('links') || [];

                return (
                  <Button
                    type="default"
                    onClick={() => form.setFieldsValue({
                      links: [...links, { id: Date.now().toString(), url: '', description: '' }],
                    })}
                  >
                    Add Link
                  </Button>
                );
              }}
            </Form.Item>
            <Form.Item noStyle>
              <Button
                type="primary"
                htmlType="submit"
                loading={request.loading}
              >
                Save
              </Button>
            </Form.Item>
          </>
        )}
      />
    </Form>
  );
}
