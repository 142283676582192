import { Select, SelectProps } from 'antd';
import { Link } from 'react-router-dom';
import useAssistants from '../hooks/useAssistants';
import { getAssistantTypeLabel } from '../utils';
import { AssistantType } from '../../api';
import Button from '../../common/components/Button';
import useActiveWorkspaceSlug from '../../workspace/hooks/useActiveWorkspaceSlug';

interface AssistantSelectProps extends Omit<SelectProps, 'options'> {
  type?: AssistantType;
}

export default function AssistantSelect(props: AssistantSelectProps) {
  const { assistants } = useAssistants();
  const { type, ...selectProps } = props;
  const slug = useActiveWorkspaceSlug();

  return (
    <Select
      options={assistants
        .filter((assistant) => !type || type === assistant.type)
        .map((assistant) => ({
          label: `${assistant.name} (${getAssistantTypeLabel(assistant.type)})`,
          value: assistant._id,
        }))}
      notFoundContent={(
        <div className="m-3 d-flex align-items-center justify-content-center flex-column gap-2">
          <p className="m-0">
            No assistants found
          </p>
          <Link to={`/${slug}/assistants`}>
            <Button size="small">Create Assistant</Button>
          </Link>
        </div>
      )}
      {...selectProps}
    />
  );
}
