import { asset } from './assets';

export const FALLBACK_USER_AVATAR = asset('/static/img/avatar-placeholder.png');

export function getUserAvatar(avatar?: string | null, fallback = true): string | undefined {
  if (avatar) {
    return asset(avatar);
  }

  if (!fallback) {
    return undefined;
  }

  return FALLBACK_USER_AVATAR;
}
