import BaseModel from './BaseModel';

export enum ConversationParticipantType {
  WEBSITE_USER = 'user',
  AGENT = 'agent',
  AI_ASSISTANT = 'ai-assistant',
}

export default interface ConversationParticipant extends BaseModel {
  entityId: BaseModel['_id'];
  type: ConversationParticipantType;
  name: string;
  avatar?: string;
}
