import BaseModel from './BaseModel';
import WebsiteModule from './WebsiteModule';

export enum ConversationIntentType {
  TECHNICAL_ISSUE = 'technical_issue',
  QUESTION = 'question',
}

export enum ConversationIntentStatus {
  OPEN = 'open',
  IN_PROGRESS = 'in-progress',
  CLOSED = 'closed',
}

export enum ConversationIntentResolution {
  UNRESOLVED = 'unresolved',
  RESOLVED = 'resolved',
}

export enum ConversationIntentDiscoveryMode {
  AI = 'ai',
  MANUAL = 'manual',
}

export interface ConversationIntentData {
  /**
   * The type of the conversation intent.
   * What does the user want to talk about?
   */
  type: ConversationIntentType;

  /**
   * The status of the conversation intent.
   * Is the user intent still open, in progress or closed?
   */
  status: ConversationIntentStatus;

  /**
   * The resolution of the conversation intent.
   */
  resolution: ConversationIntentResolution;

  /**
   * The solution to the conversation intent.
   */
  solution?: string;

  /**
   * The summary of the conversation intent.
   */
  summary: string;

  /**
   * A list of tags associated with the conversation intent.
   */
  tags: string[];

  /**
   * The module id that the intent is related to.
   */
  moduleId: WebsiteModule['_id'];

  /**
   * The submodule id that the intent is related to.
   */
  submoduleId: WebsiteModule['_id'];

  /**
   * The conversation id
   */
  conversationId: BaseModel['_id'];
}

export default interface ConversationIntent extends ConversationIntentData, BaseModel {
  /**
   * The module that the intent is related to.
   */
  module: string;

  /**
   * The submodule that the intent is related to.
   */
  submodule: string;

  /**
   * The vector representation of the intent.
   */
  vector: number[];

  /**
   * The discover mode of the intent.
   */
  discoveryMode: ConversationIntentDiscoveryMode;

  /**
   * The user who created the conversation intent.
   */
  createdBy?: BaseModel['_id'];
}
