import Select, { SelectProps } from '../../../common/components/Select';
import useWebsiteModules from '../../hooks/useWebsiteModules';

interface WebsiteUnifiedModuleSelectProps extends Omit<SelectProps, 'options'> {}

export default function WebsiteUnifiedModuleSelect(props: WebsiteUnifiedModuleSelectProps) {
  const { unifiedModulesOptions } = useWebsiteModules();

  return (
    <Select
      options={unifiedModulesOptions}
      placeholder="Select a module"
      showSearch
      {...props}
    />
  );
}
