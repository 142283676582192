import {
  Button, Form, Input, notification,
} from 'antd';
import { User } from '../../api';
import useAuth from '../hooks/useAuth';

export default function SignInForm() {
  const auth = useAuth();
  const onFinish = async (values: Pick<User, 'email' | 'password'>) => {
    try {
      await auth.signIn(values.email, values.password);
    } catch (e) {
      notification.error({
        message: 'Wrong email or password',
        description: 'Please try again',
      });
    }
  };

  return (
    <Form<Pick<User, 'email' | 'password'>>
      layout="vertical"
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: 'Please enter your email' },
          { type: 'email', message: 'Please enter a valid email' },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          { required: true, message: 'Please enter your password' },
          { min: 8, message: 'Password must be at least 8 characters' },
          { max: 32, message: 'Password must be at most 32 characters' },
        ]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary" block>Sign In</Button>
      </Form.Item>
    </Form>
  );
}
