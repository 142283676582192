import { Button, Collapse, Input } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { HTMLProps, useMemo, useState } from 'react';
import MiniSearch from 'minisearch';
import SimpleBar from 'simplebar-react';
import websiteUserApi from '../../../website-user-api';
import {
  Agent, Conversation, FAQ, Website,
} from '../../../api';
import 'simplebar-react/dist/simplebar.min.css';
import UserAvatar from '../../../common/components/UserAvatar';

interface ChatWidgetHomeProps {
  onConversationStarted: (conversation: Conversation) => void;
  widgetHeight?: number;
  faqs?: Website['faqs'];
  agents?: Agent[];
}

function LightingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width="20px"
      height="20px"
      color="var(--color-primary)"
    >
      <rect width="256" height="256" fill="none" />
      <polygon
        points="160 16 144 96 208 120 96 240 112 160 48 136 160 16"
        fill="var(--color-primary)"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
    </svg>
  );
}

function PlusIcon(props: HTMLProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width="18px"
      height="18px"
      {...props}
    >
      <rect width="256" height="256" fill="none" />
      <line
        x1="40"
        y1="128"
        x2="216"
        y2="128"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
      <line
        x1="128"
        y1="40"
        x2="128"
        y2="216"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
      />
    </svg>
  );
}

interface FAQItemProps {
  question: string;
  answer: string;
}

function FAQItem(props: FAQItemProps) {
  const { question, answer } = props;

  return (
    <Collapse
      className="chat-widget-faqsv2-widget-item"
      items={[
        {
          label: (
            <span className="d-flex align-items-center d-flex justify-content-between">
              <span className="chat-widget-faqsv2-widget-item-question">
                {question}
              </span>
              <PlusIcon />
            </span>
          ),
          children: (
            <div className="chat-widget-faqsv2-widget-item-answer-container">
              <p className="chat-widget-faqsv2-widget-item-answer">{answer}</p>
            </div>
          ),
          showArrow: false,
        },
      ]}
      size="small"
      bordered={false}
    />
  );
}

export default function ChatWidgetHome(props: ChatWidgetHomeProps) {
  const {
    onConversationStarted,
    faqs,
    agents,
    widgetHeight = 400,
  } = props;
  const [message, setMessage] = useState('');
  const valid = message.trim().length > 0;
  const [faqSearchString, setFAQSearchString] = useState('');
  const [sending, setSending] = useState(false);

  const faqSearchClean = faqSearchString.trim().toLowerCase();

  const search = useMemo(() => {
    const miniSearch = new MiniSearch<FAQ>({
      idField: 'id',
      fields: ['question', 'answer'],
      storeFields: ['question', 'answer'],
    });

    if (faqs) {
      miniSearch.addAll(faqs);
    }

    return miniSearch;
  }, [faqs?.length]);

  const onSendMessage = () => {
    if (valid) {
      setSending(true);
      websiteUserApi.startConversation(message).then((conversation) => {
        onConversationStarted(conversation);
        setMessage('');
        setSending(false);
      }).catch(() => {
        setSending(false);
      });
    }
  };

  const faqItems = faqSearchClean ? search.search(faqSearchClean, {
    prefix: true,
  }) : faqs;

  return (
    <SimpleBar
      className="chat-widget-home-container"
      style={{
        height: `calc(${widgetHeight}px - var(--chat-widget-nav-height) - var(--powered-by-height) - var(--chat-widget-header-small-height))`,
        maxHeight: `calc(${widgetHeight}px - var(--chat-widget-nav-height) - var(--powered-by-height) - var(--chat-widget-header-small-height))`,
        boxSizing: 'border-box',
      }}
    >
      <div className="chat-widget-home-header">
        <h1 className="mb-1">Hello</h1>
        <p className="chat-widget-home-header-subtitle">
          Zupport AI is here to bring you the best customer service experience available.
          Send us a message to see it in action!
        </p>
      </div>
      <div className="chat-widget-widget-container chat-widget-send-message-widget">
        <p className="chat-widget-send-message-widget-title">Send us a message</p>
        {
          agents ? (
            <div className="d-flex gap-3 align-items-center">
              <div className="my-2 d-flex">
                {
                  agents.map((agent, index) => (
                    <UserAvatar
                      key={agent._id}
                      avatar={agent.avatar}
                      style={{
                        marginLeft: index > 0 ? -20 : 0,
                        border: '2px solid white',
                      }}
                      size={50}
                    >
                      {agent.name}
                    </UserAvatar>
                  ))
                }
              </div>
              <div>
                <p className="m-0 chat-widget-send-message-widget-reply-time-info">
                  Our human or AI agents are replying
                </p>
                <div className="d-flex align-items-center gap-1 mt-1 chat-widget-send-message-widget-reply-time-info-subtitle">
                  <LightingIcon />
                  <span>Instantly</span>
                </div>
              </div>
            </div>
          ) : (
            <p className="chat-widget-send-message-widget-subtitle">
              If you have any questions or need help.
            </p>
          )
        }
        <Input
          className="chat-widget-send-message-widget-input"
          placeholder="Type your message here..."
          size="small"
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          onPressEnter={onSendMessage}
          suffix={(
            <Button
              type="link"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onSendMessage();
              }}
              icon={(
                <SendOutlined />
              )}
              disabled={!valid}
              className="p-0 m-0"
              style={{
                height: 25,
                width: 25,
                color: 'var(--color-primary)',
              }}
              loading={sending}
            />
          )}
        />
      </div>
      {
        faqs && (
          <div className="mt-5 chat-widget-faqsv2-widget">
            <p className="chat-widget-faqsv2-widget-title">FAQ</p>
            <Input
              size="small"
              placeholder="Search"
              className="mb-2"
              value={faqSearchString}
              onChange={(e) => {
                setFAQSearchString(e.target.value);
              }}
            />
            {
              faqItems && faqItems.map((faq) => (
                <FAQItem
                  key={faq.question}
                  question={faq.question}
                  answer={faq.answer}
                />
              ))
            }
          </div>
        )
      }
    </SimpleBar>
  );
}
