import { Form, message } from 'antd';
import { useEffect } from 'react';
import { AppSubPageLayoutContent } from '../../layout/components/AppSubPageLayout';
import { HelpCentersStore } from '../state';
import CardSwitch from '../../common/components/CardSwitch';
import Select from '../../common/components/Select';
import languages from '../../common/config/languages';
import Button from '../../common/components/Button';
import useRequest from '../../common/hooks/useRequest';
import api, { HelpCenterPatchData } from '../../api';

export default function HelpCenterLanguagesPage() {
  const helpCenter = HelpCentersStore.useState((s) => s.activeHelpCenter);
  const [form] = Form.useForm();

  useEffect(() => {
    if (helpCenter) {
      form.setFieldsValue(helpCenter);
    }
  }, [helpCenter]);

  const request = useRequest(async (values: HelpCenterPatchData) => {
    if (!helpCenter) {
      throw new Error('Help center not found');
    }

    return api.helpCenters.patch(helpCenter._id, values);
  }, {
    onSuccess: (data) => {
      HelpCentersStore.update((s) => {
        if (!s.activeHelpCenter) {
          return s;
        }

        return {
          ...s,
          activeHelpCenter: {
            ...s.activeHelpCenter,
            ...data,
          },
        };
      });

      message.success('Help center updated successfully');
    },
  });

  return (
    <Form
      form={form}
      initialValues={helpCenter}
      layout="vertical"
      className="flex-1"
      onFinish={request.submit}
    >
      <AppSubPageLayoutContent
        title="Languages"
        content={(
          <>
            <Form.Item
              name={['config', 'languages', 'enable']}
            >
              <CardSwitch
                title="Enable Multi-Language"
                subtitle="Allow users to switch between multiple languages"
              />
            </Form.Item>
            <Form.Item shouldUpdate>
              {({ getFieldValue }) => {
                const enable = getFieldValue(['config', 'languages', 'enable']);

                if (!enable) {
                  return null;
                }

                return (
                  <>
                    <Form.Item
                      name={['config', 'languages', 'default']}
                      label="Default Language"
                      extra="The default language for your help center. It will be used when a user visits your help center for the first time, as well as when creating new articles."
                      rules={[
                        {
                          required: true,
                          message: 'Please select the default language',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select default language"
                        options={languages}
                      />
                    </Form.Item>
                    <Form.Item
                      name={['config', 'languages', 'available']}
                      label="Available Languages"
                      extra="The languages that users can switch between on your help center. You will have to create articles for each of these languages."
                      rules={[
                        {
                          required: true,
                          message: 'Please select available languages',
                        },
                        {
                          type: 'array',
                          min: 1,
                          message: 'Please select at least one language',
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        placeholder="Select available languages"
                        options={languages}
                      />
                    </Form.Item>
                  </>
                );
              }}
            </Form.Item>
          </>
        )}
        footer={(
          <div className="d-flex justify-content-end flex-1">
            <Button
              type="primary"
              htmlType="submit"
              loading={request.loading}
            >
              Save
            </Button>
          </div>
        )}
      />
    </Form>
  );
}
