import {
  Col, Form, Input, notification, Row,
} from 'antd';
import { AppSubPageLayoutContent } from '../../layout/components/AppSubPageLayout';
import useActiveWorkspace from '../hooks/useActiveWorkspace';
import Button from '../../common/components/Button';
import useRequest from '../../common/hooks/useRequest';
import api, { Workspace } from '../../api';
import { setActiveWorkspace } from '../state';

export default function WorkspaceGeneralSettingsPage() {
  const workspace = useActiveWorkspace();
  const [form] = Form.useForm();

  const request = useRequest(
    async (data: Pick<Workspace, 'name' | 'slug'>) => {
      if (!workspace) {
        throw new Error('Workspace not found');
      }

      return api.workspaces.patch(workspace._id, data);
    },
    {
      form,
      onSuccess: (result) => {
        if (workspace && result.slug !== workspace.slug) {
          window.location.href = `/${result.slug}/settings/general`;
        }

        setActiveWorkspace(result);
        notification.success({
          message: 'Workspace updated',
        });
      },
    },
  );

  if (!workspace) {
    return null;
  }

  return (
    <Form
      form={form}
      layout="vertical"
      className="flex-1"
      initialValues={{
        name: workspace.name,
        slug: workspace.slug,
      }}
      colon={false}
      onFinish={request.submit}
    >
      <AppSubPageLayoutContent
        title="General"
        content={(
          <Row gutter={[16, 16]}>
            <Col span={24} md={12}>
              <Form.Item name="name" label="Name" className="mb-0">
                <Input placeholder="Workspace Name" />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item name="slug" label="Slug" className="mb-0">
                <Input placeholder="Workspace Slug" />
              </Form.Item>
            </Col>
          </Row>
        )}
        footer={(
          <div className="d-flex justify-content-end flex-1">
            <Button
              htmlType="submit"
              type="primary"
              loading={request.loading}
              disabled={request.loading}
            >
              Save
            </Button>
          </div>
        )}
      />
    </Form>
  );
}
