import { Col, Row, Slider } from 'antd';
import { useEffect, useState } from 'react';
import { AppSubPageLayoutContent } from '../../layout/components/AppSubPageLayout';
import plans from '../../billing/utils/plans';
import PricingPlanCard from '../components/PricingPlanCard';
import useActiveWorkspace from '../hooks/useActiveWorkspace';
import useRequest from '../../common/hooks/useRequest';
import api, { WorkspaceUser } from '../../api';
import SubscriptionInfo from '../../billing/components/SubscriptionInfo';
import useQuery from '../../common/hooks/useQuery';
import { pluralize } from '../../common/utils/strings';

export default function WorkspaceBillingPage() {
  const workspace = useActiveWorkspace();
  const [loadingPlan, setLoadingPlan] = useState<string>();

  const { data } = useQuery<WorkspaceUser[]>(async () => {
    if (!workspace) {
      throw new Error('No active workspace');
    }

    const result = await api.workspaces.users.list(workspace._id);
    return result.items;
  }, [workspace?._id]);
  const minQuantity = data?.length || 1;

  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    setQuantity((prev) => (prev < minQuantity ? minQuantity : prev));
  }, [minQuantity]);

  const request = useRequest(
    async (planId: string) => {
      if (!workspace) {
        throw new Error('No active workspace found');
      }

      return api.billing.subscriptions.checkout.sessions.create({
        workspaceId: workspace._id,
        planId,
        quantity,
      });
    },
    {
      onSuccess: (result) => {
        window.location.href = result.url;
        setLoadingPlan(undefined);
      },
      onError: () => {
        setLoadingPlan(undefined);
      },
    },
  );

  const hasSubscription = workspace?.subscription
    && new Date(workspace.subscription.expiresAt) > new Date();

  return (
    <AppSubPageLayoutContent
      title="Billing"
      content={(
        <div className="pb-5">
          {
            workspace && (
              <Row>
                <Col span={24}>
                  <SubscriptionInfo workspace={workspace} />
                </Col>
              </Row>
            )
          }
          {
            !hasSubscription && (
              <>
                <Row className="mt-4">
                  <Col span={24}>
                    <h2>
                      Plans & Pricing
                    </h2>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col span={24}>
                    <p>{`Nr of Seats: ${quantity}`}</p>
                    <Slider
                      min={minQuantity}
                      max={99}
                      defaultValue={minQuantity}
                      value={quantity}
                      onChange={setQuantity}
                      marks={{
                        [minQuantity]: minQuantity,
                        10: '10',
                        20: '20',
                        30: '30',
                        40: '40',
                        50: '50',
                        60: '60',
                        70: '70',
                        80: '80',
                        90: '90',
                        99: '99',
                      }}
                      tooltip={{
                        formatter: (value) => pluralize(Number(value || 1), 'Seat'),
                      }}
                    />
                  </Col>
                </Row>
                <Row
                  className="mt-4"
                  justify="center"
                  gutter={[16, 16]}
                >
                  {
                    plans.map((plan) => (
                      <Col
                        key={plan.name}
                        span={24}
                        md={12}
                        lg={10}
                        xl={8}
                      >
                        <PricingPlanCard
                          plan={plan}
                          onSubscribe={(planId) => {
                            request.submit(planId);
                            setLoadingPlan(planId);
                          }}
                          subscribeLoading={loadingPlan === plan.id}
                          subscribeDisabled={Boolean(loadingPlan)}
                          quantity={quantity}
                        />
                      </Col>
                    ))
                  }
                </Row>
              </>
            )
          }
        </div>
      )}
    />
  );
}
