import { ReactNode } from 'react';
import cn from '../../common/utils/cn';

interface AppPageContentProps {
  children: ReactNode | ReactNode[];
  padding: 'normal' | 'none';
  layout?: 'full' | 'centered';
}

export default function AppPageContent(props: AppPageContentProps) {
  const { children, padding, layout = 'full' } = props;

  return (
    <div
      className={cn(
        'app-page-content',
        `app-page-content-padding-${padding}`,
        `app-page-content-layout-${layout}`,
      )}
    >
      {children}
    </div>
  );
}
