import { message } from 'antd';
import { HelpCentersStore } from '../state';
import HelpCenterForm from '../components/HelpCenterForm';
import { AppSubPageLayoutContent } from '../../layout/components/AppSubPageLayout';

export default function HelpCenterGeneralPage() {
  const helpCenter = HelpCentersStore.useState((s) => s.activeHelpCenter);

  return (
    <AppSubPageLayoutContent
      title="Help Center"
      content={(
        <HelpCenterForm
          helpCenter={helpCenter}
          onSuccess={() => {
            message.success('Help center updated');
          }}
        />
      )}
    />
  );
}
