import { Divider as AntDivider, DividerProps } from 'antd';

export default function Divider(props: DividerProps) {
  return (
    <AntDivider
      style={{ borderColor: 'var(--app-content-separator-border-color)' }}
      {...props}
    />
  );
}
