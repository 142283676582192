import { AxiosInstance, AxiosResponse } from 'axios';

function stringify(obj: Record<string, any>, prefix = ''): string {
  const queryString = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'object' && value !== null) {
      // Recursively call stringify for nested objects
      queryString.push(stringify(value, prefix ? `${prefix}[${key}]` : key));
    } else if (value !== undefined && value !== null) {
      // Encode key and value for URL encoding
      const encodedKey = encodeURIComponent(prefix ? `${prefix}[${key}]` : key);
      const encodedValue = encodeURIComponent(value);
      queryString.push(`${encodedKey}=${encodedValue}`);
    }
  }

  return queryString.join('&');
}

export default abstract class BaseService {
  constructor(readonly axios: AxiosInstance) {}

  static unwrap<T>(response: { data: T }) {
    return response.data;
  }

  async _post<T, R = T>(
    url: string,
    data?: T,
    config?: Record<string, unknown>,
  ): Promise<R> {
    return BaseService.unwrap(await this.axios.post<T, AxiosResponse<R>>(url, data, config));
  }

  protected async _get<R>(url: string, config: Record<string, unknown> = {}): Promise<R> {
    return BaseService.unwrap(await this.axios.get<R>(url, {
      ...config,
      paramsSerializer: (params) => stringify(params),
    }));
  }

  async _delete<R>(url: string, config?: Record<string, unknown>): Promise<R> {
    return BaseService.unwrap(await this.axios.delete<R>(url, config));
  }

  async _patch<T, R = T>(
    url: string,
    data: T,
    config?: Record<string, unknown>,
  ): Promise<R> {
    return BaseService.unwrap(await this.axios.patch<T, AxiosResponse<R>>(url, data, config));
  }
}
