import { Store } from 'pullstate';
import {
  Conversation,
  ConversationMessage,
  ConversationParticipant,
  ConversationWithMetadata,
} from '../../api';

export function getUnreadMessagesCount(
  conversations: ConversationWithMetadata[],
  activeConversation: ConversationWithMetadata | null,
) {
  let count = 0;

  conversations.forEach((conversation) => {
    if (conversation._id !== activeConversation?._id) {
      count += conversation.metadata?.unreadMessagesCount || 0;
    }
  });

  return count;
}

export type ChatWidgetConversationsState = {
  conversations: ConversationWithMetadata[];
  loading: boolean;
  initialized: boolean;
  messages: Record<Conversation['_id'], ConversationMessage[]>;
  participant?: ConversationParticipant;
  unreadMessagesCount: number;
  activeConversation: ConversationWithMetadata | null;
};

export const ChatWidgetConversationsStore = new Store<ChatWidgetConversationsState>({
  conversations: [],
  loading: true,
  initialized: false,
  messages: {},
  unreadMessagesCount: 0,
  activeConversation: null,
});

export type ConversationState = {
  messages: ConversationMessage[];
  loading: boolean;
  pageTrail?: string;
  hasMore: boolean;
};

export const defaultConversationState: ConversationState = {
  messages: [],
  loading: true,
  hasMore: true,
};

export type ChatWidgetConversationState = Record<Conversation['_id'], ConversationState>;
export const ChatWidgetConversationStore = new Store<ChatWidgetConversationState>({});

export function addConversationMessage(conversationId: Conversation['_id'], message: ConversationMessage) {
  ChatWidgetConversationStore.update((s) => {
    const conversationState = s[conversationId] || defaultConversationState;
    const { messages } = conversationState;
    const exists = messages.find(
      (m) => m._id === message._id
        || (message.clientId && m.clientId === message.clientId),
    );

    if (!exists) {
      return {
        ...s,
        [conversationId]: {
          ...conversationState,
          messages: [...messages, message],
        },
      };
    }

    return {
      ...s,
      [conversationId]: {
        ...conversationState,
        messages: messages.map((m) => (m._id === message._id ? message : m)),
      },
    };
  });
}

ChatWidgetConversationsStore.createReaction((s) => s.conversations, (conversations, state) => {
  // eslint-disable-next-line no-param-reassign
  state.unreadMessagesCount = getUnreadMessagesCount(conversations, state.activeConversation);
  window.parent.postMessage({
    message: 'zupport.chat.set.unread:messages',
    count: state.unreadMessagesCount,
  }, '*');
});

export function markConversationAsRead(conversationId: Conversation['_id']) {
  ChatWidgetConversationsStore.update((s) => {
    if (s.activeConversation?._id === conversationId) {
      return {
        ...s,
        activeConversation: {
          ...s.activeConversation,
          metadata: {
            ...s.activeConversation.metadata,
            unreadMessagesCount: 0,
          },
        },
        conversations: s.conversations.map((c) => {
          if (c._id === conversationId) {
            return {
              ...c,
              metadata: {
                ...c.metadata,
                unreadMessagesCount: 0,
              },
            };
          }

          return c;
        }),
      };
    }

    return s;
  });
}

type ChatWidgetState = {
  websiteUid?: string;
};

export const ChatWidgetStore = new Store<ChatWidgetState>({});
