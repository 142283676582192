import { Store } from 'pullstate';
import { Assistant } from '../../api';

export type AssistantsState = {
  assistants: Assistant[];
  initialized: boolean;
  loading: boolean;
};

export const AssistantStore = new Store<AssistantsState>({
  assistants: [],
  initialized: false,
  loading: false,
});
