export default function throttle<T extends (...args: any[]) => any>(func: T, wait: number) {
  let timeout: NodeJS.Timeout | undefined;

  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    const context = this;

    if (!timeout) {
      func.apply(context, args);
      timeout = setTimeout(() => {
        timeout = undefined;
      }, wait);
    }
  };
}
