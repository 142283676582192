import {
  Avatar, Card, Skeleton, Tag,
} from 'antd';
import { EditOutlined, MoreOutlined } from '@ant-design/icons';
import { Assistant } from '../../api';
import { asset } from '../../common/utils/assets';
import Dropdown from '../../common/components/Dropdown';
import Button from '../../common/components/Button';
import { getAssistantTypeLabel } from '../utils';

interface AssistantPreviewCardProps extends Pick<Assistant, 'name' | 'createdAt' | 'avatar' | 'personality' | 'type'> {
  onEditClick?: () => void;
}

export default function AssistantPreviewCard(props: AssistantPreviewCardProps) {
  const {
    type, name, createdAt, avatar, personality, onEditClick,
  } = props;

  return (
    <Card size="small" className="p-1">
      <div className="d-flex align-items-center justify-content-between m-0">
        <div className="d-flex align-items-center gap-3 flex-2">
          <Avatar src={asset(avatar)} size={40} />
          <p className="m-0 fw-bold">{name}</p>
          <Tag className="bg-gray-5 gray-4 px-3 py-1" style={{ borderRadius: 16, border: 'none' }}>
            {getAssistantTypeLabel(type)}
          </Tag>
        </div>
        <p className="m-0 gray-4 flex-2 d-none d-md-block">{personality}</p>
        <div className="flex-1 d-flex align-items-center justify-content-end gap-2">
          <p className="m-0 gray-4">
            {new Date(createdAt).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </p>
          <Dropdown
            items={[
              {
                key: 'edit',
                label: 'Edit',
                icon: <EditOutlined />,
                onClick: onEditClick,
              },
            ]}
            overlayStyle={{
              minWidth: 200,
            }}
          >
            <Button size="small" type="text">
              <MoreOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </Card>
  );
}

export function AssistantPreviewCardSkeleton() {
  return (
    <Skeleton.Input style={{
      width: '100%',
      height: 74,
    }}
    />
  );
}
