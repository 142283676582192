import { Link } from 'react-router-dom';
import SignUpForm from '../components/SignUpForm';
import AuthPageLayout from '../../layout/components/AuthPageLayout';

export default
function SignUpPage() {
  return (
    <AuthPageLayout>
      <h1 className="mb-4">Sign Up</h1>
      <SignUpForm />
      <Link to="/">
        Already have an account? Sign In
      </Link>
    </AuthPageLayout>
  );
}
