import { useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import { Conversation, Website } from '../../../api';
import websiteUserApi from '../../../website-user-api';
import ConversationCardPreview, { ConversationSkeleton } from '../ConversationCardPreview';
import { ChatWidgetConversationsStore } from '../../state';

interface ChatWidgetConversationsProps {
  websiteId?: Website['_id'];
  onConversationClick?: (conversation: Conversation) => void;
  widgetHeight?: number;
}

export async function loadChatWidgetConversations() {
  try {
    const result = await websiteUserApi.listConversations();
    ChatWidgetConversationsStore.update((s) => ({
      ...s,
      conversations: result.items,
      loading: false,
      initialized: true,
    }));
  } catch {
    ChatWidgetConversationsStore.update((s) => ({
      ...s,
      loading: false,
      initialized: true,
    }));
  }
}

export default function ChatWidgetConversations(props: ChatWidgetConversationsProps) {
  const {
    websiteId,
    onConversationClick,
    widgetHeight = 400,
  } = props;
  const conversations = ChatWidgetConversationsStore.useState((s) => s.conversations);
  const loading = ChatWidgetConversationsStore.useState((s) => s.loading);

  useEffect(() => {
    if (websiteId) {
      loadChatWidgetConversations();
    }
  }, [websiteId]);

  console.info('convos', conversations);
  return (
    <SimpleBar
      style={{
        height: `calc(${widgetHeight}px - var(--chat-widget-nav-height) - var(--powered-by-height) - var(--chat-widget-header-small-height))`,
        maxHeight: `calc(${widgetHeight}px - var(--chat-widget-nav-height) - var(--powered-by-height) - var(--chat-widget-header-small-height))`,
      }}
    >
      {
        loading && (
          <>
            <ConversationSkeleton />
            <ConversationSkeleton />
            <ConversationSkeleton />
            <ConversationSkeleton />
            <ConversationSkeleton />
            <ConversationSkeleton />
            <ConversationSkeleton />
            <ConversationSkeleton />
            <ConversationSkeleton />
            <ConversationSkeleton />
            <ConversationSkeleton />
            <ConversationSkeleton />
          </>
        )
      }
      {
        !loading && conversations.map((conversation) => (
          <ConversationCardPreview
            key={`${conversation._id}-${conversation.metadata?.unreadMessagesCount}-${conversation.lastMessage?._id}`}
            onClick={() => onConversationClick?.(conversation)}
            {...conversation}
          />
        ))
      }
    </SimpleBar>
  );
}
