import { ButtonProps } from 'antd';
import useRequest from '../../../common/hooks/useRequest';
import api from '../../../api';
import useActiveWorkspace from '../../../workspace/hooks/useActiveWorkspace';
import Button from '../../../common/components/Button';

export default function ManageSubscriptionButton(props: Partial<ButtonProps>) {
  const { children = 'Manage', onClick, ...rest } = props;

  const workspace = useActiveWorkspace();

  const manageRequest = useRequest(
    async () => {
      if (!workspace) {
        throw new Error('No active workspace found');
      }

      return api.billing.portal.sessions.create({ workspaceId: workspace._id });
    },
    {
      onSuccess: (result) => {
        window.location.href = result.url;
      },
    },
  );

  return (
    <Button
      loading={manageRequest.loading}
      type="primary"
      {...rest}
      onClick={(e) => {
        e.persist();

        manageRequest.submit().then(() => {}).finally(() => {
          if (onClick) {
            onClick(e);
          }
        });
      }}
    >
      {children}
    </Button>
  );
}
