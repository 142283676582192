import { Store } from 'pullstate';
import { HelpCenter } from '../../api';

interface HelpCentersState {
  activeHelpCenter?: HelpCenter;
  loading: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const HelpCentersStore = new Store<HelpCentersState>({
  activeHelpCenter: undefined,
  loading: false,
});
