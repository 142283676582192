import { Tag, Tooltip } from 'antd';
import {
  ClockCircleOutlined,
  DeleteOutlined, EditOutlined, IssuesCloseOutlined,
} from '@ant-design/icons';
import Button from '../../../common/components/Button';
import styles from './index.module.scss';
import { ConversationIntent, ConversationIntentStatus } from '../../../api';
import cn from '../../../common/utils/cn';

interface ConversationInfoIntentCardProps extends Pick<
ConversationIntent,
'summary' | 'module' | 'submodule' | 'resolution' | 'status'
> {
  onEditClick?: () => void;
  onDeleteClick?: () => void;
}

export default function ConversationInfoIntentCard(props: ConversationInfoIntentCardProps) {
  const {
    summary, module, submodule, onEditClick, resolution, onDeleteClick, status,
  } = props;

  return (
    <div className={cn(styles.intent, styles[resolution])}>
      <div className={styles.infos}>
        {
          status === ConversationIntentStatus.IN_PROGRESS && (
            <Tooltip title="This intent is in progress" placement="left">
              <ClockCircleOutlined style={{ color: '#244def' }} />
            </Tooltip>
          )
        }
        {
          status === ConversationIntentStatus.CLOSED && (
            <Tooltip title="This intent has been closed" placement="left">
              <IssuesCloseOutlined style={{ color: '#1ab805' }} />
            </Tooltip>
          )
        }
      </div>
      <p
        className={styles.summary}
        style={{
          paddingRight: [
            ConversationIntentStatus.CLOSED,
            ConversationIntentStatus.IN_PROGRESS,
          ].includes(status) ? 30 : 0,
        }}
      >
        {summary}
      </p>
      <div className="mt-1 d-flex align-items-center justify-content-between">
        <div>
          <Tag bordered={false} color="blue">{module}</Tag>
          <Tag bordered={false} color="blue">{submodule}</Tag>
        </div>
        <div className={styles.actions}>
          <Button
            type="link"
            icon={(
              <EditOutlined />
            )}
            size="small"
            style={{
              maxHeight: 22,
            }}
            onClick={onEditClick}
          />
          <Button
            type="link"
            icon={(
              <DeleteOutlined />
            )}
            size="small"
            style={{
              maxHeight: 22,
            }}
            onClick={onDeleteClick}
            danger
          />
        </div>
      </div>
    </div>
  );
}
