import { Link } from 'react-router-dom';
import SignInForm from '../components/SignInForm';
import AuthPageLayout from '../../layout/components/AuthPageLayout';

export default function SignInPage() {
  return (
    <AuthPageLayout>
      <h1 className="mb-4">Sign In</h1>
      <SignInForm />
      <Link to="/sign-up">
        Don&apos;t have an account? Sign Up
      </Link>
    </AuthPageLayout>
  );
}
