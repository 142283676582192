import { Store } from 'pullstate';
import { notification } from 'antd';
import api, { Workspace, WorkspaceUser } from '../../api';
import { getWorkspaceUser, setWorkspaceUser } from '../../auth/utils';
import { setWebsite } from '../../website/state';

const LOCAL_STORAGE_KEY = 'workspace';

type WorkspaceState = {
  id?: string;
  slug?: string;
  workspace?: Workspace;
  loading: true;
  workspaces: Workspace[];
  user?: WorkspaceUser;
};

export function getActiveWorkspace(): Workspace | undefined {
  const workspace = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (workspace) {
    return JSON.parse(workspace);
  }

  return undefined;
}

function getInitialState(): Partial<WorkspaceState> {
  const workspace = getActiveWorkspace();

  if (workspace) {
    return {
      id: workspace._id,
      slug: workspace.slug,
      workspace,
      user: getWorkspaceUser(),
    };
  }

  return {};
}

export const WorkspaceStore = new Store<WorkspaceState>({
  ...getInitialState(),
  workspaces: [],
  loading: true,
});

export function clearActiveWorkspace() {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
  WorkspaceStore.update((s) => ({
    ...s,
    id: undefined,
    slug: undefined,
    workspace: undefined,
    loading: false,
  }));
}

export async function setActiveWorkspace(workspace: Workspace) {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(workspace));

  try {
    const [user, website] = await Promise.all([
      api.workspaces.users.me(workspace._id),
      api.websites.get(workspace.websiteId),
    ]);
    setWorkspaceUser(user);
    setWebsite(website);
    WorkspaceStore.update((s) => ({
      ...s,
      id: workspace._id,
      slug: workspace.slug,
      workspace,
      user,
      loading: false,
    }));
  } catch (e) {
    notification.error({
      message: 'Failed to load workspace user',
      description: 'Please contact support',
    });
  }
}
