import BaseService from './BaseService';
import Upload from '../models/Upload';

type CreateUploadData = Pick<Upload, 'type' | 'workspaceId'> & {
  file: File;
  helpCenterId?: string;
};

export default class Uploads extends BaseService {
  async create(data: CreateUploadData): Promise<Upload> {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('name', data.file.name);
    formData.append('type', data.type);
    formData.append('workspaceId', data.workspaceId);

    if (data.helpCenterId) {
      formData.append('helpCenterId', data.helpCenterId);
    }

    return this._post<FormData, Upload>('/uploads', formData);
  }
}
