import { Outlet, useMatch } from 'react-router-dom';
import { CreditCardOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import AppSubPageLayout from '../../layout/components/AppSubPageLayout';

export default function WorkspaceSettingsPagesWrapper() {
  const match = useMatch('/:workspaceSlug/settings/:pageKey?/?*');
  const activePageKey = match?.params.pageKey || 'general';
  return (
    <AppSubPageLayout
      pages={[
        {
          key: 'general',
          title: 'General',
          path: 'general',
          icon: <SettingOutlined />,
        },
        {
          key: 'members',
          title: 'Members',
          path: 'members',
          icon: <UserOutlined />,
        },
        {
          key: 'billing',
          title: 'Billing',
          path: 'billing',
          icon: <CreditCardOutlined />,
        },
      ]}
      activePageKey={activePageKey}
      content={<Outlet />}
    />
  );
}
