import { Form, Input } from 'antd';
import { useEffect } from 'react';
import Button from '../../../common/components/Button';
import useRequest from '../../../common/hooks/useRequest';
import api, { HelpCenter } from '../../../api';
import useActiveWorkspaceId from '../../../workspace/hooks/useActiveWorkspaceId';
import useActiveWebsite from '../../../website/hooks/useActiveWebsite';

interface HelpCenterFormProps {
  helpCenter?: HelpCenter;
  onSuccess?: (helpCenter: HelpCenter) => void;
}

export default function HelpCenterForm(props: HelpCenterFormProps) {
  const { helpCenter, onSuccess } = props;

  const workspaceId = useActiveWorkspaceId();
  const website = useActiveWebsite();
  const [form] = Form.useForm();

  useEffect(() => {
    if (helpCenter) {
      form.setFieldsValue(helpCenter);
    }
  }, [JSON.stringify(helpCenter)]);

  const request = useRequest(
    async (values: Pick<HelpCenter, 'name' | 'slug'>) => {
      if (helpCenter) {
        return api.helpCenters.patch(helpCenter._id, values);
      }

      if (!workspaceId) {
        throw new Error('No active workspace');
      }

      if (!website) {
        throw new Error('No active website');
      }

      return api.helpCenters.create({
        ...values,
        workspaceId,
        websiteId: website._id,
      });
    },
    {
      onSuccess,
      form,
    },
  );

  return (
    <Form
      initialValues={{
        name: '',
        slug: '',
      }}
      layout="vertical"
      onFinish={request.submit}
      form={form}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please specify the Help Center name' }]}
      >
        <Input placeholder="Name" />
      </Form.Item>
      <Form.Item
        label="Slug"
        name="slug"
        rules={[
          {
            required: true,
            message: 'Please specify the Help Center slug',
          },
          {
            pattern: /^(?!.*--)(?=.*[a-zA-Z])[a-zA-Z0-9]+(-?[a-zA-Z0-9]+)*$/,
            message: 'Slug can only contain lowercase alphanumeric characters and dashes',
          },
        ]}
      >
        <Input
          addonBefore="zupport.ai/help/"
          placeholder="your-slug-here"
        />
      </Form.Item>
      <Form.Item>
        <div className="d-flex align-items-center justify-content-center">
          <Button
            type="primary"
            htmlType="submit"
            loading={request.loading}
          >
            {
              !helpCenter ? 'Create Help Center' : 'Update Help Center'
            }
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
}
