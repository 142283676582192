import {
  Card, Col, Form, InputNumber, notification, Row, Switch,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { AppSubPageLayoutContent } from '../../layout/components/AppSubPageLayout';
import Button from '../../common/components/Button';
import api, { AssistantType, ProactiveEngagementSequence, Website } from '../../api';
import useRequest from '../../common/hooks/useRequest';
import AssistantSelect from '../../assistants/components/AssistantSelect';
import useActiveWebsite from '../hooks/useActiveWebsite';
import { setWebsite } from '../state';

export default function WebsiteProactiveEngagementSettings() {
  const [form] = Form.useForm();
  const website = useActiveWebsite();

  const request = useRequest<Website>(async (values: any) => {
    if (!website) {
      throw new Error('Website not found');
    }

    return api.websites.patch(website._id, {
      proactiveEngagement: values,
    });
  }, {
    onSuccess: (response) => {
      setWebsite(response);
      notification.success({
        message: 'Proactive Engagement settings saved',
      });
    },
  });

  return (
    <Form
      className="flex-1"
      layout="vertical"
      colon={false}
      requiredMark={false}
      initialValues={website?.proactiveEngagement || {
        enabled: false,
        sequences: [{
          id: String(Date.now()),
          minimumWaitTime: 0,
          numberOfVisits: 0,
          triggerDelay: 20,
          triggerDelayVariation: 5,
        }],
      }}
      form={form}
      onFinish={request.submit}
    >
      <AppSubPageLayoutContent
        title="Proactive Engagement"
        content={(
          <>
            <div>
              Manage your Proactive Engagement settings here.
            </div>
            <div
              className="mt-5 d-flex justify-content-between align-items-center gap-3 fw-bold"
            >
              <p className="m-0">
                Enable Proactive Engagement
              </p>
              <Form.Item
                name="enabled"
                valuePropName="checked"
                noStyle
              >
                <Switch
                  checkedChildren="Enabled"
                  unCheckedChildren="Disabled"
                />
              </Form.Item>
            </div>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const enabled = getFieldValue('enabled');

                if (!enabled) {
                  return null;
                }

                const sequences = getFieldValue('sequences') as ProactiveEngagementSequence[] | undefined;

                return (
                  <div className="mt-5">
                    <Form.Item
                      name="assistantId"
                      label="Assistant"
                      rules={[
                        {
                          required: true,
                          message: 'Please select an assistant',
                        },
                      ]}
                    >
                      <AssistantSelect
                        type={AssistantType.PROACTIVE_ENGAGEMENT}
                        placeholder="Select an assistant"
                      />
                    </Form.Item>
                    {
                      sequences && (
                        <div>
                          {
                            sequences.map((sequence, index) => (
                              <Card
                                className="mt-2"
                                key={`sequence-${sequence.id}`}
                                title={`Sequence ${index + 1}`}
                                extra={sequences.length > 1 && (
                                  <Button
                                    type="link"
                                    size="small"
                                    onClick={() => {
                                      const newSequences = [...sequences];
                                      newSequences.splice(index, 1);
                                      form.setFieldsValue({ sequences: newSequences });
                                    }}
                                    icon={<DeleteOutlined />}
                                  />
                                )}
                                size="small"
                              >
                                <Form.Item
                                  name={['sequences', index, 'id']}
                                  hidden
                                >
                                  <InputNumber />
                                </Form.Item>
                                <Row gutter={16}>
                                  <Col span={24}>
                                    <div className="d-flex align-items-center flex-wrap gap-2">
                                      <Form.Item>
                                        Wait for
                                      </Form.Item>
                                      <Form.Item
                                        name={['sequences', index, 'minimumWaitTime']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please enter a minimum wait time',
                                          },
                                          {
                                            validator: (_, value) => {
                                              if (value < 0) {
                                                return Promise.reject(new Error('Minimum wait time must be at least 0'));
                                              }
                                              return Promise.resolve();
                                            },
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          size="small"
                                          style={{ width: 80 }}
                                          min={0}
                                        />
                                      </Form.Item>
                                      <Form.Item>
                                        {' '}
                                        hours after the previous sequence,
                                        and at least
                                        {' '}
                                      </Form.Item>
                                      <Form.Item
                                        name={['sequences', index, 'numberOfVisits']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please enter a number of visits',
                                          },
                                          {
                                            validator: (_, value) => {
                                              if (value < 0) {
                                                return Promise.reject(new Error('Number of visits must be at least 0'));
                                              }
                                              return Promise.resolve();
                                            },
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          size="small"
                                          style={{ width: 80 }}
                                          min={0}
                                        />
                                      </Form.Item>
                                      <Form.Item>
                                        {' '}
                                        {' '}
                                        visits.
                                      </Form.Item>
                                    </div>
                                    <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                                      <Form.Item>
                                        Trigger the sequence once the user has spent more than
                                      </Form.Item>
                                      <Form.Item
                                        name={['sequences', index, 'triggerDelay']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please enter a trigger delay',
                                          },
                                          {
                                            validator: (_, value) => {
                                              if (value < 0) {
                                                return Promise.reject(new Error('Trigger delay must be at least 0'));
                                              }
                                              return Promise.resolve();
                                            },
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          size="small"
                                          style={{ width: 80 }}
                                          min={0}
                                        />
                                      </Form.Item>
                                      <Form.Item>
                                        seconds on the website with a variation of
                                      </Form.Item>
                                      <Form.Item
                                        name={['sequences', index, 'triggerDelayVariation']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please enter a trigger delay variation',
                                          },
                                          {
                                            validator: (_, value) => {
                                              if (value < 0) {
                                                return Promise.reject(new Error('Trigger delay variation must be at least 0'));
                                              }
                                              return Promise.resolve();
                                            },
                                          },
                                        ]}
                                      >
                                        <InputNumber
                                          size="small"
                                          style={{ width: 80 }}
                                          min={0}
                                        />
                                      </Form.Item>
                                      <Form.Item>
                                        seconds
                                      </Form.Item>
                                    </div>
                                  </Col>
                                </Row>
                              </Card>
                            ))
                          }
                          <div className="d-flex justify-content-end mt-4">
                            <Button
                              type="default"
                              size="small"
                              onClick={() => {
                                const newSequences = [...sequences];
                                newSequences.push({
                                  id: Date.now()
                                    .toString(),
                                  minimumWaitTime: 24,
                                  numberOfVisits: 5,
                                  triggerDelay: 20,
                                  triggerDelayVariation: 5,
                                });
                                form.setFieldsValue({ sequences: newSequences });
                              }}
                            >
                              Add Sequence
                            </Button>
                          </div>
                        </div>
                      )
                    }
                  </div>
                );
              }}
            </Form.Item>
          </>
        )}
        footer={(
          <>
            <span />
            <Form.Item noStyle>
              <Button
                type="primary"
                htmlType="submit"
                loading={request.loading}
              >
                Save
              </Button>
            </Form.Item>
          </>
        )}
      />
    </Form>
  );
}
