import { Tag } from 'antd';
import { ContentWebsitePageCrawlingStatus } from '../../../api';

export const ContentWebsitePageCrawlingStatusLabels = {
  [ContentWebsitePageCrawlingStatus.IN_PROGRESS]: 'In progress',
  [ContentWebsitePageCrawlingStatus.SUCCESS]: 'Success',
  [ContentWebsitePageCrawlingStatus.FAILED]: 'Failed',
  [ContentWebsitePageCrawlingStatus.EXCLUDED]: 'Excluded',
};

const ContentWebsitePageCrawlingStatusColors = {
  [ContentWebsitePageCrawlingStatus.IN_PROGRESS]: 'processing',
  [ContentWebsitePageCrawlingStatus.SUCCESS]: 'success',
  [ContentWebsitePageCrawlingStatus.FAILED]: 'error',
  [ContentWebsitePageCrawlingStatus.EXCLUDED]: 'default',
};

interface WebsiteStatusTagProps {
  status: ContentWebsitePageCrawlingStatus;
}

export default function WebsiteStatusTag(props: WebsiteStatusTagProps) {
  const { status } = props;
  return (
    <Tag
      style={{ margin: 0 }}
      color={ContentWebsitePageCrawlingStatusColors[status]}
      className="text-end"
    >
      {ContentWebsitePageCrawlingStatusLabels[status]}
    </Tag>
  );
}
