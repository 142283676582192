import {
  BasicTextStyleButton,
  BlockTypeSelect, ColorStyleButton, CreateLinkButton,
  FileCaptionButton,
  FileReplaceButton,
  FormattingToolbar,
  FormattingToolbarController, NestBlockButton, TextAlignButton, UnnestBlockButton,
} from '@blocknote/react';
import React from 'react';
import FullWidthButton from './FullWidthButton';

function FT() {
  return (
    <FormattingToolbar>
      <BlockTypeSelect key="blockTypeSelect" />

      {/* Extra button to toggle blue text & background */}
      <FullWidthButton key="customButton" />

      <FileCaptionButton key="fileCaptionButton" />
      <FileReplaceButton key="replaceFileButton" />

      <BasicTextStyleButton
        basicTextStyle="bold"
        key="boldStyleButton"
      />
      <BasicTextStyleButton
        basicTextStyle="italic"
        key="italicStyleButton"
      />
      <BasicTextStyleButton
        basicTextStyle="underline"
        key="underlineStyleButton"
      />
      <BasicTextStyleButton
        basicTextStyle="strike"
        key="strikeStyleButton"
      />

      <TextAlignButton
        textAlignment="left"
        key="textAlignLeftButton"
      />
      <TextAlignButton
        textAlignment="center"
        key="textAlignCenterButton"
      />
      <TextAlignButton
        textAlignment="right"
        key="textAlignRightButton"
      />

      <ColorStyleButton key="colorStyleButton" />

      <NestBlockButton key="nestBlockButton" />
      <UnnestBlockButton key="unnestBlockButton" />

      <CreateLinkButton key="createLinkButton" />
    </FormattingToolbar>
  );
}

export default function EditorFormattingToolbarController() {
  return (
    <FormattingToolbarController formattingToolbar={FT} />
  );
}
