import { Card } from 'antd';
import { Link } from 'react-router-dom';
import { HelpCenter } from '../../../api';
import Button from '../../../common/components/Button';

interface HelpCenterPreviewCardProps extends Pick<HelpCenter, 'name' | 'slug'> {}

export default function HelpCenterPreviewCard(props: HelpCenterPreviewCardProps) {
  const { name, slug } = props;

  return (
    <Link to={slug}>
      <Card size="small">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="m-0">{name}</h3>
          <Button size="small">Open</Button>
        </div>
      </Card>
    </Link>
  );
}
