import { Store } from 'pullstate';
import { Website, WebsiteModule } from '../../api';
import { SelectOption } from '../../common/components/Select';

const WEBSITE_LOCAL_STORAGE_KEY = 'website';

export type WebsiteState = {
  website?: Website;
  loading: boolean;
  modules: WebsiteModule[];
  submodules: WebsiteModule[];
  modulesOptions: SelectOption[];
  submodulesOptions: SelectOption[];
  modulesInitialized: boolean;
  unifiedModules: WebsiteModule[];
  unifiedModulesOptions: SelectOption[];
};

function getWebsite(): Website | undefined {
  const website = localStorage.getItem(WEBSITE_LOCAL_STORAGE_KEY);
  if (!website) {
    return undefined;
  }

  return JSON.parse(website);
}

export const WebsiteStore = new Store<WebsiteState>({
  website: getWebsite(),
  loading: true,
  modules: [],
  submodules: [],
  modulesOptions: [],
  submodulesOptions: [],
  modulesInitialized: false,
  unifiedModules: [],
  unifiedModulesOptions: [],
});

export function setWebsite(website: Website) {
  localStorage.setItem(WEBSITE_LOCAL_STORAGE_KEY, JSON.stringify(website));
  WebsiteStore.update((s) => ({ ...s, website, loading: false }));
}
