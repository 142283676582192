import { useLocation, useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

export default function useURLBuilder() {
  const { pathname } = useLocation();
  const [params] = useSearchParams();

  return useCallback((newParams: Record<string, string>): string => {
    const searchParams = new URLSearchParams(params);
    Object.entries(newParams).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    });
    return `${pathname}?${searchParams.toString()}`;
  }, [pathname, params]);
}
