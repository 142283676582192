import { Store } from 'pullstate';

// Define a type for the hook function that returns the value of the specified key
type StoreHook<T extends object, Key extends keyof T> = () => T[Key];

// Define the storeHookFactory function
// eslint-disable-next-line import/prefer-default-export
export function storeHookFactory<T extends object>(store: Store<T>) {
  // Return a hook function for accessing the specified key
  return function <Key extends keyof T>(key: Key): StoreHook<T, Key> {
    return () => store.useState((s) => s[key]);
  };
}
