export default function BookOpenTextIcon() {
  return (
    <span className="anticon anticon-setting ant-menu-item-icon">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
        <rect width="256" height="256" fill="none" />
        <path
          d="M128,88a32,32,0,0,1,32-32h72V200H160a32,32,0,0,0-32,32"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="24"
        />
        <path
          d="M24,200H96a32,32,0,0,1,32,32V88A32,32,0,0,0,96,56H24Z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="24"
        />
        <line
          x1="164"
          y1="108"
          x2="196"
          y2="108"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="24"
        />
        <line
          x1="164"
          y1="148"
          x2="196"
          y2="148"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="24"
        />
      </svg>
    </span>
  );
}
