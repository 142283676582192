import {
  Card, Col, Row,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import AppPageContent from '../../layout/components/AppPageContent';
import HelpCenterForm from '../components/HelpCenterForm';

export default function CreateHelpCenterPage() {
  const navigate = useNavigate();

  return (
    <AppPageContent padding="normal">
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <div className="flex-1">
          <Row justify="center">
            <Col span={12} md={12} lg={10} xl={8} xxl={6}>
              <Card title="Create Help Center">
                <HelpCenterForm
                  onSuccess={(result) => {
                    navigate(`../help-centers/${result.slug}`);
                  }}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </AppPageContent>
  );
}
