import { getDefaultReactSlashMenuItems, SuggestionMenuController } from '@blocknote/react';
import { FileImageOutlined } from '@ant-design/icons';
import { BlockNoteEditor, insertOrUpdateBlock } from '@blocknote/core';
import React from 'react';

interface EditorSuggestionMenuControllerProps {
  editor: BlockNoteEditor<any, any, any>;
}

export default function EditorSuggestionMenuController(props: EditorSuggestionMenuControllerProps) {
  const { editor } = props;

  return (
    <SuggestionMenuController
      triggerCharacter="/"
      getItems={async (query) => {
        const available = [
          ...getDefaultReactSlashMenuItems(editor).filter((item) => item.title !== 'Emoji'),
          {
            icon: <FileImageOutlined />,
            onItemClick: () => {
              const insertedBlock = insertOrUpdateBlock(editor, {
                type: 'image',
              });

              // Immediately open the file toolbar
              editor.dispatch(
                // eslint-disable-next-line no-underscore-dangle
                editor._tiptapEditor.state.tr.setMeta(editor.filePanel!.plugin, {
                  block: insertedBlock,
                }),
              );
            },
            key: 'image',
            ...editor.dictionary.slash_menu.image,
          },
        ];

        const filtered = available.filter((item) => {
          const cleanSearch = query.toLowerCase().trim();

          return item.title.toLowerCase().includes(cleanSearch);
        });

        return filtered.length > 0 ? filtered : available;
      }}
    />
  );
}
