import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { Socket, io } from 'socket.io-client';
import {
  WebsiteUser,
  ClientToServerEvents,
  ConversationMessage,
  ConversationWithMetadata,
  Paginated,
  ServerToClientEvents,
} from '../api';
import Query from '../api/types/Query';

class WebsiteUserApi {
  axios: AxiosInstance;

  token: string | undefined;

  websiteUid: string | undefined;

  baseAPI: string | undefined;

  origin: string | undefined;

  io: Socket<ServerToClientEvents, ClientToServerEvents> | undefined;

  constructor() {
    this.axios = axios.create();

    this.axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      // eslint-disable-next-line prefer-destructuring
      const token = this.token;

      if (token) {
        return {
          ...config,
          headers: {
            ...config.headers,
            'x-wu-token': token,
          },
        } as unknown as InternalAxiosRequestConfig;
      }

      return config as InternalAxiosRequestConfig;
    });
  }

  static buildToken(user: Pick<WebsiteUser, 'uid' | 'secret'>) {
    return `${user.uid}:${user.secret}`;
  }

  init(baseAPI: string, websiteUid: string, origin: string, user: Pick<WebsiteUser, 'uid' | 'secret'>) {
    this.axios.defaults.baseURL = baseAPI;
    this.baseAPI = baseAPI;
    this.websiteUid = websiteUid;
    this.origin = origin;
    this.token = WebsiteUserApi.buildToken(user);
  }

  async startConversation(message: string) {
    const result = await this.axios.post(`/widgets/chat/${this.websiteUid}/conversations/start`, {
      message,
      origin: this.origin,
    });

    return result.data;
  }

  async sendMessage(conversationId: string, message: string, clientId: string) {
    const result = await this.axios.post(`/widgets/chat/${this.websiteUid}/conversations/${conversationId}/messages`, {
      message,
      clientId,
    });

    return result.data;
  }

  async triggerSequence(sequenceId: string) {
    const result = await this.axios.post(`/widgets/events/sequences/trigger/${sequenceId}`, {
      origin: this.origin,
    });

    return result.data;
  }

  async listConversations(): Promise<Paginated<ConversationWithMetadata>> {
    const result = await this.axios.get(`/widgets/chat/${this.websiteUid}/conversations`);

    return result.data;
  }

  async setUserEmail(userId: string, email: string, secret: string) {
    const result = await this.axios.patch(`/widgets/chat/${this.websiteUid}/users/${userId}/set-email`, {
      email,
      secret,
    });

    return result.data;
  }

  async listConversationMessages(
    conversationId: string,
    params: Query,
  ): Promise<Paginated<ConversationMessage>> {
    const result = await this.axios.get(`/widgets/chat/${this.websiteUid}/conversations/${conversationId}/messages`, { params });

    return result.data;
  }

  markConversationAsRead(websiteId: string, conversationId: string) {
    return this.axios.post(`/widgets/chat/${websiteId}/conversations/${conversationId}/read`);
  }

  socket(): Socket {
    if (!this.io) {
      if (!this.baseAPI) {
        throw new Error('WebsiteUserApi not initialized');
      }

      this.io = io(this.baseAPI, {
        auth: {
          'x-wut-token': this.token || '',
        },
      });
    }

    return this.io;
  }
}

export default new WebsiteUserApi();
