import { useParams } from 'react-router-dom';
import useQuery from '../../common/hooks/useQuery';
import api, { HelpCenterArticle } from '../../api';
import HelpCenterArticleEditorPage from './HelpCenterArticleEditorPage';

export default function HelpCenterArticlePage() {
  const params = useParams<{ articleId: string }>();
  const { articleId } = params;

  const query = useQuery<HelpCenterArticle | undefined>(
    async () => {
      if (!articleId) {
        throw new Error('Article ID is required');
      }

      return api.helpCenters.articles.get(articleId);
    },
  );

  return (
    <HelpCenterArticleEditorPage article={query.data || undefined} />
  );
}
