import { ReactNode } from 'react';

interface PermissionCheckerProps {
  allowed: boolean;
  children: ReactNode | ReactNode[];
}

export default function PermissionChecker(props: PermissionCheckerProps) {
  const { allowed, children } = props;

  if (!allowed) {
    return (
      <div className="min-vh-100">
        <h2 className="text-center">
          You are not allowed to access this page, please contact the workspace owner.
        </h2>
      </div>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
