import {
  Alert,
  Col, Form, Input, message, Row, Typography,
} from 'antd';
import { useEffect } from 'react';
import { AppSubPageLayoutContent } from '../../layout/components/AppSubPageLayout';
import Button from '../../common/components/Button';
import useRequest from '../../common/hooks/useRequest';
import api, { HelpCenter, HelpCenterVerificationStatus } from '../../api';
import useActiveHelpCenter from '../hooks/useActiveHelpCenter';
import { HelpCentersStore } from '../state';
import Spinner from '../../common/components/Spinner';

export default function HelpCenterDomainsPage() {
  const helpCenter = useActiveHelpCenter();
  const [form] = Form.useForm();

  useEffect(() => {
    if (helpCenter) {
      form.setFieldsValue({
        domain: helpCenter.domain,
      });
    }
  }, [helpCenter]);

  const request = useRequest(
    async (values: Pick<HelpCenter, 'domain'>) => {
      if (!helpCenter) {
        throw new Error('Help center not loaded yet');
      }

      return api.helpCenters.patch(helpCenter._id, values);
    },
    {
      onSuccess: (result) => {
        message.success('Domain updated');
        HelpCentersStore.update((s) => ({
          ...s,
          activeHelpCenter: result,
        }));
      },
    },
  );

  return (
    <AppSubPageLayoutContent
      title="Domains"
      content={(
        <Row>
          <Col span={24} md={12}>
            <Form
              layout="vertical"
              onFinish={request.submit}
              form={form}
            >
              {
                helpCenter?.domain
                && helpCenter.domainVerificationStatus
                === HelpCenterVerificationStatus.UNVERIFIED && (
                  <Alert
                    className="mb-4"
                    message={(
                      <div>
                        <div className="d-flex gap-2 mb-3">
                          <p className="m-0">
                            Domain verification is pending
                          </p>
                          <Spinner />
                        </div>
                        <div>
                          <span>
                            Please verify your domain by adding the following
                            {' '}
                            <Typography.Text code>CNAME</Typography.Text>
                            {' '}
                            record to your DNS configuration:
                          </span>
                          <Typography.Text code color="red" copyable>
                            zupport-5ksk.onrender.com
                          </Typography.Text>
                          .
                          <p className="mb-0">
                            Please note that it may take up to 24 hours
                            for the changes to take effect.
                          </p>
                        </div>
                      </div>
                    )}
                  />
                )
              }
              {
                helpCenter?.domain
                && helpCenter.domainVerificationStatus
                === HelpCenterVerificationStatus.VERIFIED && (
                  <Alert
                    className="mb-4"
                    message={(
                      <div>
                        <p className="m-0">
                          Domain verified
                        </p>
                        <p>
                          You can now access your help center at
                          {' '}
                          <a href={`https://${helpCenter.domain}`} target="_blank" rel="noreferrer">
                            {helpCenter.domain}
                          </a>
                        </p>
                      </div>
                    )}
                    type="success"
                  />
                )
              }
              <Form.Item
                label="Domain"
                extra="Allow your customers to access your help center using a custom domain"
                name="domain"
                rules={[
                  {
                    pattern: /^(localhost|([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}$)/,
                    message: 'Invalid domain',
                  },
                ]}
              >
                <Input placeholder="Enter domain name" />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={request.loading}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      )}
    />
  );
}
