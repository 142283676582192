import BaseModel from './BaseModel';

export enum AssistantType {
  CUSTOMER_SUPPORT = 'cs',
  PROACTIVE_ENGAGEMENT = 'pe',
}

export interface AssistantData {
  type: AssistantType;
  name: string;
  personality: string;
  avatar: string;
  websiteId: BaseModel['_id'];
  instructions: string;
}

export default interface Assistant extends AssistantData, BaseModel {}
