import { useEffect, useState } from 'react';
import useSize from '../../../common/hooks/useSize';
import { asset } from '../../../common/utils/assets';
import styles from './index.module.scss';

export default function ChatWidgetButtonPage() {
  const [size, ref] = useSize<HTMLButtonElement>();
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const { width, height } = size;

  useEffect(() => {
    window.parent.postMessage({
      message: 'zupport.chat.button.size',
      width,
      height,
    }, '*');
  }, [width, height]);

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data) {
        if (event.data.message === 'zupport.chat.init:success') {
          const {
            conversationParticipantMetadata,
          } = event.data;

          setUnreadMessagesCount(conversationParticipantMetadata.unreadMessagesCount);
        } else if (event.data.message === 'zupport.chat.set.unread:messages') {
          setUnreadMessagesCount(event.data.count);
        }
      }
    });

    window.parent.postMessage({ message: 'zupport.chat.widget.button.ready' }, '*');
  }, []);

  return (
    <div className={styles.container}>
      <button
        type="button"
        ref={ref}
        onClick={() => {
          window.parent.postMessage({ message: 'zupport.chat.button.clicked' }, '*');
        }}
        className={styles.launcherButton}
      >
        <img
          src={asset('/static/img/zupport-blue-logo.png')}
          alt="Zupport Logo"
          className={styles.launcherIcon}
        />
      </button>
      {
        unreadMessagesCount > 0 && (
          <div className={styles.launcherButtonUnreadMessagesBadge}>
            {unreadMessagesCount}
          </div>
        )
      }
      {/*
      @ts-ignore */}
      <style jsx>
        {`
          body {
            background-color: transparent;
            overflow: hidden;
            width: ${width ? `${width}px` : 'auto'};
            height: ${height ? `${height}px` : 'auto'};
          }
      `}
      </style>
    </div>
  );
}
