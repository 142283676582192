import { Outlet, useMatch } from 'react-router-dom';
import {
  FormatPainterOutlined,
  GlobalOutlined, SettingOutlined,
} from '@ant-design/icons';
import AppSubPageLayout from '../../layout/components/AppSubPageLayout';
import useQuery from '../../common/hooks/useQuery';
import api from '../../api';
import useActiveWorkspaceId from '../../workspace/hooks/useActiveWorkspaceId';
import { HelpCentersStore } from '../state';
import BrowserIcon from '../../common/components/Icons/BrowserIcon';
import BookOpenTextIcon from '../../common/components/Icons/BookOpenTextIcon';

export default function HelpCenterPagesWrapper() {
  const match = useMatch('/:workspaceSlug/help-centers/:helpCenterSlug/:pageKey?/?*');
  const activePageKey = match?.params.pageKey || 'general';
  const helpCenterSlug = match?.params.helpCenterSlug;
  const workspaceId = useActiveWorkspaceId();

  useQuery(async () => {
    if (helpCenterSlug && workspaceId) {
      HelpCentersStore.update((s) => ({
        ...s,
        loading: true,
      }));
      const result = await api.helpCenters.list({
        workspaceId,
        slug: helpCenterSlug,
      });

      HelpCentersStore.update((s) => ({
        ...s,
        activeHelpCenter: result[0] || undefined,
        loading: false,
      }));
    }
  });

  return (
    <AppSubPageLayout
      pages={[
        {
          key: 'general',
          title: 'General',
          path: '',
          icon: <SettingOutlined />,
        },
        {
          key: 'domains',
          title: 'Domains',
          path: 'domains',
          icon: <BrowserIcon width="18px" height="18px" />,
        },
        {
          key: 'styling',
          title: 'Styling',
          path: 'styling',
          icon: <FormatPainterOutlined />,
        },
        {
          key: 'languages',
          title: 'Languages',
          path: 'languages',
          icon: <GlobalOutlined />,
        },
        {
          key: 'articles',
          title: 'Articles',
          path: 'articles',
          icon: <BookOpenTextIcon />,
        },
      ]}
      activePageKey={activePageKey}
      content={<Outlet />}
    />
  );
}
