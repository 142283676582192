import { Button, Input, message as m } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import {
  BaseSyntheticEvent, HTMLProps,
  useCallback,
  useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import Markdown from 'react-markdown';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import websiteUserApi from '../../../website-user-api';
import {
  Conversation, ConversationMessageActionType, ConversationParticipant,
  WebsiteUser,
} from '../../../api';
import {
  addConversationMessage,
  ChatWidgetConversationStore,
  ChatWidgetStore,
  defaultConversationState,
  markConversationAsRead,
} from '../../state';
import cn from '../../../common/utils/cn';
import UserAvatar from '../../../common/components/UserAvatar';
import throttle from '../../../common/utils/throttle';

interface ChatWidgetConversationProps {
  conversation?: Conversation;
  participant?: ConversationParticipant;
  user?: WebsiteUser;
  onEmailChange?: (email: string) => Promise<void>;
  widgetHeight?: number;
}

const LIMIT = 20;

interface ChatWidgetConversationMessageProps {
  message: string;
  isOwnMessage?: boolean;
  avatar?: string;
  isDifferentParticipant?: boolean;
  showParticipantInfo?: boolean;
}

const EMAIL_INPUT_COMPONENT_HEIGHT = 70;

function LinkRenderer(props: HTMLProps<HTMLAnchorElement>) {
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return (<a target="_blank" rel="noreferrer" {...props} />);
}

function ChatWidgetConversationMessage(props: ChatWidgetConversationMessageProps) {
  const {
    message,
    isOwnMessage,
    avatar,
    isDifferentParticipant,
    showParticipantInfo,
  } = props;

  return (
    <div>
      <div
        className={cn(
          'chat-widget-conversation-message-bubble-container',
          isOwnMessage && 'chat-widget-conversation-message-bubble-container-own',
          isDifferentParticipant && 'chat-widget-conversation-message-bubble-container-divergent-participant',
        )}
      >
        <div className="chat-widget-conversation-message-avatar-container">
          {
            showParticipantInfo && (
              <UserAvatar avatar={avatar} size={30} className="chat-widget-conversation-message-avatar" />
            )
          }
        </div>
        <div className="chat-widget-conversation-message-bubble">
          <div
            className="chat-widget-conversation-message-bubble-message"
          >
            <Markdown components={{ a: LinkRenderer }}>{message}</Markdown>
          </div>
        </div>
      </div>
    </div>
  );
}

export function getDisplayTime(date: Date) {
  const isToday = new Date().toDateString() === date.toDateString();

  if (isToday) {
    return date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  const isDifferentYear = new Date().getFullYear() !== date.getFullYear();

  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: isDifferentYear ? 'numeric' : undefined,
  });
}

export default function ChatWidgetConversation(props: ChatWidgetConversationProps) {
  const {
    conversation,
    participant,
    user,
    widgetHeight = 400,
    onEmailChange,
  } = props;
  const [message, setMessage] = useState('');
  const [confirmingEmail, setConfirmingEmail] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const blockAutoScrollRef = useRef(false);
  const websiteUid = ChatWidgetStore.useState((s) => s.websiteUid);

  const state = ChatWidgetConversationStore.useState(
    (s) => (
      conversation
        ? (s[conversation._id] || defaultConversationState)
        : defaultConversationState
    ),
    [conversation?._id],
  );

  useEffect(() => {
    if (websiteUid && conversation?._id) {
      websiteUserApi.markConversationAsRead(websiteUid, conversation._id).then(() => {
        // Ignore
      }).catch(() => {
        // Ignore
      });
      markConversationAsRead(conversation._id);
    }
  }, [websiteUid, conversation?._id]);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }
    }, 50);
  }, [Boolean(chatContainerRef.current)]);

  const { messages, hasMore, loading } = state;

  const onScroll = useCallback(throttle((e: BaseSyntheticEvent<HTMLDivElement>) => {
    if (!loading && conversation && hasMore) {
      const distanceToTop = e.target.scrollTop;

      if (distanceToTop <= 500) {
        // Load more
        const [last] = messages;
        websiteUserApi.listConversationMessages(conversation._id, {
          $trail: last ? last._id : undefined,
        }).then((response) => {
          blockAutoScrollRef.current = true;
          ChatWidgetConversationStore.update((s) => ({
            ...s,
            [conversation._id]: {
              ...state,
              messages: [...response.items.reverse(), ...messages],
              hasMore: response.items.length >= LIMIT,
              loading: false,
            },
          }));
          setTimeout(() => {
            blockAutoScrollRef.current = false;
          }, 100);
        });
      }
    }
  }, 500), [loading, messages, conversation?._id, hasMore]);

  useEffect(() => {
    if (messages.length < LIMIT && conversation && hasMore) {
      const $trail = messages[0]?._id;
      websiteUserApi.listConversationMessages(conversation._id, {
        $limit: LIMIT,
        $trail,
      }).then((response) => {
        ChatWidgetConversationStore.update((s) => ({
          ...s,
          [conversation._id]: {
            ...state,
            messages: [...response.items.reverse(), ...messages],
            hasMore: response.items.length >= LIMIT,
            loading: false,
          },
        }));
      });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (chatContainerRef.current) {
        const el = chatContainerRef.current;
        const bottomDistance = el.scrollHeight - el.scrollTop - el.clientHeight;
        const shouldScroll = bottomDistance <= (el.clientHeight * 2);

        if (shouldScroll && !blockAutoScrollRef.current) {
          el.scrollTo({
            top: el.scrollHeight,
            behavior: 'smooth',
          });
        }
      }
    }, 50);
  }, [messages.length]);

  const valid = message.trim().length > 0;

  const onSendMessage = () => {
    if (valid) {
      if (!conversation) {
        websiteUserApi.startConversation(message).then(() => {
          setMessage('');
        });
      } else {
        const clientId = Date.now().toString();

        if (participant) {
          // Send the message right away (optimistic rendering)
          setMessage('');
          addConversationMessage(
            conversation._id,
            {
              _id: Date.now().toString(),
              message,
              participant,
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
              conversationId: conversation._id,
              clientId,
            },
          );
          const notificationSound = new Audio('/pop.mp3');
          notificationSound.play().then(() => {
            // Ignore
          }).catch(() => {
            // Ignore
          });

          websiteUserApi.sendMessage(conversation._id, message, clientId).then((result) => {
            // Update the message with the server response
            addConversationMessage(
              conversation._id,
              result,
            );
          });
        }
      }
    }
  };

  const onConfirmEmail = async () => {
    if (onEmailChange) {
      let emailValid = true;

      if (!newEmail) {
        emailValid = false;
      } else {
        emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail);
      }

      if (emailValid) {
        setConfirmingEmail(true);
        onEmailChange(newEmail).then(() => {
          setEmailConfirmed(true);
        }).catch(() => {}).finally(() => {
          setConfirmingEmail(false);
        });
      } else {
        m.error('Invalid email address');
      }
    }
  };

  let lastShownTimestamp: Date | null;
  let lastParticipantMessage: ConversationParticipant['_id'] | null;

  const showEmailInput = user && (!user.email || emailConfirmed);
  const emailInputHeight = showEmailInput ? EMAIL_INPUT_COMPONENT_HEIGHT : 0;
  const height = `calc(${widgetHeight - emailInputHeight}px - var(--chat-widget-nav-height) - var(--powered-by-height) - var(--chat-widget-header-small-height))`;

  return (
    <div className="chat-widget-conversation-container">
      <SimpleBar
        className="chat-widget-conversation-messages-container"
        style={{
          height,
          maxHeight: height,
        }}
        scrollableNodeProps={{
          ref: chatContainerRef,
          onScroll,
        }}
      >
        <div className="chat-widget-conversation-message-container-inner">
          {
            messages.map((item, index) => {
              const isOwnMessage = item.participant._id === participant?._id;

              const { createdAt } = item;

              let showTimestamp = !lastShownTimestamp;

              if (lastShownTimestamp) {
                const differenceInMinutes = Math.abs(
                  new Date(createdAt).getTime()
                  - lastShownTimestamp.getTime(),
                ) / 1000 / 60;

                showTimestamp = differenceInMinutes > 5;
              }

              if (showTimestamp) {
                lastShownTimestamp = new Date(createdAt);
              }

              const nextMessage = messages[index + 1];
              const nextMessageIsOwn = nextMessage?.participant._id === participant?._id;

              let showTimestampNext: boolean = false;

              const next = messages[index + 1];

              if (next) {
                const differenceInMinutes = Math.abs(
                  new Date(next.createdAt).getTime()
                  - new Date(createdAt).getTime(),
                ) / 1000 / 60;

                showTimestampNext = differenceInMinutes > 5;
              }

              const isDifferentParticipant = lastParticipantMessage !== item.participant._id;
              const isDifferentParticipantNext = next
                && next.participant._id !== item.participant._id;

              const showParticipantInfo = !isOwnMessage && (
                !nextMessage || nextMessageIsOwn || showTimestampNext || isDifferentParticipantNext
              );

              lastParticipantMessage = item.participant._id;

              return (
                <>
                  {
                    showTimestamp && (
                      <div className="chat-widget-conversation-message-timestamp">
                        {getDisplayTime(new Date(createdAt))}
                      </div>
                    )
                  }
                  {
                    item.actions && item.actions.length > 0 && (
                      <>
                        {
                          item.actions.map((action) => {
                            if (action.type === ConversationMessageActionType.CONVERSATION_JOINED) {
                              return (
                                <div className="chat-widget-conversation-message-joined-action-container">
                                  <UserAvatar
                                    avatar={item.participant.avatar}
                                    size={40}
                                    className="chat-widget-conversation-message-joined-action-avatar"
                                  >
                                    {item.participant.name[0]}
                                  </UserAvatar>
                                  <span>
                                    {item.participant.name}
                                    {' '}
                                    joined the conversation.
                                  </span>
                                  <span className="chat-widget-conversation-message-joined-action-timestamp">
                                    {getDisplayTime(new Date(createdAt))}
                                  </span>
                                </div>
                              );
                            }

                            return null;
                          })
                        }
                      </>
                    )
                  }
                  <ChatWidgetConversationMessage
                    key={item._id}
                    message={item.message}
                    isOwnMessage={isOwnMessage}
                    avatar={showParticipantInfo ? item.participant.avatar : undefined}
                    showParticipantInfo={showParticipantInfo}
                    isDifferentParticipant={isDifferentParticipant}
                  />
                  {
                    showParticipantInfo && (
                      <div className="chat-widget-conversation-message-participant-info">
                        <div className="chat-widget-conversation-message-participant-info-name">
                          {item.participant.name}
                        </div>
                      </div>
                    )
                  }
                  {
                    item.actions && (
                      <>
                        {
                          item.actions.map((action) => {
                            if (action.type === ConversationMessageActionType.TRANSFER_TO_HUMAN) {
                              return (
                                <p className="chat-widget-conversation-message-action-text">
                                  {item.participant.name}
                                  {' '}
                                  transferred this conversation to a human agent,
                                  please wait for a response.
                                </p>
                              );
                            }

                            return null;
                          })
                        }
                      </>
                    )
                  }
                </>
              );
            })
          }
        </div>
      </SimpleBar>
      {
        showEmailInput && (
          <div
            className="chat-widget-email-input-container"
            style={{
              height: EMAIL_INPUT_COMPONENT_HEIGHT,
            }}
          >
            {
              !emailConfirmed ? (
                <>
                  <p className="chat-widget-email-input-title">
                    Enter your email address to receive responses
                  </p>
                  <Input
                    placeholder="Email address"
                    className="chat-widget-email-input"
                    size="small"
                    value={newEmail}
                    onChange={(e) => {
                      setNewEmail(e.target.value);
                    }}
                    suffix={(
                      <Button
                        size="small"
                        type="link"
                        className="chat-widget-email-input-button"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onConfirmEmail();
                        }}
                        loading={confirmingEmail}
                      >
                        Confirm
                      </Button>
                    )}
                  />
                </>
              ) : (
                <>
                  <p className="chat-widget-email-input-title">
                    Email address confirmed
                  </p>
                  <span className="chat-widget-email-input-confirm-message">
                    You will be notified at
                    {' '}
                    <a href={`mailto:${newEmail}`}>{newEmail}</a>
                  </span>
                </>
              )
            }
          </div>
        )
      }
      <div className="chat-widget-conversation-input-container">
        <div className="chat-widget-conversation-input-background" />
        <Input
          placeholder="Type a question or a message ..."
          size="small"
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          suffix={(
            <Button
              type="link"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onSendMessage();
              }}
              icon={(
                <SendOutlined />
              )}
              disabled={!valid}
              className="p-0 m-0"
              style={{
                height: 25,
                width: 25,
              }}
            />
          )}
          onPressEnter={onSendMessage}
          className="chat-widget-conversation-input-input"
        />
      </div>
    </div>
  );
}
