import {
  MutableRefObject, useEffect, useRef, useState,
} from 'react';

export interface Size {
  width: number | undefined;
  height: number | undefined;
}

export default function useSize<T extends HTMLButtonElement | HTMLDivElement>(
  onResize?: (size: Size) => void,
): [
    Size, MutableRefObject<T>,
  ] {
  const ref = useRef<T>(null);
  const [size, setSize] = useState<Size>({ width: undefined, height: undefined });

  const refSet = Boolean(ref.current);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        const newSize = {
          width: ref.current.offsetWidth,
          height: ref.current.offsetHeight,
        };
        setSize(newSize);

        if (onResize) {
          onResize(newSize);
        }
      }
    };

    // Initial setting
    handleResize();

    setTimeout(handleResize, 1000);

    // Use MutationObserver to detect size change
    const observer = new ResizeObserver(handleResize);
    if (ref.current) {
      observer.observe(ref.current);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, [refSet, onResize]);

  return [size, ref as MutableRefObject<T>];
}
