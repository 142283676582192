import { SelectOption } from '../components/Select';

const languages: SelectOption[] = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Spanish',
    value: 'es',
  },
  {
    label: 'French',
    value: 'fr',
  },
  {
    label: 'German',
    value: 'de',
  },
  {
    label: 'Italian',
    value: 'it',
  },
  {
    label: 'Japanese',
    value: 'ja',
  },
  {
    label: 'Korean',
    value: 'ko',
  },
  {
    label: 'Portuguese',
    value: 'pt',
  },
  {
    label: 'Russian',
    value: 'ru',
  },
];

const languageObject: Record<string, string> = languages.reduce((acc, language) => {
  acc[String(language.value)] = language.label;
  return acc;
}, {} as Record<string, string>);

export function getLanguageName(language: string): string {
  return languageObject[language] || language;
}

export default languages;
