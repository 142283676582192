import { Avatar, AvatarProps } from 'antd';
import { getUserAvatar } from '../../utils/chat';

interface UserAvatarProps extends Omit<AvatarProps, 'src'> {
  avatar?: string;
  fallback?: boolean;
}

export default function UserAvatar(props: UserAvatarProps) {
  const { avatar, fallback, ...rest } = props;

  return (
    <Avatar
      src={getUserAvatar(avatar, fallback)}
      {...rest}
    />
  );
}
