import BaseService from './BaseService';
import Paginated from '../types/Paginated';
import Agent from '../models/Agent';
import Query from '../types/Query';

export default class Agents extends BaseService {
  async list(params: Query<{ websiteId: string; }>): Promise<Paginated<Agent>> {
    return this._get('/agents', { params });
  }
}
