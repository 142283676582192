import {
  Card,
  Form, Input, notification,
} from 'antd';
import { DeleteOutlined, DragOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { useRef } from 'react';
import useActiveWebsite from '../hooks/useActiveWebsite';
import useRequest from '../../common/hooks/useRequest';
import api, { FAQ, Website } from '../../api';
import { setWebsite } from '../state';
import { AppSubPageLayoutContent } from '../../layout/components/AppSubPageLayout';
import Button from '../../common/components/Button';

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: Partial<HTMLDivElement['style']>) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'var(--color-gray-2)' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const reorder = (list: FAQ[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function WebsiteFAQSettingsPage() {
  const website = useActiveWebsite();
  const [form] = Form.useForm();

  const contentContainerRef = useRef<HTMLDivElement>(null);

  const request = useRequest<Website>(async (values: Pick<Website, 'faqs'>) => {
    if (!website) {
      throw new Error('Website not found');
    }

    // It is not defined if there are no faqs
    return api.websites.patch(website._id, {
      faqs: values?.faqs || [],
    });
  }, {
    onSuccess: (response) => {
      setWebsite(response);
      notification.success({
        message: 'Website updated',
      });
    },
  });

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const faqs = form.getFieldValue('faqs');

    const items = reorder(
      faqs,
      result.source.index,
      result.destination.index,
    );

    form.setFieldsValue({
      faqs: items,
    });
  };

  return (
    <Form
      className="flex-1"
      layout="vertical"
      colon={false}
      requiredMark={false}
      initialValues={{
        faqs: website?.faqs || '',
      }}
      form={form}
      onFinish={request.submit}
    >
      <AppSubPageLayoutContent
        title="FAQs"
        contentContainerRef={contentContainerRef}
        content={(
          <>
            <div>
              Build a list of faqs that you want to share with your customers.
              They will be used by the AI agents for proactive support or
              when customers ask for more information about your services.
            </div>
            <div>
              <Form.Item shouldUpdate>
                {({ getFieldValue }) => {
                  const faqs = getFieldValue('faqs') || [];

                  return (
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided: any) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            <div className="mt-4">
                              {
                                faqs.map((faq: any, index: number) => (
                                  <Draggable key={faq.id} draggableId={faq.id} index={index}>
                                    {(_provided: any, _snapshot: any) => (
                                      <Card
                                        size="small"
                                        title={(
                                          <DragOutlined />
                                        )}
                                        ref={_provided.innerRef}
                                        {..._provided.draggableProps}
                                        {..._provided.dragHandleProps}
                                        style={getItemStyle(
                                          _snapshot.isDragging,
                                          _provided.draggableProps.style,
                                        )}
                                        extra={[(
                                          <Button
                                            type="link"
                                            size="small"
                                            danger
                                            onClick={() => {
                                              form.setFieldsValue({
                                                faqs: faqs.filter(
                                                  (_: any, i: number) => i !== index,
                                                ),
                                              });
                                            }}
                                            icon={(
                                              <DeleteOutlined />
                                            )}
                                          />
                                        )]}
                                      >
                                        <Form.Item
                                          name={['faqs', index, 'id']}
                                          hidden
                                        >
                                          <Input />
                                        </Form.Item>
                                        <Form.Item
                                          name={['faqs', index, 'question']}
                                          label="Question"
                                          rules={[{ required: true, message: 'Question is required' }]}
                                        >
                                          <Input size="small" />
                                        </Form.Item>
                                        <Form.Item
                                          name={['faqs', index, 'answer']}
                                          label="Answer"
                                          className="m-0"
                                        >
                                          <Input size="small" />
                                        </Form.Item>
                                      </Card>
                                    )}
                                  </Draggable>
                                ))
                              }
                            </div>
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  );
                }}
              </Form.Item>
            </div>
          </>
        )}
        footer={(
          <>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const faqs = getFieldValue('faqs') || [];

                return (
                  <Button
                    type="default"
                    onClick={() => {
                      form.setFieldsValue({
                        faqs: [...faqs, { id: Date.now().toString(), question: '', answer: '' }],
                      });

                      setTimeout(() => {
                        contentContainerRef.current?.scrollTo({
                          top: contentContainerRef.current?.scrollHeight,
                          behavior: 'smooth',
                        });
                      });
                    }}
                  >
                    Add FAQ
                  </Button>
                );
              }}
            </Form.Item>
            <Form.Item noStyle>
              <Button
                type="primary"
                htmlType="submit"
                loading={request.loading}
              >
                Save
              </Button>
            </Form.Item>
          </>
        )}
      />
    </Form>
  );
}
