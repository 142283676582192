import {
  Col, Collapse, Form, Radio, Row, Upload,
} from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { Color } from 'antd/es/color-picker';
import ImgCrop from 'antd-img-crop';
import { AppSubPageLayoutContent } from '../../layout/components/AppSubPageLayout';
import { asset, FALLBACK_IMAGE_URL } from '../../common/utils/assets';
import useActiveHelpCenter from '../hooks/useActiveHelpCenter';
import useRequest from '../../common/hooks/useRequest';
import api, { HelpCenter, HelpCenterPatchData } from '../../api';
import Button from '../../common/components/Button';
import LightModeIcon from '../../common/components/Icons/LightIcon';
import DarkModeIcon from '../../common/components/Icons/DarkModeIcon';
import theme from '../../common/config/theme';
import FormColorPicker from '../../common/components/FormColorPicker';
import Divider from '../../common/components/Divider';

interface ColorValueSplitProps {
  light: ReactNode;
  dark: ReactNode;
  full?: boolean;
}

interface ColorValueContainerProps {
  name: string;
  icon: ReactNode;
  children: ReactNode;
}

function ColorValueContainer(props: ColorValueContainerProps) {
  const { name, icon, children } = props;

  return (
    <div>
      <div className="d-flex align-items-center gap-2">
        {icon}
        <span>{name}</span>
      </div>
      <div className="mt-2">
        {children}
      </div>
    </div>
  );
}

function ColorValueSplit(props: ColorValueSplitProps) {
  const { light, dark, full } = props;

  return (
    <div className="d-flex gap-2">
      <div
        style={{
          maxWidth: '50%',
          flex: full ? 1 : 'none',
        }}
      >
        <ColorValueContainer
          name="Light"
          icon={<LightModeIcon />}
        >
          {light}
        </ColorValueContainer>
      </div>
      <div
        style={{
          borderRight: '1px solid #f0f0f0',
        }}
      />
      <div
        style={{
          flex: full ? 1 : 'none',
        }}
      >
        <ColorValueContainer
          name="Dark"
          icon={<DarkModeIcon />}
        >
          {dark}
        </ColorValueContainer>
      </div>
    </div>
  );
}

type DeepPartial<T> = T extends object ? {
  [P in keyof T]?: DeepPartial<T[P]>;
} : T;

const defaultConfig: DeepPartial<HelpCenter> = {
  config: {
    hero: {
      background: {
        gradient: {
          light: [theme.colors.primary, '#FFF'],
          dark: [theme.colors.primary, '#000'],
        },
      },
    },
  },
};

function deepMerge<T>(target: T, source: T): T {
  const output = { ...target };

  if (!target) {
    return source;
  }

  for (const key in source) {
    if (source[key] instanceof Object) {
      // @ts-ignore
      Object.assign(output, { [key]: deepMerge(target[key], source[key]) });
    } else {
      // @ts-ignore
      Object.assign(output, { [key]: source[key] });
    }
  }

  return output;
}

export default function HelpCenterConfigPage() {
  const helpCenter = useActiveHelpCenter();
  const [favicon, setFavicon] = useState<File>();
  const [faviconUrl, setFaviconUrl] = useState<string>(
    helpCenter?.favicon ? asset(helpCenter.favicon) : FALLBACK_IMAGE_URL,
  );
  const [logoURLLight, setLogoURLLight] = useState<string>(
    asset(helpCenter?.config.brand?.logo?.light),
  );
  const [logoLight, setLogoLight] = useState<File>();
  const [logoURLDark, setLogoURLDark] = useState<string>(
    asset(helpCenter?.config.brand?.logo?.dark),
  );
  const [logoDark, setLogoDark] = useState<File>();
  const [headerImageLight, setHeaderImageLight] = useState<File>();
  const [headerImageLightURL, setHeaderImageLightURL] = useState<string>();
  const [headerImageDark, setHeaderImageDark] = useState<File>();
  const [headerImageDarkURL, setHeaderImageDarkURL] = useState<string>();
  const [form] = Form.useForm();

  useEffect(() => {
    if (helpCenter) {
      form.setFieldsValue(deepMerge(defaultConfig, helpCenter));

      if (helpCenter.config.hero.background.image?.light) {
        setHeaderImageLightURL(asset(helpCenter.config.hero.background.image.light));
      }

      if (helpCenter.config.hero.background.image?.dark) {
        setHeaderImageDarkURL(asset(helpCenter.config.hero.background.image.dark));
      }
    }
  }, [helpCenter]);

  useEffect(() => {
    if (helpCenter) {
      setFaviconUrl(asset(helpCenter.favicon));
      setLogoURLLight(asset(helpCenter.config.brand?.logo?.light));
      setLogoURLDark(asset(helpCenter.config.brand?.logo?.dark));
    }
  }, [helpCenter]);

  const request = useRequest(
    async (values: HelpCenterPatchData) => {
      if (!helpCenter) {
        throw new Error('Help center not found');
      }

      if (
        favicon
        || headerImageLight
        || headerImageDark
        || logoLight
        || logoDark
      ) {
        await api.helpCenters.patchMultimedia(helpCenter._id, {
          favicon,
          headerImageLight,
          headerImageDark,
          logoLight,
          logoDark,
        });
      }

      return api.helpCenters.patch(helpCenter._id, values);
    },
  );

  return (
    <Form
      className="flex-1"
      initialValues={deepMerge(defaultConfig, helpCenter || {})}
      onFinish={request.submit}
      form={form}
    >
      <AppSubPageLayoutContent
        title="Layout"
        titleAction={(
          <Button
            type="primary"
            htmlType="submit"
            loading={request.loading}
            className="mt-3"
          >
            Save
          </Button>
        )}
        content={(
          <div className="pb-4">
            <Row>
              <Col span={24} md={20}>
                <div>
                  <div className="d-flex flex-column gap-3">
                    <Collapse
                      defaultActiveKey={['branding']}
                      items={[
                        {
                          key: 'branding',
                          label: 'Branding',
                          children: (
                            <>
                              <Divider orientation="left">
                                Logo
                              </Divider>
                              <div>
                                <ColorValueSplit
                                  light={(
                                    <Upload.Dragger
                                      showUploadList={false}
                                      beforeUpload={(file) => {
                                        const reader = new FileReader();
                                        reader.onload = (e) => {
                                          setLogoURLLight(e.target?.result as string);
                                          setLogoLight(file);
                                        };
                                        reader.readAsDataURL(file);
                                        return false;
                                      }}
                                    >
                                      <img
                                        src={logoURLLight}
                                        width={64}
                                        alt="Light Logo"
                                      />
                                    </Upload.Dragger>
                                  )}
                                  dark={(
                                    <Upload.Dragger
                                      showUploadList={false}
                                      beforeUpload={(file) => {
                                        const reader = new FileReader();
                                        reader.onload = (e) => {
                                          setLogoURLDark(e.target?.result as string);
                                          setLogoDark(file);
                                        };
                                        reader.readAsDataURL(file);
                                        return false;
                                      }}
                                    >
                                      <img
                                        src={logoURLDark}
                                        width={64}
                                        alt="Dark Logo"
                                      />
                                    </Upload.Dragger>
                                  )}
                                />
                              </div>
                              <Divider orientation="left">
                                Favicon
                              </Divider>
                              <div className="d-flex justify-content-start">
                                <Upload.Dragger
                                  showUploadList={false}
                                  beforeUpload={(file) => {
                                    const reader = new FileReader();
                                    reader.onload = (e) => {
                                      setFaviconUrl(e.target?.result as string);
                                      setFavicon(file);
                                    };
                                    reader.readAsDataURL(file);
                                    return false;
                                  }}
                                >
                                  <img
                                    src={faviconUrl}
                                    width={64}
                                    alt="Favicon"
                                  />
                                </Upload.Dragger>
                              </div>
                              <p className="m-0 mt-2 text-secondary">
                                Replace the default favicon with your own.
                                The recommended size is 32x32 pixels.
                              </p>
                            </>
                          ),
                        },
                      ]}
                      expandIconPosition="right"
                    />
                    <Collapse
                      items={[
                        {
                          label: 'Hero',
                          children: (
                            <div>
                              <p className="m-0 text-secondary">
                                Customize the header of your help center.
                                The header will be displayed on every page of your help center.
                              </p>
                              <Divider orientation="left">
                                Background
                              </Divider>
                              <Form.Item
                                name={['config', 'hero', 'background', 'type']}
                                className="pt-0"
                              >
                                <Radio.Group
                                  options={[
                                    {
                                      label: 'Color',
                                      value: 'color',
                                    },
                                    {
                                      label: 'Gradient',
                                      value: 'gradient',
                                    },
                                    {
                                      label: 'Image',
                                      value: 'image',
                                    },
                                  ]}
                                  optionType="button"
                                  buttonStyle="solid"
                                />
                              </Form.Item>
                              <Form.Item shouldUpdate>
                                {({ getFieldValue }) => {
                                  const type = getFieldValue(['config', 'hero', 'background', 'type']);

                                  if (type === 'color') {
                                    return (
                                      <ColorValueSplit
                                        light={(
                                          <Form.Item name={['config', 'hero', 'background', 'color', 'light']}>
                                            <FormColorPicker
                                              defaultValue={theme.colors.primary}
                                              showText
                                            />
                                          </Form.Item>
                                       )}
                                        dark={(
                                          <Form.Item name={['config', 'hero', 'background', 'color', 'dark']}>
                                            <FormColorPicker
                                              defaultValue={theme.colors.primary}
                                              showText
                                            />
                                          </Form.Item>
                                       )}
                                      />
                                    );
                                  }

                                  if (type === 'gradient') {
                                    const gradient = getFieldValue(['config', 'hero', 'background', 'gradient']);
                                    const light = ((gradient?.light || []) as (string | Color)[])
                                      .map((item) => (typeof item === 'string' ? item : item.toCssString()));
                                    const dark = ((gradient?.dark || []) as (string | Color)[])
                                      .map((item) => (typeof item === 'string' ? item : item.toCssString()));

                                    return (
                                      <ColorValueSplit
                                        light={(
                                          <div>
                                            <div className="d-flex gap-2">
                                              <Form.Item
                                                name={['config', 'hero', 'background', 'gradient', 'light', 0]}
                                              >
                                                <FormColorPicker
                                                  defaultValue={theme.colors.primary}
                                                  showText
                                                />
                                              </Form.Item>
                                              <Form.Item
                                                name={['config', 'hero', 'background', 'gradient', 'light', 1]}
                                              >
                                                <FormColorPicker
                                                  defaultValue="#FFF"
                                                  showText
                                                  onChange={(newValue) => {
                                                    // This is a workaround to update the form value
                                                    // because when selecting the second color,
                                                    // the first color is not updated and it stays
                                                    // undefined, which is causing the app to crash
                                                    const firstColor = getFieldValue(['config', 'hero', 'background', 'gradient', 'light', 0]) || theme.colors.primary;
                                                    const config = form.getFieldValue('config');
                                                    form.setFieldsValue(deepMerge(config, {
                                                      config: {
                                                        hero: {
                                                          background: {
                                                            gradient: {
                                                              light: [firstColor, newValue],
                                                            },
                                                          },
                                                        },
                                                      },
                                                    }));
                                                  }}
                                                />
                                              </Form.Item>
                                            </div>
                                            <div
                                              className="rounded-3"
                                              style={{
                                                background: `linear-gradient(${light[0] || theme.colors.primary}, ${light[1] || 'white'})`,
                                                height: 100,
                                              }}
                                            />
                                          </div>
                                        )}
                                        dark={(
                                          <div>
                                            <div className="d-flex gap-2">
                                              <Form.Item
                                                name={['config', 'hero', 'background', 'gradient', 'dark', 0]}
                                              >
                                                <FormColorPicker
                                                  defaultValue={theme.colors.primary}
                                                  showText
                                                />
                                              </Form.Item>
                                              <Form.Item
                                                name={['config', 'hero', 'background', 'gradient', 'dark', 1]}
                                              >
                                                <FormColorPicker
                                                  defaultValue="#000"
                                                  showText
                                                  onChange={(newValue) => {
                                                    // This is a workaround to update the form value
                                                    // because when selecting the second color,
                                                    // the first color is not updated and it stays
                                                    // undefined, which is causing the app to crash
                                                    const firstColor = getFieldValue(['config', 'hero', 'background', 'gradient', 'dark', 0]) || theme.colors.primary;
                                                    const config = form.getFieldValue('config');
                                                    form.setFieldsValue(deepMerge(config, {
                                                      config: {
                                                        hero: {
                                                          background: {
                                                            gradient: {
                                                              dark: [firstColor, newValue],
                                                            },
                                                          },
                                                        },
                                                      },
                                                    }));
                                                  }}
                                                />
                                              </Form.Item>
                                            </div>
                                            <div
                                              className="rounded-3"
                                              style={{
                                                background: `linear-gradient(${dark[0] || theme.colors.primary}, ${dark[1] || 'black'})`,
                                                height: 100,
                                              }}
                                            />
                                          </div>
                                        )}
                                      />
                                    );
                                  }

                                  if (type === 'image') {
                                    return (
                                      <ColorValueSplit
                                        light={(
                                          <ImgCrop aspect={16 / 5}>
                                            <Upload.Dragger
                                              showUploadList={false}
                                              beforeUpload={(file) => {
                                                const reader = new FileReader();
                                                reader.onload = (e) => {
                                                  setHeaderImageLight(file);
                                                  setHeaderImageLightURL(
                                                    e.target?.result as string,
                                                  );
                                                };
                                                reader.readAsDataURL(file);
                                                return false;
                                              }}
                                            >
                                              <img
                                                src={headerImageLight
                                                  ? URL.createObjectURL(headerImageLight)
                                                  : (headerImageLightURL || FALLBACK_IMAGE_URL)}
                                                width="100%"
                                                style={{
                                                  aspectRatio: '16/4',
                                                  objectFit: 'cover',
                                                }}
                                                alt="Header Light"
                                              />
                                            </Upload.Dragger>
                                          </ImgCrop>
                                        )}
                                        dark={(
                                          <ImgCrop aspect={16 / 5}>
                                            <Upload.Dragger
                                              showUploadList={false}
                                              beforeUpload={(file) => {
                                                const reader = new FileReader();
                                                reader.onload = (e) => {
                                                  setHeaderImageDark(file);
                                                  setHeaderImageDarkURL(e.target?.result as string);
                                                };
                                                reader.readAsDataURL(file);
                                                return false;
                                              }}
                                            >
                                              <img
                                                src={headerImageDark
                                                  ? URL.createObjectURL(headerImageDark)
                                                  : (headerImageDarkURL || FALLBACK_IMAGE_URL)}
                                                width="100%"
                                                style={{
                                                  aspectRatio: '16/4',
                                                  objectFit: 'cover',
                                                }}
                                                alt="Header Dark"
                                              />
                                            </Upload.Dragger>
                                          </ImgCrop>
                                        )}
                                        full
                                      />
                                    );
                                  }

                                  return null;
                                }}
                              </Form.Item>
                              <Divider orientation="left">
                                Text
                              </Divider>
                              <ColorValueSplit
                                light={(
                                  <Form.Item name={['config', 'hero', 'text', 'color', 'light']}>
                                    <FormColorPicker
                                      defaultValue="#000"
                                      showText
                                    />
                                  </Form.Item>
                                )}
                                dark={(
                                  <Form.Item name={['config', 'hero', 'text', 'color', 'dark']}>
                                    <FormColorPicker
                                      defaultValue="#FFF"
                                      showText
                                    />
                                  </Form.Item>
                                )}
                              />
                            </div>
                          ),
                        },
                      ]}
                      expandIconPosition="right"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
      />
    </Form>
  );
}
