import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ConfigProvider } from 'antd';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './modules/common/config/theme';
import MarketingParamsTracker from './modules/common/components/MarketingParamsTracker';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const Input = {
  controlHeight: 40,
  controlHeightLG: 46,
  controlHeightSM: 30,
};

root.render(
  <ConfigProvider
    theme={{
      cssVar: true,
      hashed: false,
      token: {
        // Light Blue theme
        // colorPrimary: '#2D55FB',

        // Light Dark theme
        colorPrimary: '#050507',
        colorPrimaryHover: '#27272a',
        colorBgContainer: theme.colors.gray.secondary,
        lineWidthFocus: 2,
        controlOutlineWidth: 1,
      },
      components: {
        Input: {
          ...Input,
          paddingInline: 24,
          paddingInlineSM: 16,
          paddingInlineLG: 32,
          colorBorder: '#E8ECEF',
          // activeShadow: '0 0 0 1px rgba(0, 1, 4, 0.73)',
          activeShadow: 'none',
          activeBg: '#FFF',
          colorBgContainer: '#FFF',
        },
        InputNumber: {
          ...Input,
          paddingInline: 24,
          paddingInlineSM: 16,
          paddingInlineLG: 32,
          colorBorder: '#E8ECEF',
          // activeShadow: '0 0 0 1px rgba(0, 1, 4, 0.73)',
          activeShadow: 'none',
          activeBg: '#FFF',
          colorBgContainer: '#FFF',
        },
        Button: {
          controlHeight: Input.controlHeight,
          controlHeightLG: Input.controlHeightLG,
          controlHeightSM: Input.controlHeightSM,
          paddingInline: 24,
          paddingInlineSM: 16,
          paddingInlineLG: 32,
          fontWeight: 600,
          defaultBg: '#FFF',
          defaultHoverBg: '#fcfcfc',
        },
        Menu: {
          itemSelectedBg: theme.colors.gray.secondary,
          itemActiveBg: theme.colors.gray.secondary,
          itemBg: '#FFF',
          itemMarginInline: 0,
          // itemMarginBlock: 0,
        },
        Layout: {
          siderBg: '#FFF',
        },
        Card: {
          colorBgContainer: '#FFF',
        },
        Select: {
          ...Input,
          colorBgContainer: '#FFF',
          controlOutlineWidth: 1,
          optionSelectedBg: theme.colors.gray.secondary,
          controlOutline: 'none',
        },
        Collapse: {
          contentBg: '#FFF',
        },
      },
    }}
  >
    <div className="app-container ant-theme-light-dark">
      <MarketingParamsTracker />
      <App />
    </div>
  </ConfigProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
