import {
  Avatar,
  Drawer,
  Form,
  Input, Select,
  Upload,
} from 'antd';
import { useEffect, useState } from 'react';
import ImgCrop from 'antd-img-crop';
import api, { Assistant, AssistantData, AssistantType } from '../../api';
import useActiveWebsite from '../../website/hooks/useActiveWebsite';
import Button from '../../common/components/Button';
import useIsMobile from '../../common/hooks/useIsMobile';
import useRequest from '../../common/hooks/useRequest';
import { asset } from '../../common/utils/assets';
import AssistantPreviewCard, { AssistantPreviewCardSkeleton } from '../components/AssistantPreviewCard';
import { getAssistantTypeLabel } from '../utils';
import useAssistants from '../hooks/useAssistants';
import AppPageContent from '../../layout/components/AppPageContent';
import usePermissions from '../../permissions/hooks/usePermissions';
import PermissionChecker from '../../common/components/PermissionChecker';

const DEFAULT_AVATAR = '/static/img/color-logo-square-original-small-tinified.jpg';

export default function AssistantsPage() {
  const mobile = useIsMobile();
  const website = useActiveWebsite();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [avatar, setAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>(asset(DEFAULT_AVATAR));
  const [editAssistant, setEditAssistant] = useState<Assistant | null>(null);
  const permissions = usePermissions();

  const {
    assistants, loading, addAssistant, updateAssistant,
  } = useAssistants();

  const request = useRequest(
    async (values: Omit<AssistantData, 'websiteId' | 'avatar'>) => {
      if (!website) {
        throw new Error('No active website found');
      }

      if (editAssistant) {
        return api.assistants.patch(editAssistant._id, {
          type: values.type,
          name: values.name,
          personality: values.personality,
          instructions: values.instructions,
          avatar,
        });
      }

      return api.assistants.create({
        ...values,
        websiteId: website._id,
        avatar,
      });
    },
    {
      onSuccess: (assistant) => {
        if (editAssistant) {
          updateAssistant(assistant);
        } else {
          addAssistant(assistant);
        }
        setOpen(false);
      },
    },
  );

  useEffect(() => {
    if (editAssistant) {
      setOpen(true);
    }
  }, [editAssistant]);

  useEffect(() => {
    if (!open) {
      setEditAssistant(null);
      setAvatar(undefined);
      setAvatarUrl(asset(DEFAULT_AVATAR));
      form.resetFields();
    }
  }, [open]);

  const onEditAssistantClick = (assistant: Assistant) => {
    setEditAssistant(assistant);
    form.setFieldsValue({
      type: assistant.type,
      name: assistant.name,
      personality: assistant.personality,
      instructions: assistant.instructions,
    });
  };

  return (
    <PermissionChecker allowed={permissions.assistants.manage}>
      <AppPageContent padding="normal" layout="centered">
        <div className="d-flex justify-content-end">
          <Button
            type="default"
            size="small"
            onClick={() => setOpen(true)}
          >
            Add Assistant
          </Button>
        </div>
        {
          loading && (
            <div className="d-flex flex-column gap-2">
              <AssistantPreviewCardSkeleton />
              <AssistantPreviewCardSkeleton />
              <AssistantPreviewCardSkeleton />
              <AssistantPreviewCardSkeleton />
              <AssistantPreviewCardSkeleton />
              <AssistantPreviewCardSkeleton />
              <AssistantPreviewCardSkeleton />
              <AssistantPreviewCardSkeleton />
              <AssistantPreviewCardSkeleton />
              <AssistantPreviewCardSkeleton />
            </div>
          )
        }
        {
          assistants && (
            <div>
              {
                assistants.map((assistant) => (
                  <div
                    key={assistant._id}
                    className="mt-2"
                  >
                    <AssistantPreviewCard
                      type={assistant.type}
                      name={assistant.name}
                      createdAt={assistant.createdAt}
                      avatar={assistant.avatar}
                      personality={assistant.personality}
                      onEditClick={() => {
                        onEditAssistantClick(assistant);
                      }}
                    />
                  </div>
                ))
              }
            </div>
          )
        }
        <Drawer
          open={open}
          onClose={() => setOpen(false)}
          width={mobile ? '100%' : 500}
          title={editAssistant ? 'Edit Assistant' : 'Add Assistant'}
        >
          <div>
            <Form
              form={form}
              layout="vertical"
              colon={false}
              onFinish={request.submit}
              requiredMark={false}
              initialValues={{
                name: '',
                personality: '',
              }}
            >
              <div>
                <Form.Item>
                  <ImgCrop>
                    <Upload.Dragger
                      style={{ width: '100%' }}
                      className="w-100"
                      showUploadList={false}
                      beforeUpload={(file) => {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                          setAvatarUrl(e.target?.result as string);
                          setAvatar(file);
                        };
                        reader.readAsDataURL(file);
                        return false;
                      }}
                    >
                      <Avatar
                        size={100}
                        src={avatarUrl}
                      />
                    </Upload.Dragger>
                  </ImgCrop>
                </Form.Item>
              </div>
              <Form.Item
                name="type"
                label="Type"
                extra="The type of assistant you want to create"
                rules={[
                  {
                    required: true,
                    message: 'Please select a type',
                  },
                ]}
              >
                <Select
                  options={Object.values(AssistantType).map((type) => ({
                    label: getAssistantTypeLabel(type),
                    value: type,
                  }))}
                  placeholder="Select a type"
                />
              </Form.Item>
              <Form.Item
                name="name"
                label="Name"
                extra="This name will be visible by your customers in the chat"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a name',
                  },
                ]}
              >
                <Input
                  placeholder="Name"
                />
              </Form.Item>
              <Form.Item
                name="personality"
                label="Personality"
                extra="Input the type of personality you want for your assistant"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a personality',
                  },
                ]}
              >
                <Input
                  placeholder="Personality"
                />
              </Form.Item>
              <Form.Item
                name="instructions"
                label="Instructions"
                extra="Instructions for your assistant, like how to behave, what to say, etc."
              >
                <Input.TextArea
                  placeholder="Instructions"
                  rows={8}
                />
              </Form.Item>
              <div className="d-flex justify-content-between w-100">
                <Button
                  type="default"
                  onClick={() => setOpen(false)}
                  size="small"
                >
                  Cancel
                </Button>
                <Form.Item>
                  <Button
                    type="primary"
                    size="small"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </Drawer>
      </AppPageContent>
    </PermissionChecker>
  );
}
