import React from 'react';
import {
  Button,
  Card, Col, Form, Input, Row,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import useRequest from '../../common/hooks/useRequest';
import api, { Workspace } from '../../api';
import { setActiveWorkspace, WorkspaceStore } from '../state';

export default function WorkspaceCreatePage() {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const request = useRequest<Workspace>(async (values: Pick<Workspace, 'slug' | 'name'>) => api.workspaces.create({
    name: values.name,
    slug: values.slug,
  }), {
    form,
    onSuccess: (response) => {
      WorkspaceStore.update((s) => ({
        ...s,
        workspaces: [...s.workspaces, response],
      }));
      setActiveWorkspace(response).then(() => {
        navigate(`/${response.slug}/inbox`);
      });
    },
  });

  return (
    <div className="d-flex align-items-center min-vh-100">
      <Row justify="center" className="flex-1">
        <Col span={24} md={12} lg={10}>
          <Card title="Create Workspace">
            <Form
              form={form}
              onFinish={request.submit}
              name="create-workspace"
              layout="vertical"
              initialValues={{ remember: true }}
              autoComplete="off"
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please enter workspace name' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Slug"
                name="slug"
                rules={[{ required: true, message: 'Please enter workspace slug' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <Button loading={request.loading} className="mt-4" htmlType="submit" type="primary" block>Create</Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
