import { useCallback, useEffect } from 'react';
import { AssistantsState, AssistantStore } from '../state';
import useActiveWebsite from '../../website/hooks/useActiveWebsite';
import api, { Assistant } from '../../api';

type UseAssistants = AssistantsState & {
  addAssistant: (assistant: Assistant) => void;
  updateAssistant: (assistant: Assistant) => void;
};

export default function useAssistants(): UseAssistants {
  const state = AssistantStore.useState();
  const website = useActiveWebsite();

  useEffect(() => {
    if (!state.initialized) {
      if (!website) {
        throw new Error('No active website found');
      }

      api.assistants.list(website._id).then((response) => {
        AssistantStore.update((s) => ({
          ...s,
          assistants: response.items,
          initialized: true,
          loading: false,
        }));
      });
    }
  }, [state.initialized]);

  const addAssistant = useCallback((assistant: Assistant) => {
    AssistantStore.update((s) => ({
      ...s,
      assistants: [assistant, ...s.assistants],
    }));
  }, []);

  const updateAssistant = useCallback((assistant: Assistant) => {
    AssistantStore.update((s) => ({
      ...s,
      assistants: s.assistants.map((item) => (item._id === assistant._id ? assistant : item)),
    }));
  }, []);

  return {
    ...state,
    addAssistant,
    updateAssistant,
  };
}
