import { ReactNode } from 'react';
import { Collapse } from 'antd';
import styles from './index.module.scss';

interface RightSidebarSectionProps {
  title: string;
  leftActions?: ReactNode;
  rightActions?: ReactNode;
  children: ReactNode | ReactNode[];
}

export default function RightSidebarSection(props: RightSidebarSectionProps) {
  const {
    title, leftActions, rightActions, children,
  } = props;

  return (
    <div className={styles.container}>
      <Collapse
        ghost
        expandIconPosition="right"
        defaultActiveKey={['1']}
        className={styles.collapse}
      >
        <Collapse.Panel
          header={(
            <span className="m-0 d-flex align-items-center justify-content-between">
              <span className="d-flex gap-1 align-items-center">
                <span className={styles.title}>{title}</span>
                {leftActions}
              </span>
              {rightActions}
            </span>
          )}
          key="1"
        >
          {children}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}
