import {
  useBlockNoteEditor,
  useComponentsContext,
  useSelectedBlocks,
} from '@blocknote/react';
import '@blocknote/mantine/style.css';
import {
  Block,
  BlockFromConfig,
  BlockNoteEditor,
  BlockSchema,
  FileBlockConfig,
  InlineContentSchema,
  StyleSchema,
} from '@blocknote/core';
import { ColumnWidthOutlined } from '@ant-design/icons';

// Adjusted from
// https://github.com/TypeCellOS/BlockNote/blob/459a07439d32d287e7a535c91e6b8cffd7e66967/packages/core/src/blocks/defaultBlockTypeGuards.ts#L67
export function checkBlockIsImageBlock<
  B extends BlockSchema,
  I extends InlineContentSchema,
  S extends StyleSchema,
>(
  block: Block<any, I, S>,
  editor: BlockNoteEditor<B, I, S>,
): block is BlockFromConfig<FileBlockConfig, I, S> {
  return (
    (block.type in editor.schema.blockSchema
      && editor.schema.blockSchema[block.type].type === 'image')
    || false
  );
}

// Custom Formatting Toolbar Button to toggle blue text & background color.
export default function FullWidthButton() {
  const editor = useBlockNoteEditor();

  const Components = useComponentsContext()!;

  const selectedBlocks = useSelectedBlocks(editor);
  const block = selectedBlocks.length === 1 ? selectedBlocks[0] : undefined;
  if (!block || !checkBlockIsImageBlock(block, editor)) {
    return null;
  }

  // @ts-expect-error: Property 'previewWidthRelative'
  // does not exist on type 'Block<any, InlineContentSchema, StyleSchema>'.
  const isSelected = block.props.previewWidthRelative === '100%';

  return (
    <Components.FormattingToolbar.Button
      mainTooltip="Full Width"
      onClick={() => {
        // Change the block's width to 100% if it's not already.
        if (typeof block.props === 'object') {
          editor.updateBlock(block.id, {
            props: {
              // @ts-ignore
              previewWidthRelative: isSelected ? null : '100%',
            },
          });
        }
      }}
      isSelected={isSelected}
    >
      <ColumnWidthOutlined />
    </Components.FormattingToolbar.Button>
  );
}
