import { useEffect, useState } from 'react';

function checkKeyDown(pressedKey: string[], keys: string | string[], mode: 'all' | 'some'): boolean {
  if (!Array.isArray(keys)) {
    return pressedKey.includes(keys);
  }

  return mode === 'all' ? keys.every((k) => pressedKey.includes(k)) : keys.some((k) => pressedKey.includes(k));
}

export default function useKeyDown(key: string | string[], mode: 'all' | 'some' = 'some'): boolean {
  const [, setPressedKeys] = useState<string[]>([]);

  // We store the result in the state in order to prevent the calculations
  // running on every render
  const [keyDown, setKeyDown] = useState(false);

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (
        (typeof key === 'string' && event.key === key)
        || (Array.isArray(key) && key.includes(event.key))
      ) {
        setPressedKeys((prev) => {
          const result = [...prev, event.key];

          setKeyDown(checkKeyDown(result, key, mode));
          return result;
        });
      }
    };

    const onKeyUp = (event: KeyboardEvent) => {
      setPressedKeys((prev) => {
        const result = prev.filter((k) => k !== event.key);

        setKeyDown(checkKeyDown(result, key, mode));
        return result;
      });
    };

    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('keyup', onKeyUp);
    };
  }, []);

  return keyDown;
}
