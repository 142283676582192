/* eslint-disable no-nested-ternary */
import '@blocknote/core/fonts/inter.css';
import '@blocknote/mantine/style.css';
import React, { useEffect, useState } from 'react';
import {
  useCreateBlockNote,
} from '@blocknote/react';
import {
  defaultBlockSpecs,
  BlockNoteSchema,
  Block,
} from '@blocknote/core';
import { BlockNoteView } from '@blocknote/mantine';

import EditorSuggestionMenuController from './EditorSuggestionMenuController';
import EditorFormattingToolbarController from './EditorFormattingToolbarController';
import ReactImageBlock from './ReactImageBlock';

export interface EditorProps {
  value?: Block[];
  onChange?: (value: Block[], html: string) => void;
  uploadFile?: (file: File) => Promise<string>;
}

function fixHTML(blocks: Block[], html: string) {
  // Temporary fix of images not being rendered properly (they are missing the src attribute).
  // https://github.com/TypeCellOS/BlockNote/issues/1049
  // TODO: remove once the above issue is fixed.
  let fixed = html;

  blocks.forEach((block) => {
    if (block.type === 'image') {
      const target = `alt="${block.props.caption || block.props.name}"`;
      fixed = fixed.replaceAll(target, `${target} src="${block.props.url}"`);
    }
  });

  return fixed;
}

const schema = BlockNoteSchema.create({
  blockSpecs: {
    // first pass all the blockspecs from the built in, default block schema
    ...defaultBlockSpecs,

    // Custom image block
    image: ReactImageBlock,

    // A temporary fix for an issue where the rendered HTML as `null` instead of list item number
    numberedListItem: {
      ...defaultBlockSpecs.numberedListItem,
      config: {
        ...defaultBlockSpecs.numberedListItem.config,
        propSchema: {
          ...defaultBlockSpecs.numberedListItem.config.propSchema,
          index: {
            default: '',
          },
        },
      },
    },

    // disable blocks you don't want
    audio: undefined as any,
    video: undefined as any,
    file: undefined as any,
  },
});

export default function Editor(props: EditorProps) {
  const { value, onChange, uploadFile } = props;

  const [initialized, setInitialized] = useState(Boolean(value));

  // Creates a new editor instance.
  const editor = useCreateBlockNote({
    // @ts-ignore
    schema,
    // @ts-ignore
    initialContent: value,
    uploadFile,
  });

  useEffect(() => {
    // Update the editor content when the value prop changes.
    if (!initialized && value) {
      // Force replace the blocks when the value prop becomes
      // available for the first time.
      // The initial content is not updating the value when
      // it changes.
      editor.replaceBlocks(value, editor.document);
      setInitialized(true);
    }
  }, [value, initialized]);

  // Renders the editor instance using a React component.
  return (
    <BlockNoteView
      editor={editor}
      theme="light"
      slashMenu={false}
      formattingToolbar={false}
      onChange={() => {
        if (onChange) {
          editor.blocksToFullHTML(editor.document).then((html) => {
            // @ts-ignore
            onChange(editor.document, fixHTML(editor.document, html));
          }).catch(() => {
            // @ts-ignore
            onChange(editor.document, '');
          });
        }
      }}
      emojiPicker={false}
    >
      <EditorSuggestionMenuController editor={editor} />
      <EditorFormattingToolbarController />
    </BlockNoteView>
  );
}
