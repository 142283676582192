import { Outlet } from 'react-router-dom';
import useActiveWorkspaceLoading from '../../hooks/useActiveWorkspaceLoading';
import styles from './index.module.scss';

export default function WorkspacePageWrapper() {
  const loading = useActiveWorkspaceLoading();

  if (loading) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center min-vh-100">
        <img className={styles.loader} src="/zupport-blue-logo.png" alt="zupport-logo" />
        <h3>
          Loading your workspace
        </h3>
      </div>
    );
  }

  return (<Outlet />);
}
