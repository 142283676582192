import BaseModel from './BaseModel';

export enum ContentWebsitePageCrawlingStatus {
  IN_PROGRESS = 'in-progress',
  SUCCESS = 'success',
  FAILED = 'failed',
  EXCLUDED = 'excluded',
}

export default interface ContentWebsitePage extends BaseModel {
  websiteId: string;
  contentWebsiteId: string;
  content: string;
  title: string;
  url: string;
  workspaceId: string;
  status: ContentWebsitePageCrawlingStatus;
  lastCrawledAt: Date;
}
