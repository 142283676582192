import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useActiveWorkspace from '../../workspace/hooks/useActiveWorkspace';
import useUser from '../../auth/hooks/useUser';
import api from '../../api';
import { setActiveWorkspace } from '../../workspace/state';

export default function DashboardPage() {
  const navigate = useNavigate();
  const activeWorkspace = useActiveWorkspace();
  const user = useUser();

  useEffect(() => {
    (async () => {
      let workspace = activeWorkspace;

      if (!workspace && user?.workspaceIds.length) {
        workspace = await api.workspaces.get(user.workspaceIds[0]);
        await setActiveWorkspace(workspace);
      }

      if (workspace) {
        navigate(`/${workspace.slug}/inbox`);
      }
    })();
  }, [activeWorkspace, user]);

  return null;
}
