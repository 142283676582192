import BaseModel from './BaseModel';
import ConversationParticipant from './ConversationParticipant';

export enum ConversationMessageActionType {
  TRANSFER_TO_HUMAN = 'transfer-to-human',
  CONVERSATION_JOINED = 'conversation-joined',
}

type TransferToHumanAction = {
  type: ConversationMessageActionType.TRANSFER_TO_HUMAN,
};

type ConversationJoinedAction = {
  type: ConversationMessageActionType.CONVERSATION_JOINED,
};

export type ConversationMessageAction = TransferToHumanAction | ConversationJoinedAction;

export interface ConversationMessageData {
  message: string;
  conversationId: BaseModel['_id'];
  clientId?: string;
}

export default interface ConversationMessage extends ConversationMessageData, BaseModel {
  participant: ConversationParticipant;
  actions?: ConversationMessageAction[];
}
