import { Card, Switch } from 'antd';

interface CardSwitchProps {
  title: string;
  subtitle?: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
}

export default function CardSwitch(props: CardSwitchProps) {
  const {
    title, subtitle, value, onChange,
  } = props;

  return (
    <Card bordered style={{ marginBottom: 16 }}>
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <h3 className="m-0">{title}</h3>
          {
            subtitle && (
              <p className="m-0 text-muted">
                {subtitle}
              </p>
            )
          }
        </div>
        <Switch
          checked={value}
          onChange={onChange}
        />
      </div>
    </Card>
  );
}
