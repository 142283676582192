import { SVGProps } from 'react';

export default function BrowserIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <span className="anticon anticon-setting ant-menu-item-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        viewBox="0 0 256 256"
        {...props}
      >
        <path
          d="M216,36H40A20,20,0,0,0,20,56V200a20,20,0,0,0,20,20H216a20,20,0,0,0,20-20V56A20,20,0,0,0,216,36Zm-4,24V84H44V60ZM44,196V108H212v88Z"
        />
      </svg>
    </span>
  );
}
