import { AssistantType } from '../../api';

// eslint-disable-next-line import/prefer-default-export
export function getAssistantTypeLabel(type: AssistantType) {
  switch (type) {
    case AssistantType.CUSTOMER_SUPPORT:
      return 'Customer Support';
    case AssistantType.PROACTIVE_ENGAGEMENT:
      return 'Proactive Engagement';
    default:
      return 'Unknown Type';
  }
}
