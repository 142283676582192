import { Store } from 'pullstate';
import { Agent, ConversationParticipant } from '../../api';

interface InboxState {
  participant: ConversationParticipant | null;
  agent: Agent | null;
  agents: Agent[];
}

const InboxStore = new Store<InboxState>({
  participant: null,
  agent: null,
  agents: [],
});

export default InboxStore;
