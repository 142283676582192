import {
  Col, Form, Input, Row,
} from 'antd';
import Editor, { EditorProps } from '../../../common/components/Editor';
import { URL_SLUG_REGEX } from '../../../common/utils/url';

interface HelpCenterArticleLocalizedContentFormProps extends Pick<EditorProps, 'uploadFile'> {
  isActive: boolean;
  language: string;
  onTitleChange?: (title: string) => void;
  onHTMLChange?: (html: string) => void;
}

export default function HelpCenterArticleLocalizedContentForm(
  props: HelpCenterArticleLocalizedContentFormProps,
) {
  const {
    language,
    onTitleChange,
    uploadFile,
    onHTMLChange,
    isActive,
  } = props;

  return (
    <>
      <Form.Item
        name={['content', language, 'title']}
        rules={[
          {
            required: true,
            message: 'Please enter a title',
          },
        ]}
      >
        <Input
          placeholder="Title"
          style={{
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0,
            fontSize: '20px',
            borderRadius: 0,
            fontWeight: 'bold',
          }}
          onChange={(e) => onTitleChange?.(e.target.value)}
        />
      </Form.Item>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Slug"
            name={['content', language, 'slug']}
            extra="The slug is the URL-friendly version of the title. It is usually all lowercase and contains only letters, numbers, and hyphens. It can be different for each language."
            rules={[
              {
                required: true,
                message: 'Please enter a slug',
              },
              {
                pattern: URL_SLUG_REGEX,
                message: 'Invalid slug (only letters, numbers, and hyphens are allowed)',
              },
            ]}
          >
            <Input placeholder="Slug" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Description"
            name={['content', language, 'description']}
            extra="Will be visible in the article preview"
            rules={[
              {
                required: true,
                message: 'Please enter a description',
              },
            ]}
          >
            <Input placeholder="Description" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label={(
          <span style={{ margin: '0 54px' }}>
            Content
          </span>
        )}
        name={['content', language, 'content']}
        style={{ margin: '0 -54px' }}
      >
        {/*
         A trick in order to make the editor work.
          This component is being rendered in multiple antd tabs.
          This is causing the "Editor" to be rendered multiple times, which doesn't work well.
          For instance the images are being rendered in the first one only after a page refresh.
          The trick is to render it only once (when the tab is active)
        */}
        {
          isActive ? (
            <Editor
              uploadFile={uploadFile}
              onChange={(_, html) => {
                if (onHTMLChange) {
                  onHTMLChange(html);
                }
              }}
            />
          ) : (
            <Input />
          )
        }
      </Form.Item>
      <Form.Item
        label="HTML"
        name={['content', language, 'html']}
        hidden
      >
        <Input />
      </Form.Item>
    </>
  );
}
