import { Skeleton } from 'antd';
import { useState } from 'react';
import { ConversationWithMetadata } from '../../../api';
import UserAvatar from '../../../common/components/UserAvatar';
import cn from '../../../common/utils/cn';

interface ConversationCardPreviewProps extends Pick<ConversationWithMetadata, 'lastMessageAt' | 'updatedAt' | 'lastMessage' | 'metadata'> {
  onClick?: () => void;
}

function timeAgo(date: Date) {
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days}d ago`;
  }

  if (hours > 0) {
    return `${hours}h ago`;
  }

  if (minutes > 0) {
    return `${minutes}m ago`;
  }

  return `${seconds}s ago`;
}

function useSkeletonRangeSize(min: number, max: number) {
  const [size] = useState(() => Math.floor(Math.random() * (max - min + 1)) + min);
  return size;
}

export function ConversationSkeleton() {
  const nameWidth = useSkeletonRangeSize(50, 100);
  const messageWidth = useSkeletonRangeSize(100, 200);
  const timeWidth = useSkeletonRangeSize(50, 80);

  return (
    <div className="p-3 chat-widget-conversation-card-preview">
      <div className="d-flex align-items-center gap-2">
        <Skeleton.Avatar size={40} active />
        <div>
          <div className="chat-widget-conversation-card-preview-name">
            <Skeleton.Input
              style={{ minWidth: nameWidth, maxWidth: nameWidth, height: 17 }}
              active
            />
          </div>
          <div className="chat-widget-conversation-card-preview-message">
            <Skeleton.Input
              style={{
                minWidth: messageWidth,
                maxWidth: messageWidth,
                height: 17,
                marginTop: 4,
              }}
              active
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center gap-2">
        <div className="chat-widget-conversation-card-preview-time">
          <Skeleton.Input
            style={{ minWidth: timeWidth, maxWidth: timeWidth, height: 17 }}
            active
          />
        </div>
      </div>
    </div>
  );
}

export default function ConversationCardPreview(props: ConversationCardPreviewProps) {
  const {
    lastMessageAt, updatedAt, lastMessage, onClick, metadata,
  } = props;
  const lastMessageDate = lastMessageAt || updatedAt;
  const date = new Date(lastMessageDate);
  const unreadMessagesCount = metadata?.unreadMessagesCount || 0;

  return (
    <div
      className={cn(
        'p-3 chat-widget-conversation-card-preview',
        unreadMessagesCount > 0 && 'chat-widget-conversation-card-preview-unread',
      )}
      onClick={onClick}
      tabIndex={0}
      role="button"
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClick?.();
        }
      }}
    >
      <div className="d-flex align-items-center gap-2">
        <UserAvatar
          avatar={lastMessage?.participant?.avatar}
          size={40}
        />
        <div>
          {
            lastMessage?.participant && (
            <div className="chat-widget-conversation-card-preview-name">
              {lastMessage.participant.name}
            </div>
            )
          }
          <div className="chat-widget-conversation-card-preview-message">
            {lastMessage?.message || 'No messages'}
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center gap-2">
        <div className="chat-widget-conversation-card-preview-time">
          {timeAgo(date)}
        </div>
        {
          unreadMessagesCount > 0 && (
            <div className="chat-widget-conversation-card-preview-unread-count">
              {unreadMessagesCount}
            </div>
          )
        }
      </div>
    </div>
  );
}
