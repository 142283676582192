import BaseModel from './BaseModel';
import ConversationMessage from './ConversationMessage';
import ConversationParticipant from './ConversationParticipant';
import ConversationIntent from './ConversationIntent';
import ConversationMetadata from './ConversationMetadata';

export enum ConversationStatus {
  /**
   * The conversation is open, meaning that it hasn't been resolved yet.
   */
  OPEN = 'open',

  /**
   * The conversation is closed, meaning that it has been resolved.
   */
  CLOSED = 'closed',
}

export enum ConversationEngagementType {
  /**
   * The conversation was initiated by an AI agent or a bot.
   */
  PROACTIVE = 'proactive',

  /**
   * The conversation was initiated by a user.
   */
  REACTIVE = 'reactive',
}

export enum ConversationHandlerType {
  /**
   * The conversation is handled by an AI agent.
   */
  AI = 'ai',

  /**
   * The conversation is handled by a human agent.
   */
  HUMAN = 'human',
}

export enum ConversationResolution {
  /**
   * The conversation was resolved.
   */
  OPEN = 'open',

  /**
   * The conversation was resolved.
   */
  RESOLVED = 'resolved',

  /**
   * The conversation was partially resolved.
   */
  PARTIALLY_RESOLVED = 'partially-resolved',

  /**
   * The conversation was unresolved.
   */
  UNRESOLVED = 'unresolved',
}

export enum ConversationPendingAction {
  /**
   * The conversation is awaiting a human agent.
   */
  AWAITING_HUMAN_AGENT = 'awaiting-human-agent',
}

export default interface Conversation extends BaseModel {
  /**
   * The website that this conversation belongs to.
   */
  websiteId: BaseModel['_id'];

  /**
   * The participants in this conversation.
   */
  participantIds: ConversationParticipant['_id'][];

  /**
   * The participants in this conversation.
   */
  participants: Record<string, ConversationParticipant>;

  /**
   * The last message in this conversation.
   */
  lastMessage?: ConversationMessage;

  /**
   * The timestamp of the last message in this conversation.
   */
  lastMessageAt?: Date;

  /**
   * The number of messages in this conversation.
   */
  messagesCount: number;

  /**
   * The participant that initiated this conversation.
   */
  initiatedBy: ConversationParticipant;

  /**
   * Whether a human agent is involved in this conversation.
   */
  humanAgentInvolved: boolean;

  /**
   * The status of this conversation.
   */
  status: ConversationStatus;

  /**
   * The type of engagement for this conversation.
   */
  engagementType: ConversationEngagementType;

  /**
   * For engagement replies, whether the reply was sent by the user.
   * We might want to stop the conversation if the user replies.
   */
  hasCustomerReply: boolean;

  /**
   * The type of handler for this conversation.
   */
  handlerType: ConversationHandlerType;

  /**
   * The ids of the assignees for this conversation.
   */
  assigneeId: BaseModel['_id'] | null;

  /**
   * The pending action for this conversation.
   */
  pendingAction?: ConversationPendingAction;

  /**
   * The origin of the conversation (url)
   */
  origin?: string;

  /**
   * A list of intents associated with the conversation.
   */
  intents?: ConversationIntent[];

  /**
   * The workspace that this conversation belongs to.
   */
  workspaceId: BaseModel['_id'];

  /**
   * The resolution of this conversation.
   */
  resolution: ConversationResolution;
}

/**
 * A conversation with metadata.
 * This is a combination of a conversation and its metadata.
 * It can be returned by the server when querying conversations.
 * The reason is to avoid querying the metadata separately.
 */
export interface ConversationWithMetadata extends Conversation {
  metadata?: ConversationMetadata;
}
