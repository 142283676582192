import { Select as AntSelect, SelectProps as AntSelectProps } from 'antd';
import { ReactNode } from 'react';
import cn from '../../utils/cn';

export type SelectOption = {
  value: any;
  label: string;
  icon?: ReactNode;
};

export interface SelectProps extends Omit<AntSelectProps, 'options'> {
  options: SelectOption[];
}

export default function Select(props: SelectProps) {
  const {
    size = 'middle', options, popupClassName, ...rest
  } = props;

  return (
    <AntSelect
      size={size}
      options={options.map((option) => ({
        value: option.value,
        label: (
          <span className="d-flex align-items-center gap-2">
            {option.icon && <span className="mr-2">{option.icon}</span>}
            {option.label}
          </span>
        ),
        textLabel: option.label,
      }))}
      filterOption={(input, option) => {
        if (typeof option?.textLabel === 'string') {
          return option.textLabel.toLowerCase().includes(input.toLowerCase());
        }

        return false;
      }}
      popupClassName={cn(`ant-select-dropdown-${size}`, popupClassName)}
      {...rest}
    />
  );
}
