import { useEffect } from 'react';
import { WebsiteState, WebsiteStore } from '../state';
import api, { WebsiteModule } from '../../api';

interface UseWebsiteModules extends Pick<WebsiteState, 'modules' | 'submodules' | 'modulesOptions' | 'submodulesOptions' | 'unifiedModulesOptions'> {}

export default function useWebsiteModules(): UseWebsiteModules {
  const website = WebsiteStore.useState((s) => s.website);
  const initialized = WebsiteStore.useState((s) => s.modulesInitialized);

  useEffect(() => {
    if (website && !initialized) {
      api.websites.modules.all(website._id).then((data) => {
        const modules: WebsiteModule[] = [];
        const submodules: WebsiteModule[] = [];
        const modulesOptions: UseWebsiteModules['modulesOptions'] = [];
        const submodulesOptions: UseWebsiteModules['submodulesOptions'] = [];
        const unifiedModules: WebsiteModule[] = [];
        const unifiedModulesOptions: UseWebsiteModules['modulesOptions'] = [];

        data.items.forEach((module) => {
          const value = { label: module.name, value: module._id };
          if (module.isSubmodule) {
            submodules.push(module);
            submodulesOptions.push(value);
          } else {
            modules.push(module);
            modulesOptions.push(value);
          }

          unifiedModules.push(module);
        });

        submodules.forEach((module) => {
          const parent = modules.find((m) => m._id === module.parentId);

          if (parent) {
            unifiedModulesOptions.push({
              label: `${parent.name}:${module.name}`,
              value: `${parent._id}:${module._id}`,
            });
          }
        });

        WebsiteStore.update((s) => ({
          ...s,
          modules,
          submodules,
          modulesOptions,
          submodulesOptions,
          modulesInitialized: true,
          initialized: true,
          unifiedModules,
          unifiedModulesOptions,
        }));
      });
    }
  }, [website?._id, initialized]);

  const modules = WebsiteStore.useState((s) => s.modules);
  const submodules = WebsiteStore.useState((s) => s.submodules);
  const modulesOptions = WebsiteStore.useState((s) => s.modulesOptions);
  const submodulesOptions = WebsiteStore.useState((s) => s.submodulesOptions);
  const unifiedModulesOptions = WebsiteStore.useState((s) => s.unifiedModulesOptions);

  return {
    modules, submodules, modulesOptions, submodulesOptions, unifiedModulesOptions,
  };
}
