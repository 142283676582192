import {
  useCallback, useEffect, useState, UIEvent, useRef,
} from 'react';
import 'simplebar-react/dist/simplebar.min.css';
import SimpleBar from 'simplebar-react';
import { flushSync } from 'react-dom';
import { useSearchParams } from 'react-router-dom';
import api, { ConversationMetadata, ConversationWithMetadata } from '../../../api';
import useActiveWebsite from '../../../website/hooks/useActiveWebsite';
import styles from './index.module.scss';
import useAgent from '../../hooks/useAgent';
import Conversation, {
  ConversationEngagementType,
  ConversationHandlerType,
  ConversationStatus,
} from '../../../api/models/Conversation';
import ConversationPreviewCard from '../ConversationPreviewCard';
import debounce from '../../../common/utils/debounce';
import useURLBuilder from '../../../common/hooks/useURLBuilder';
import { InboxPageParams } from '../../config/params';
import useSearchParam from '../../../common/hooks/useSearchParam';
import Select from '../../../common/components/Select';
import ConversationPreviewCardSkeleton from '../ConversationPreviewCard/ConversationPreviewCardSkeleton';

const $limit = 20;

export default function InboxPageConversationsList() {
  const [params] = useSearchParams();
  const id = params.get(InboxPageParams.CONVERSATION);
  const website = useActiveWebsite();
  const agent = useAgent();
  const [conversations, setConversations] = useState<ConversationWithMetadata[]>([]);
  const [loading, setLoading] = useState(true);
  const loadingRef = useRef(true);
  const hasMoreRef = useRef(true);
  const urlBuilder = useURLBuilder();

  const [tab] = useSearchParam(InboxPageParams.TAB, 'inbox');
  const [paramsEngagementMode, setParamsEngagementMode] = useSearchParam(InboxPageParams.ENGAGEMENT_TYPE, 'incoming');
  const [paramsStatus, setParamsStatus] = useSearchParam(InboxPageParams.STATUS, 'open');
  const [pagination, setPagination] = useState({
    page: 0,
    websiteId: website?._id,
    filter: tab,
    agentId: agent?._id,
    engagementMode: paramsEngagementMode,
    status: paramsStatus,
  });

  useEffect(() => {
    if (
      website
      && (tab !== 'inbox' || agent) // If filter is home, agent must be defined
    ) {
      hasMoreRef.current = true;
      flushSync(() => {
        setConversations([]);
      });
      setPagination((prev) => ({
        ...prev,
        page: 1,
        websiteId: website._id,
        filter: tab,
        agentId: agent?._id,
        engagementMode: paramsEngagementMode,
        status: paramsStatus,
      }));
    }
  }, [website?._id, tab, agent?._id, paramsEngagementMode, paramsStatus]);

  useEffect(() => {
    const { websiteId, agentId } = pagination;
    if (
      pagination.page > 0
      && websiteId
      && (tab !== 'inbox' || agent) // If filter is home, agent must be defined
    ) {
      setLoading(true);
      loadingRef.current = true;
      let assigneeId;

      if (tab === 'inbox' && agent) {
        assigneeId = agentId;
      }

      let engagementType: ConversationEngagementType | undefined;
      let status: ConversationStatus | undefined;

      if (pagination.engagementMode === 'incoming') {
        engagementType = ConversationEngagementType.REACTIVE;
      } else if (pagination.engagementMode === 'proactive') {
        engagementType = ConversationEngagementType.PROACTIVE;
      }

      if (pagination.status === 'open') {
        status = ConversationStatus.OPEN;
      } else if (pagination.status === 'closed') {
        status = ConversationStatus.CLOSED;
      }

      api.conversations.list({
        websiteId,
        assigneeId,
        handlerType: tab === 'ai' ? ConversationHandlerType.AI : undefined,
        unassigned: tab === 'unassigned' ? true : undefined,
        $limit,
        $skip: (pagination.page - 1) * $limit,
        engagementType,
        status,
      }).then((response) => {
        hasMoreRef.current = response.items.length >= $limit;
        setConversations((prev) => [
          ...(pagination.page === 1 ? [] : prev),
          ...response.items,
        ]);
      }).finally(() => {
        setLoading(false);
        loadingRef.current = false;
      });
    }
  }, [pagination]);

  useEffect(() => {
    const updateConversation = (conversation: Conversation, metadata: ConversationMetadata) => {
      setConversations((prev) => prev.map((c) => {
        if (c._id === conversation._id) {
          return {
            ...conversation,
            metadata: {
              ...metadata,
              unreadMessagesCount: conversation._id === id ? 0 : metadata.unreadMessagesCount,
            },
          };
        }

        return c;
      }));
    };

    api.socket().on('conversation:metadata:updated', updateConversation);

    return () => {
      api.socket().off('conversation:metadata:updated', updateConversation);
    };
  }, [id]);

  // eslint-disable-next-line react-hooks/exhaustive-deps,@typescript-eslint/no-unused-vars
  const onScroll = useCallback(debounce((e: UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    const distanceToBottom = target.scrollHeight - target.scrollTop - target.clientHeight;

    if (distanceToBottom < 500 && !loadingRef.current && hasMoreRef.current) {
      setPagination((prev) => ({
        ...prev,
        page: prev.page + 1,
      }));
    }
  }, 500), []);

  return (
    <div className="h-100">
      <div className="p-1 d-flex align-items-center justify-content-between">
        <Select
          options={[
            {
              value: 'all',
              label: 'All',
            },
            {
              value: 'incoming',
              label: 'Incoming',
            },
            {
              value: 'proactive',
              label: 'Proactive',
            },
          ]}
          value={pagination.engagementMode}
          onChange={setParamsEngagementMode}
          style={{
            maxHeight: 32,
          }}
          variant="borderless"
          size="small"
          popupMatchSelectWidth={false}
        />
        <Select
          options={[
            {
              value: 'all',
              label: 'All',
            },
            {
              value: 'open',
              label: 'Open',
            },
            {
              value: 'closed',
              label: 'Closed',
            },
          ]}
          value={pagination.status}
          onChange={setParamsStatus}
          style={{
            maxHeight: 32,
          }}
          variant="borderless"
          size="small"
          popupMatchSelectWidth={false}
        />
      </div>
      <SimpleBar
        className={styles['conversation-list']}
        scrollableNodeProps={{ onScroll }}
      >
        {
          conversations.map((conversation, index) => (
            <ConversationPreviewCard
              key={conversation._id}
              conversation={conversation}
              index={index}
              url={urlBuilder({ [InboxPageParams.CONVERSATION]: conversation._id })}
            />
          ))
        }
        {
          loading && (
            Array.from({ length: 20 }).map(
              (_, index) => <ConversationPreviewCardSkeleton key={index?.toString()} />,
            )
          )
        }
        {
          !loading && conversations.length === 0 && (
            <p className="text-center">No conversations found</p>
          )
        }
      </SimpleBar>
    </div>
  );
}
