import {
  Checkbox, Form, Input, notification,
} from 'antd';
import api, {
  ConversationIntent,
  ConversationIntentData,
  ConversationIntentResolution,
  ConversationIntentStatus,
} from '../../../api';
import Button from '../../../common/components/Button';
import WebsiteUnifiedModuleSelect from '../../../website/components/WebsiteUnifiedModuleSelect';
import ConversationIntentStatusSelect from '../ConversationIntentStatusSelect';
import useRequest from '../../../common/hooks/useRequest';
import ConversationIntentTypeSelect from '../ConversationIntentTypeSelect';
import Select from '../../../common/components/Select';

interface ConversationIntentFormProps {
  conversationId: ConversationIntent['conversationId'];
  intent?: ConversationIntent;
  onSuccess?: (intent: ConversationIntent) => void;
}

type Values = Omit<ConversationIntentData, 'moduleId' | 'submoduleId' | 'conversationId'> & {
  module: string;
};

export default function ConversationIntentForm(props: ConversationIntentFormProps) {
  const { conversationId, intent, onSuccess } = props;
  console.log(intent);
  const [form] = Form.useForm<Values>();
  const request = useRequest(async (values: Values) => {
    const { module, ...rest } = values;
    const { resolution, solution } = values;
    const [moduleId, submoduleId] = module.split(':');

    let valid = true;

    if (resolution === ConversationIntentResolution.RESOLVED && !solution) {
      form.setFields([
        {
          name: 'solution',
          errors: ['Please provide a solution'],
        },
      ]);
      valid = false;
    }

    if (valid) {
      const data: ConversationIntentData = {
        ...rest,
        moduleId,
        submoduleId,
        conversationId,
      };

      try {
        if (intent) {
          const result = await api.conversationIntents.patch(intent._id, data);

          if (onSuccess) {
            onSuccess(result);
          }
        } else {
          const result = await api.conversationIntents.create(data);

          if (onSuccess) {
            onSuccess(result);
          }
        }
      } catch (e: any) {
        notification.error({
          message: 'Something went wrong',
          description: e.message,
        });
      }
    }
  });

  return (
    <Form<Values>
      layout="vertical"
      form={form}
      onFinish={request.submit}
      requiredMark={false}
      initialValues={intent ? {
        ...intent,
        module: `${intent.moduleId}:${intent.submoduleId}`,
      } : {
        status: ConversationIntentStatus.IN_PROGRESS,
        resolution: ConversationIntentResolution.UNRESOLVED,
        tags: [],
      }}
      preserve
    >
      <Form.Item
        name="type"
        label="Type"
        rules={[{ required: true, message: 'Please select a type' }]}
      >
        <ConversationIntentTypeSelect size="small" />
      </Form.Item>
      <Form.Item
        name="summary"
        hasFeedback
        help="Describe the intent in a few words"
        rules={[{ required: true, message: 'Please enter a summary' }]}
      >
        <Input.TextArea placeholder="Summary" size="small" />
      </Form.Item>
      <Form.Item
        name="solution"
        hasFeedback
        help="Describe the solution (if any)"
        className="pt-2"
        dependencies={['resolution']}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue('resolution') === ConversationIntentResolution.RESOLVED && (!value || !value.trim())) {
                return Promise.reject(new Error('Please provide a solution'));
              }

              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input.TextArea placeholder="Solution" size="small" />
      </Form.Item>
      <Form.Item
        shouldUpdate
        className="pt-2"
        label="Module"
        name="module"
        rules={[{ required: true, message: 'Please select the module' }]}
      >
        <WebsiteUnifiedModuleSelect size="small" />
      </Form.Item>
      <Form.Item name="tags" label="Tags">
        <Select
          mode="tags"
          style={{ width: '100%' }}
          tokenSeparators={[',']}
          options={[]}
          size="small"
          placeholder="Enter tags"
          popupClassName="d-none"
        />
      </Form.Item>
      <Form.Item name="status" label="Status">
        <ConversationIntentStatusSelect size="small" />
      </Form.Item>
      <div className="mt-2 d-flex justify-content-between">
        <Form.Item shouldUpdate noStyle>
          {({
            getFieldValue,
            setFieldsValue,
          }) => {
            const resolution = getFieldValue('resolution');

            return (
              <div>
                <Checkbox
                  checked={resolution === ConversationIntentResolution.RESOLVED}
                  onChange={(e) => {
                    setFieldsValue({
                      resolution: e.target.checked
                        ? ConversationIntentResolution.RESOLVED
                        : ConversationIntentResolution.UNRESOLVED,
                    });
                  }}
                >
                  Resolved
                </Checkbox>
              </div>
            );
          }}
        </Form.Item>
        <Form.Item noStyle name="resolution">
          <Input type="hidden" />
        </Form.Item>
        <Form.Item noStyle>
          <Button
            size="small"
            htmlType="submit"
            loading={request.loading}
          >
            {
              intent ? 'Save' : 'Create'
            }
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}
