import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';
import useURLBuilder from './useURLBuilder';

export default function useSearchParam(
  name: string,
  fallback?: string,
): [string | undefined, (value: string) => void] {
  const buildUrl = useURLBuilder();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const value = params.get(name) || fallback;
  const setValue = useCallback((newValue: string) => {
    navigate(buildUrl({
      [name]: newValue,
    }));
  }, [name, value, buildUrl]);

  return [value, setValue];
}
