import {
  InteractionOutlined,
  LinkOutlined, PicLeftOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Outlet, useMatch } from 'react-router-dom';
import AppSubPageLayout, { AppSubPageLayoutProps } from '../../layout/components/AppSubPageLayout';
import usePermissions from '../../permissions/hooks/usePermissions';

interface WebsiteSettingsPageLayoutProps extends Omit<AppSubPageLayoutProps, 'pages' | 'activePageKey' | 'content'> {}

export default function WebsiteSettingsPageLayout(props: WebsiteSettingsPageLayoutProps) {
  const match = useMatch('/:workspaceSlug/website/settings/:pageKey?');
  const activePageKey = match?.params.pageKey || 'setup';
  const permissions = usePermissions();

  return (
    <AppSubPageLayout
      allowed={permissions.websites.any}
      activePageKey={activePageKey}
      pages={[
        {
          key: 'setup',
          title: 'Setup',
          path: 'setup',
          icon: <SettingOutlined />,
        },
        {
          key: 'proactive-engagement',
          title: 'Proactive Engagement',
          path: 'proactive-engagement',
          icon: <InteractionOutlined />,
        },
        {
          key: 'details',
          title: 'Details',
          path: 'details',
          icon: <ReadOutlined />,
        },
        {
          key: 'links',
          title: 'Links',
          path: 'links',
          icon: <LinkOutlined />,
        },
        {
          key: 'faqs',
          title: 'FAQs',
          path: 'faqs',
          icon: <QuestionCircleOutlined />,
        },
        {
          key: 'content',
          title: 'Content',
          path: 'content',
          icon: <PicLeftOutlined />,
        },
      ]}
      content={<Outlet />}
      {...props}
    />
  );
}
