import BaseModel from './BaseModel';

export enum WorkspaceUserRole {
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
  CUSTOMER_SUPPORT_AGENT = 'CUSTOMER_SUPPORT_AGENT',
  SOFTWARE_ENGINEER = 'SOFTWARE_ENGINEER',
}

export interface WorkspaceUserData {
  firstName: string;
  lastName: string;
  email: string;
  role: WorkspaceUserRole;
}

export default interface WorkspaceUser extends WorkspaceUserData, BaseModel {
  accepted: boolean;
  workspaceId: BaseModel['_id'];
  token: string;
  avatar?: string;
  userId?: BaseModel['_id']; // Can be null if the user is not registered yet
}
