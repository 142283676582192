import { REACT_APP_CDN_URL } from '../../config/env';

const RAW_FALLBACK = '/static/img/placeholder-image.webp';

export function asset(path?: string): string {
  if (!path) {
    return asset(RAW_FALLBACK);
  }

  return `${REACT_APP_CDN_URL}${path}`;
}

export const FALLBACK_IMAGE_URL = asset('/static/img/placeholder-image.webp');
