import { useCallback, useMemo } from 'react';
import useActiveWorkspaceUser from '../../workspace/hooks/useActiveWorkspaceUser';
import { WorkspaceUserRole } from '../../api';

interface UsePermissions {
  assistants: {
    manage: boolean;
  };
  websites: {
    any: boolean;
  };
}

export default function usePermissions(): UsePermissions {
  const user = useActiveWorkspaceUser();

  const is = useCallback((role: WorkspaceUserRole | WorkspaceUserRole[]) => {
    if (!user) {
      return false;
    }

    if (Array.isArray(role)) {
      return role.includes(user.role);
    }

    return user.role === role;
  }, [user]);

  return useMemo(() => {
    const ADMIN_OWNER = is([WorkspaceUserRole.ADMIN, WorkspaceUserRole.OWNER]);
    const ADMIN_OWNER_DEV = is([
      WorkspaceUserRole.ADMIN,
      WorkspaceUserRole.OWNER,
      WorkspaceUserRole.SOFTWARE_ENGINEER,
    ]);

    return {
      assistants: {
        manage: ADMIN_OWNER,
      },
      websites: {
        any: ADMIN_OWNER_DEV,
      },
    };
  }, [is]);
}
