import BaseModel from './BaseModel';

export enum UploadType {
  IMAGE = 'image',
}

interface UploadData {
  name: string;
  path: string;
  url: string;
  used: boolean;
  type: UploadType;
  size: number;
  extension: string;
  mimeType: string;
  usedIn: BaseModel['_id'][];
  usageCount: number;
}

export default interface Upload extends UploadData, BaseModel {
  workspaceId: BaseModel['_id'];
  userId?: BaseModel['_id'];
}
