import { useEffect } from 'react';

export default function useScript(src: string, enabled = true) {
  useEffect(() => {
    if (enabled) {
      const exists = document.querySelector(`script[src="${src}"]`);

      if (!exists) {
        const script = document.createElement('script');

        script.src = src;
        script.defer = true;

        document.body.appendChild(script);
      }
    }
  }, [src, enabled]);
}
